import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import DeleteConfiramation from '../components/modals/DeleteConfirmation'
import AddButton from '../components/table/actions/header/AddButton'
import { capitalizeFirstLetter } from '../helpers/string'
import GroupModal from '../components/modals/GroupModal'
import UserModal from '../components/modals/UserModal'
import LinkModal from '../components/modals/LinkModal'
import StateInfo from '../common/stateInfo/StateInfo'
import { getAllGroups } from '../store/groups/getAll'
import { getAllUsers } from '../store/users/getAll'
import { deleteGroup } from '../store/groups/delete'
import { deleteUser } from '../store/users/delete'
import Table from '../components/table/Table'

const Admin = () => {
  const [showModalGroup, setShowModalGroup] = useState(false)
  const [showModalUser, setShowModalUser] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalLink, setShowModalLink] = useState(false)
  const [groupToBeUpdated, setGroupToBeUpdated] = useState(null)
  const [userToBeUpdated, setUserToBeUpdated] = useState(null)
  const [userLink, setUserLink] = useState(null)
  const [itemToBeDeleted, setItemToBeDeleted] = useState({})
  const [offsetGroup, setOffsetGroup] = useState(1)
  const [offsetUser, setOffsetUser] = useState(1)
  const [sliceGroup, setSliceGroup] = useState([])
  const [sliceUser, setSliceUser] = useState([])
  const [usersChoice, setUsersChoices] = useState({})
  const [groupsChoice, setGroupsChoices] = useState({})
  const [limit, setLimit] = useState(10)
  const groups = useSelector((state) => state.groups)
  const users = useSelector((state) => state.users)
  const prevGroups = useRef()
  const prevUsers = useRef()
  const dispatch = useDispatch()
  const handleGroupPagination = (page) => {
    setSliceGroup(users.data.rows.slice(limit * (page - 1), limit * page))
    setOffsetGroup(page)
  }
  const handleUserPagination = (page) => {
    setSliceUser(users.data.rows.slice(limit * (page - 1), limit * page))
    setOffsetUser(page)
  }
  useEffect(() => {
    setLimit(10)
    if (!groups.data.count && !groups.loading) {
      dispatch(getAllGroups())
    } else if (groups.data.count) {
      setSliceGroup(groups.data.rows.slice(0, limit))
      groupParcer()
    }
    if (!users.data.count && !users.loading) {
      dispatch(getAllUsers())
    } else if (users.data.count) {
      setSliceUser(users.data.rows.slice(0, limit))
      userParcer()
    }
  }, [])

  useEffect(() => {
    if (prevUsers.current && !users.loading && users.data.count) {
      setSliceUser(users.data.rows.slice(0, limit))
      userParcer()
    }
    prevUsers.current = users.loading
  }, [users])

  useEffect(() => {
    if (prevGroups.current && !groups.loading && groups.data.count) {
      setSliceGroup(groups.data.rows.slice(0, limit))
      groupParcer()
    }
    prevGroups.current = groups.loading
  }, [groups])

  const userParcer = () => {
    const list = {}
    for (const user of users.data.rows) {
      list[user.user_id] = `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(
        user.last_name
      )}`
    }
    setUsersChoices(list)
  }
  const groupParcer = () => {
    const list = {}
    for (const group of groups.data.rows) {
      list[group.group_id] = `${capitalizeFirstLetter(group.name)}`
    }
    setGroupsChoices(list)
  }
  const updateGroup = (row) => {
    setGroupToBeUpdated({
      id: row.group_id,
      roles: row.roles,
      name: row.name,
      users: row.users.map((e) => e.user_id)
    })
    setShowModalGroup(true)
  }
  const handleDelete = () => {
    switch (itemToBeDeleted.type) {
      case 'group':
        dispatch(deleteGroup(itemToBeDeleted.id))
        break
      case 'user':
        dispatch(deleteUser(itemToBeDeleted.id))
        break
      default:
        break
    }
    setItemToBeDeleted({})
  }
  const updateUser = (row) => {
    setUserToBeUpdated({
      id: row.user_id,
      email: row.email,
      first_name: row.first_name,
      last_name: row.last_name,
      groups: row.groups.map((e) => e.group_id),
      blocked: row.blocked
    })
    setShowModalUser(true)
  }
  return (
    <>
      {itemToBeDeleted.id && itemToBeDeleted.type && (
        <DeleteConfiramation
          action={itemToBeDeleted.type}
          handleClose={() => setItemToBeDeleted({})}
          handleConfirm={handleDelete}
        />
      )}
      {groups.error ? (
        groups.error.code === 403 ? (
          <div className="h-full flex items-center justify-center">
            <StateInfo type="warning" description={groups.error.message} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <StateInfo
              type="error"
              description={typeof groups.error === 'object' ? groups.error.message : groups.error}
            />
          </div>
        )
      ) : (
        <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar">
          <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
            {showModalGroup && (
              <GroupModal
                users={usersChoice}
                row={groupToBeUpdated}
                handleClose={() => {
                  setShowModalGroup(false)
                  setGroupToBeUpdated(null)
                }}
              />
            )}

            {showModalUser && (
              <UserModal
                groups={groupsChoice}
                row={userToBeUpdated}
                handleClose={() => {
                  setShowModalUser(false)
                  setUserToBeUpdated(null)
                }}
              />
            )}
            {showModalLink && (
              <LinkModal
                handleClose={() => {
                  setShowModalLink(false)
                }}
                link={userLink}
              />
            )}

            <div className="h-full inline-block w-full">
              {/*table */}
              <Table
                data={sliceGroup}
                currentPage={offsetGroup}
                handlePagination={handleGroupPagination}
                type="groups"
                show={[showModalDelete, setShowModalDelete]}
                isLoading={groups.loading}
                total={groups.data.count}
                onDelete={(id) => setItemToBeDeleted({ id, type: 'group' })}
                onEdit={updateGroup}
                headerActions={<AddButton onAdd={() => setShowModalGroup(true)} />}
              />
            </div>
          </div>
          <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
            <div className="h-full inline-block w-full">
              <Table
                data={sliceUser}
                currentPage={offsetUser}
                handlePagination={handleUserPagination}
                type="users"
                isLoading={users.loading}
                total={users.data.count}
                onDelete={(id) => setItemToBeDeleted({ id, type: 'user' })}
                onEdit={updateUser}
                onLink={(link) => {
                  setShowModalLink(true)
                  setUserLink(link)
                }}
                headerActions={<AddButton onAdd={() => setShowModalUser(true)} id="add-user" />}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Admin
