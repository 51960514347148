import { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const LineDebitDevice = ({ rx, tx, trigger, options }) => {
  const [chartData, setChartData] = useState({
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    datasets: [
      {
        fill: true,
        label: 'Download',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: '#a855f7',
        backgroundColor: 'rgba(254, 202, 202, 0.2)'
      },
      {
        fill: true,
        label: 'Upload',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: '#3b82f6',
        backgroundColor: 'rgba(191, 219, 254, 0.2)'
      }
    ]
  })

  useEffect(() => {
    const randomDownload = rx
    const randomUpload = tx
    setChartData((prevData) => {
      const newLabels = [...prevData.labels.slice(1), String(prevData.labels.length + 1)]
      const newDownloadData = [...prevData.datasets[0].data.slice(1), randomDownload]
      const newUploadData = [...prevData.datasets[1].data.slice(1), randomUpload]

      return {
        labels: newLabels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: newDownloadData
          },
          {
            ...prevData.datasets[1],
            data: newUploadData
          }
        ]
      }
    })
  }, [trigger])

  return <Line options={options} data={chartData} />
}

export default LineDebitDevice
