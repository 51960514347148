import AccessPoint from './security/AccessPoint'
import { useTranslation } from 'react-i18next'
import EndPoint from './security/EndPoint'
import Client from './security/Client'
import { useState } from 'react'

const DeviceSecurity = ({
  data,
  error,
  id,
  uploadError,
  setUploadError,
  handleChange,
  changes
}) => {
  const { t } = useTranslation()
  const [navIndex, setNavIndex] = useState(0)
  return (
    <div className="">
      <div className="w-full border-b border-gray-200 flex justify-between sticky bg-white z-20">
        <button
          onClick={() => setNavIndex(0)}
          className={`${
            !navIndex
              ? ' text-blue-600 bg-gray-100'
              : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100'
          } py-4 px-4 w-full text-sm text-center`}
        >
          {`${t('ACCESS_POINT')} ${
            Object.keys(changes).some((key) => key.includes('ap')) ? '●' : ''
          }`}
        </button>
        <button
          onClick={() => setNavIndex(1)}
          className={`${
            navIndex === 1
              ? ' text-blue-600 bg-gray-100'
              : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100'
          } py-4 px-4 w-full text-sm text-center`}
        >
          {`${t('END_POINT')} ${Object.keys(changes).some((key) => key.includes('ep')) ? '●' : ''}`}
        </button>
        <button
          onClick={() => setNavIndex(2)}
          className={`${
            navIndex === 2
              ? ' text-blue-600 bg-gray-100'
              : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100'
          } py-4 px-4 w-full text-sm text-center `}
        >
          {`${t('HOST')} ${Object.keys(changes).some((key) => key.includes('host')) ? '●' : ''}`}
        </button>
      </div>
      <div className="p-4 text-gray-600 flex items-center justify-between mb-16">
        {navIndex === 0 ? (
          <AccessPoint
            data={data.ap}
            handleChange={handleChange}
            id={id}
            uploadError={uploadError}
            setUploadError={setUploadError}
            changes={changes}
          />
        ) : navIndex === 1 ? (
          <EndPoint
            data={data.ep}
            handleChange={handleChange}
            error={error}
            uploadError={uploadError}
            setUploadError={setUploadError}
            id={id}
            changes={changes}
          />
        ) : (
          <Client
            data={data.host}
            handleChange={handleChange}
            error={error}
            uploadError={uploadError}
            setUploadError={setUploadError}
            id={id}
            changes={changes}
          />
        )}
      </div>
    </div>
  )
}
export default DeviceSecurity
