import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getOne } from './getOne'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/hardware`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const linkDevice = createAsyncThunk('device/link', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/link`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getOne(data.device_hardware_id))
  return res.data
})

const linkSlice = createSlice({
  name: 'device',
  initialState,
  extraReducers: {
    [linkDevice.pending]: (state) => {
      return { ...state, loading: true }
    },
    [linkDevice.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [linkDevice.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = linkSlice
export default reducer
