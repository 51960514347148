import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { forgetPassword as sendMail, reset } from '../store/currentUser/forgetPassword'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import { isValidEmail } from '../helpers/form'
import Logo from '../assets/img/logo2.png'
import { useTranslation } from 'react-i18next'
import SetLanguages from '../components/languages/SetLanguages'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'

const ForgetPassword = () => {
  const { t } = useTranslation()
  const forgetPassword = useSelector((state) => state.forgetPassword)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const handleSubmit = () => {
    if (!email || !isValidEmail(email)) {
      setError(t('EMAIL_INVALID'))
    } else {
      setError('')
      dispatch(sendMail({ email }))
    }
  }
  useEffect(() => {
    dispatch(reset())
  }, [])
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {forgetPassword.loading && (
        <div className="absolute w-full  h-screen z-50 ">
          <Spinner version={1} />
        </div>
      )}
      <div className="absolute top-16 left-16 w-10">
        <SetLanguages position={'absolute'} />
      </div>
      <div className="max-w-md w-full space-y-4 border rounded-md p-8 bg-white shadow-md">
        <div className="cursor-pointer w-16" onClick={() => navigate('/login')}>
          <ArrowBackIcon />
        </div>
        <div>
          <img className="mx-auto h-32 w-auto" src={Logo} alt="Workflow" />
        </div>

        {(error || forgetPassword.error) && (
          <StateInfo type="error" description={error ? error : forgetPassword.error} />
        )}
        {forgetPassword.data.message && (
          <StateInfo
            title={forgetPassword.data.message}
            type="success"
            description={t('CHECK_MAIL')}
          />
        )}
        <h4 className="mt-2 text-start text-md font-extrabold text-gray-900">
          {t('FORGOT_PASSWORD')}
        </h4>
        <span className="text-sm text-gray-400"> {t('FORGOT_PASSWORD_DESC')}</span>
        <div>
          <div className="rounded-md shadow-sm space-y-6 mt-8">
            <div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700  mb-2">
                  {t('EMAIL')}
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder={t('EMAIL_ADDRESS')}
                />
              </div>

              <div>
                <button
                  onClick={() => handleSubmit()}
                  className="mt-6 shadow-md group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-400 focus:outline-none"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
                  {t('RESET_PASSWORD')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForgetPassword
