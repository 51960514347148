const equals = (arr1, arr2) => {
  if (!arr1 || !arr2) return false
  if (arr1.length !== arr2.length) return false
  const uniqueValues = new Set([...arr1, ...arr2])
  for (const v of uniqueValues) {
    const countA = arr1.filter((e) => e === v).length
    const countB = arr2.filter((e) => e === v).length
    if (countA !== countB) return false
  }
  return true
}

export default equals
