import axios from 'axios'

let clientIp
const customAxios = async (url, method, data, headers, onUploadProgress) => {
  const token =
    localStorage.getItem('token') !== null && localStorage.getItem('token')
      ? localStorage.getItem('token')
      : null
  if (!clientIp) clientIp = '0.0.0.0'
  return await axios({
    baseURL: '',
    url,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'x-access-token': token,
      'client-ip-address': clientIp,
      Accept: '*/*',
      ...headers
    },
    onUploadProgress
  })
    .then((res) => {
      return {
        status: 'success',
        data: res && res.data && res.data.data ? res.data.data : res.data,
        msg: res.data.msg
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('persist:root')
        window.location.reload()
      }
      // ToDo : add error manage
      return {
        code: err.response.status,
        status: 'error',
        message:
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err.message
      }
    })
}

export default customAxios
