import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/hardware`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllDevices = createAsyncThunk('devices/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll?orderBy=created_at&orderType=0`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return { data: res.data, user: thunkAPI.getState().currentUser }
})

const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateOne: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          rows: state.data.rows.map((el) => {
            if (el.device_hardware_id === action.payload.data.device_hardware_id)
              return {
                ...action.payload.data,
                editable:
                  action.payload.user.data.superiorRole === 'user'
                    ? hasPermisson(
                        action.payload.user.data.groupIds,
                        action.payload.data.informations[0]?.groups
                      )
                    : true
              }
            return el
          })
        }
      }
    },
    reset: (state) => {
      return initialState
    }
  },
  extraReducers: {
    [getAllDevices.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllDevices.fulfilled]: (state, action) => {
      if (action.payload.user.data.superiorRole === 'user') {
        const groups = action.payload.user.data.groupIds
        return {
          error: null,
          data: {
            ...action.payload.data,
            rows: action.payload.data.rows.map((el) => ({
              ...el,
              editable: hasPermisson(groups, el.informations[0]?.groups)
            }))
          },
          loading: false
        }
      } else {
        return {
          error: null,
          data: {
            ...action.payload.data,
            rows: action.payload.data.rows.map((el) => ({ ...el, editable: true }))
          },
          loading: false
        }
      }
    },
    [getAllDevices.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    }
  }
})

const hasPermisson = (userGroup, deviceGroup) => {
  for (const id of userGroup) {
    for (const group of deviceGroup) {
      if (group.edit.indexOf(id) !== -1) return true
    }
  }
  return false
}
const { reducer } = deviceSlice
const { updateOne, reset } = deviceSlice.actions
export { updateOne, reset, hasPermisson }
export default reducer
