import React from 'react'
const PageButton = ({ orientation, currentPage, pageCount, handlePageChange }) => {
  const button = {
    left: {
      verification: currentPage - 1 > 0,
      action: () => {
        handlePageChange(currentPage - 1)
      },
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      )
    },
    right: {
      verification: currentPage + 1 <= pageCount,
      action: () => {
        handlePageChange(currentPage + 1)
      },
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 min-w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      )
    }
  }

  if (button[orientation].verification) {
    return (
      <button
        id="buttonPreviousPage"
        onClick={button[orientation].action}
        className="h-4 w-4 hover:text-window-icon-accent rounded-full bg-window focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-window focus:ring-window transition duration-100"
      >
        {button[orientation].icon}
      </button>
    )
  } else {
    return (
      <div className="h-4 w-4 text-window-icon-muted rounded-full">{button[orientation].icon}</div>
    )
  }
}
export default PageButton
