import { Route, Routes, Navigate } from 'react-router-dom'

import ForgetPassword from '../container/ForgetPassword'
import ResetPassword from '../container/ResetPassword'
import Login from '../container/Loginv2'
// import Login from '../container/Login'

const PublicRoute = () => (
  <Routes>
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/forgetPassword" element={<ForgetPassword />} />
    <Route exact path="/resetPassword/:token" element={<ResetPassword />} />
    <Route exact path="/account/activate/:token" element={<ResetPassword />} />
    {/* redirect to default route */}
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
)

export default PublicRoute
