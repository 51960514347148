import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import DeleteConfiramation from '../components/modals/DeleteConfirmation'
import AddButton from '../components/table/actions/header/AddButton'
import WidgetModal from '../components/modals/WidgetModal'
import { getAllWidgets } from '../store/widgets/getAll'
import { deleteWidget } from '../store/widgets/delete'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import Table from '../components/table/Table'

const Widget = () => {
  const widgets = useSelector((state) => state.widgets)
  const dispatch = useDispatch()
  const prevData = useRef()
  const [state, setState] = useState({
    sliceWidget: [],
    offset: 1,
    itemToBeDeleted: null,
    itemToBeEdit: null,
    showModal: false
  })
  useEffect(() => {
    if (prevData.current && !widgets.loading && !widgets.error) {
      setState((prev) => ({
        ...prev,
        sliceWidget: widgets.data.slice(0, 10)
      }))
    }
    prevData.current = widgets.loading
  }, [widgets.loading])
  useEffect(() => {
    if (!widgets.data.length && !widgets.loading) {
      dispatch(getAllWidgets())
    } else {
      setState((prev) => ({
        ...prev,
        sliceWidget: widgets.data.slice(0, 10)
      }))
    }
  }, [])
  const handlePagination = (page) => {
    setState((prev) => ({
      ...prev,
      sliceWidget: widgets.data.slice(10 * (page - 1), 10 * page),
      offset: page
    }))
  }
  const handleDelete = () => {
    dispatch(deleteWidget(state.itemToBeDeleted))
    setState((prev) => ({ ...prev, itemToBeDeleted: null }))
  }
  const update = (item) => {
    setState((prev) => ({ ...prev, itemToBeEdit: item, showModal: true }))
  }
  return (
    <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar">
      <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
        <div className="h-full inline-block w-full">
          {state.showModal && (
            <WidgetModal
              row={state.itemToBeEdit}
              handleClose={() =>
                setState((prev) => ({ ...prev, itemToBeEdit: null, showModal: false }))
              }
            />
          )}
          {!!state.itemToBeDeleted && (
            <DeleteConfiramation
              action="widget"
              handleClose={() => setState((prev) => ({ ...prev, itemToBeDeleted: null }))}
              handleConfirm={handleDelete}
            />
          )}
          {widgets.loading ? (
            <div className="absolute w-full h-full z-50 top-0 left-0">
              <Spinner />
            </div>
          ) : widgets.error ? (
            <div className="h-full flex items-center justify-center">
              <StateInfo
                type="error"
                description={
                  typeof widgets.error === 'object' ? widgets.error.message : widgets.error
                }
              />
            </div>
          ) : (
            <Table
              data={state.sliceWidget}
              currentPage={state.offset}
              handlePagination={handlePagination}
              type="widgets"
              isLoading={widgets.loading}
              total={widgets.data.length}
              onEdit={update}
              onDelete={(id) => setState((prev) => ({ ...prev, itemToBeDeleted: id }))}
              headerActions={
                <AddButton onAdd={() => setState((prev) => ({ ...prev, showModal: true }))} />
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Widget
