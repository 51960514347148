import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import { getAllDistributions } from './getAll'
import { getOneDistribution } from './getOne'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  uploadProgress: 0,
  data: {},
  loading: false,
  error: null
}
export const createDistribution = createAsyncThunk('firmware/create', async (data, thunkAPI) => {
  let progress
  const res = await axios(
    `${basedUrl}/firmware`,
    'POST',
    data,
    {
      'Content-Type': 'multipart/form-data',
      'Content-Encoding': 'gzip'
    },
    (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      if (progress !== percentCompleted) {
        progress = percentCompleted
        thunkAPI.dispatch(onProgress(percentCompleted))
      }
    }
  )
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))()
  }
  thunkAPI.dispatch(getOneDistribution(res.data.id))
  thunkAPI.dispatch(getAllDistributions())
  thunkAPI.dispatch(success())
  return res.data
})
export const uploadFile = createAsyncThunk(
  'firmware/upload',
  async ({ data, moduleId, distributionId }, thunkAPI) => {
    let progress
    const res = await axios(
      `${basedUrl}/uploadArtifact/${moduleId}`,
      'POST',
      data,
      {
        'Content-Type': 'multipart/form-data',
        'Content-Encoding': 'gzip'
      },
      (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (progress !== percentCompleted) {
          progress = percentCompleted
          thunkAPI.dispatch(onProgress(percentCompleted))
        }
      }
    )
    if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
    thunkAPI.dispatch(getOneDistribution(distributionId))

    return res.data
  }
)
const distributionSlice = createSlice({
  name: 'firmware/create',
  initialState,
  reducers: {
    onProgress: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload
      }
    }
  },
  extraReducers: {
    [createDistribution.pending]: (state) => {
      return { ...state, loading: true }
    },
    [createDistribution.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false, uploadProgress: 0 }
    },
    [createDistribution.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false, uploadProgress: 0 }
    },
    [uploadFile.pending]: (state) => {
      return { ...state, loading: true }
    },
    [uploadFile.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false, uploadProgress: 0 }
    },
    [uploadFile.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false, uploadProgress: 0 }
    }
  }
})

const { reducer } = distributionSlice
const { onProgress } = distributionSlice.actions
export default reducer
