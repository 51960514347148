import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllTypes = createAsyncThunk('type/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAllTypes`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data?.content || []
})

const distributionSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [getAllTypes.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllTypes.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllTypes.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = distributionSlice
export default reducer
