import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getAllUpdate } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const createDeviceUpdate = createAsyncThunk(
  'update/createRollout',
  async (data, thunkAPI) => {
    const res = await axios(`${basedUrl}/createRollout`, 'POST', data)
    if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
    thunkAPI.dispatch(getAllUpdate({ limit: 10, offset: 0 }))
    return res.data
  }
)

export const resetCreate = createAsyncThunk('update/reset', async () => {
  return true
})

const deviceUpdateSlice = createSlice({
  name: 'update',
  initialState,
  extraReducers: {
    [createDeviceUpdate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [createDeviceUpdate.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [createDeviceUpdate.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetCreate.fulfilled]: () => {
      return initialState
    }
  }
})
const { reducer } = deviceUpdateSlice
export default reducer
