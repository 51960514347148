import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

function Actions({ onEdit, onDelete, onOpen, row }) {
  return (
    <div className="flex items-stretch">
      {onEdit && (
        <button
          aria-label="edit"
          onClick={() => onEdit(row)}
          className="rounded-full flex items-center p-2 text-gray-700 hover:bg-blue-100 hover:text-blue-500 dark:hover:text-blue-500 dark:text-white"
        >
          <EditIcon />
        </button>
      )}
      {onOpen && (
        <button
          aria-label="view"
          onClick={() => onOpen(row)}
          className="rounded-full flex items-center p-2 text-gray-700 hover:bg-blue-100 hover:text-blue-500 dark:hover:text-blue-500 dark:text-white"
        >
          <RemoveRedEyeIcon />
        </button>
      )}

      {onDelete && (
        <button
          aria-label="delete"
          onClick={() => onDelete(row)}
          className="rounded-full flex items-center p-2 text-gray-700 hover:bg-red-100 hover:text-red-500 dark:text-white dark:hover:text-red-500"
        >
          <DeleteIcon />
        </button>
      )}
    </div>
  )
}

export default Actions
