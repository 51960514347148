import useOnClickOutside from '../../utils/useOnClickOutside'
import { capitalizeFirstLetter } from '../../helpers/string'

import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Filter = ({ value, rows, onChange, onSearch, isLoading, label, editable, classLabel }) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [items, setItems] = useState(rows)
  const [val, setVal] = useState('')
  const ref = useRef()

  const { t } = useTranslation()

  useOnClickOutside(ref, () => {
    setShowDropDown(false)
  })
  useEffect(() => {
    setItems(rows)
  }, [rows])
  const onAddItem = (item) => {
    onChange([...value, item])
  }
  const onRemoveItem = (index) => {
    const newValue = value
    newValue.splice(index, newValue.length - index)
    onChange(newValue)
  }

  const onFilter = (v) => {
    setVal(v)
    if (v) {
      const newList = items.filter((el) => el.label.toLowerCase().includes(v.toLowerCase()))
      setItems(newList)
    } else {
      setItems(rows)
    }
  }

  return (
    <div ref={ref} className="w-full flex flex-col items-start">
      {label && (
        <label
          className={
            'mb-1 block lg:text-sm text-lg leading-5 text-primary dark:text-white ' +
            `${classLabel ? classLabel : 'font-medium'}`
          }
        >
          {label}
        </label>
      )}

      <div className="flex flex-col items-center relative w-full">
        <div className={`flex border border-gray-200 w-full rounded-md bg-white`}>
          <div className="flex flex-auto justify-center items-center">
            {value && value.length ? (
              value.map((item, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center m-1 font-medium py-0.5 px-1 rounded-full border border-gray-300 space-x-1 `}
                >
                  <div className={`p-2 ${item.color} rounded-full`} />
                  <div className="text-xs leading-none font-semibold">
                    {capitalizeFirstLetter(item.label)}
                  </div>
                  <div
                    onClick={() => onRemoveItem(index)}
                    className="flex flex-auto flex-row-reverse"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x cursor-pointer hover:text-blue-400 rounded-full w-4 h-4 ml-2"
                      >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : !showDropDown ? (
              <div
                className="flex  items-center justify-center"
                onClick={() => setShowDropDown(true)}
              />
            ) : null}
            <div className="flex-1">
              <input
                aria-label="write filter"
                onFocus={() => setShowDropDown(true)}
                value={val}
                onChange={(e) => onFilter(e.target.value)}
                placeholder={items.length ? t('SELECT_TAG') : ''}
                className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800 "
              />
            </div>
          </div>
          <div className="flex">
            <div
              onClick={() => setShowDropDown(!showDropDown)}
              className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u"
            >
              <button
                aria-label="show tags"
                className="cursor-pointer w-6 h-6 text-gray-600 dark:text-white outline-none focus:outline-none"
              >
                {showDropDown ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="2 0 30 22"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="2 0 30 22"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                )}
              </button>
            </div>
            {onSearch && (
              <div
                onClick={() => onSearch()}
                className="text-gray-300 w-8 p-2 border-l flex items-center border-gray-200  svelte-1l8159u"
              >
                <button
                  aria-label="search devices"
                  className="cursor-pointer w-6 h-6 text-gray-600 dark:text-white outline-none focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 30 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>

        <div
          className={` ${!showDropDown ? 'hidden' : ''} ${
            editable ? 'top-9 dark:bg-slate-700' : 'top-11 dark:bg-slate-400'
          } absolute shadow bg-white z-40 w-full lef-2 mx-10 rounded-md max-h-select overflow-y-auto  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
        >
          <div className="flex flex-col w-full">
            {isLoading ? (
              <div className="flex w-full items-center p-2 pl-2">
                <div className="w-full items-center flex">
                  <div className="mx-2 leading-6 text-sm ">{t('LOADING')}...</div>
                </div>
              </div>
            ) : items.length ? (
              <>
                {items.map((item, index) => (
                  <div
                    onClick={() => onAddItem(item)}
                    key={index}
                    className="cursor-pointer w-full border-gray-100 border-b hover:bg-blue-100 font-semibold"
                  >
                    <div className="flex w-full items-center p-0.5 pl-2">
                      <div className="w-full items-center flex">
                        <div className={`p-2 ${item.color} rounded-full`} />
                        <div className="mx-2 leading-6 text-xs ">
                          {capitalizeFirstLetter(item.label)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Filter
