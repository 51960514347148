import useOnClickOutside from '../../utils/useOnClickOutside'
import SearchIcon from '@mui/icons-material/Search'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const MultiSelect = ({ values, onChange, label, choices, error }) => {
  const { t } = useTranslation()
  const [val, setVal] = useState('')
  const [showDropDown, setShowDropDown] = useState(false)
  const [list, setList] = useState({})
  const ref = useRef()
  useOnClickOutside(ref, () => setShowDropDown(false))
  const sliceList = () => {
    const newList = Object.entries(choices).slice(0, 5)
    setList(Object.fromEntries(newList))
  }

  const addItem = (item) => {
    if (val) {
      setVal('')
    }
    const key = parseInt(item) ? parseInt(item) : item
    if (!values.includes(key) && list[key]) {
      onChange(values.concat(key))
    }
    setShowDropDown(false)
  }
  // removes item from multiselect
  const removeItem = (item) => {
    setVal('')
    const filtered = values.filter((e) => e !== item)
    onChange(filtered)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (val) {
        const newList = Object.entries(choices).filter(([key, value]) =>
          value.toLowerCase().includes(val.toLowerCase())
        )
        setList(Object.fromEntries(newList.slice(0, 5)))
      } else {
        sliceList()
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [val])
  return (
    <div className="">
      <div className="inline-flex text-center">
        <label
          htmlFor="about"
          className="block lg:text-sm text-lg dark:text-gray-200 leading-5 font-medium text-primary"
        >
          {label}
        </label>
      </div>
      <div className="w-full">
        <div className="flex flex-col items-center relative ">
          <div className="w-full">
            <div className="flex border border-gray-200 dark:border-gray-600 bg-white dark:bg-slate-700 rounded-md py-1">
              <div className="flex flex-col flex-auto flex-wrap">
                {values.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-teal-700 bg-gray-100 border border-gray-200 "
                    >
                      <div className="text-xs font-normal leading-none max-w-full flex-initial">
                        {choices[item]}
                      </div>
                      <div className="flex flex-auto flex-row-reverse">
                        <div className="text-gray-500" onClick={() => removeItem(item)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                          >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div onClick={() => setShowDropDown(true)} className="flex-1">
                  <input
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                    placeholder={`${t('SEARCH')}...`}
                    className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                  />
                </div>
              </div>
              <div
                onClick={() => setShowDropDown(!showDropDown)}
                className="text-gray-600 outline-none focus:outline-nonew-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
              >
                <SearchIcon className="dark:text-gray-200" />
              </div>
            </div>
            {error && <span className="w-full text-sm text-red-500">{error}</span>}
          </div>
        </div>
        {showDropDown && (
          <div ref={ref} className="relative">
            <div
              id="dropdown"
              className="absolute shadow top-100  bg-white z-40 w-full lef-0 rounded max-h-select overflow-hidden "
            >
              <div className="flex flex-col w-full">
                {Object.keys(list).map((key, index) => {
                  return (
                    <div
                      key={key}
                      className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                      onClick={() => addItem(key)}
                    >
                      <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                        <div className="w-full items-center flex">
                          <div className="mx-2 lg:text-sm text-lg leading-5 font-medium text-primary">
                            {list[key]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {Object.keys(list).length !== Object.keys(choices) ? (
                  <div className="bg-gray-200 shadow-md border w-full flex items-center justify-center">
                    <i className="p-1 text-sm">{t('SEARCH_DESC')}</i>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default MultiSelect
