import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/widget`
const initialState = {
  loading: false,
  data: {},
  error: null
}

export const getOneWidgets = createAsyncThunk('widget/getOne', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOne/${id}`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [getOneWidgets.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getOneWidgets.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getOneWidgets.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = widgetSlice
export default reducer
