import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ReactTooltip from 'react-tooltip'

const Explain = ({ description, width, description2 }) => {
  return (
    <div id={description} className="cursor-pointer hover:shadow-lg flex items-center ">
      <InfoOutlinedIcon
        className="text-gray-500 hover:text-gray-200"
        data-for={`tip-${description}`}
        data-class="react-component-tooltip"
        data-tip
      />

      <ReactTooltip id={`tip-${description}`}>
        <p className={width ? width : 'w-64'}>{description}</p>
        {description2 && <p className={width ? width : 'w-64'}>{description2}</p>}
      </ReactTooltip>
    </div>
  )
}
export default Explain
