import { useEffect, useRef } from 'react'
import Button from '../../common/buttons/Button'
import useOnClickOutside from '../../utils/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import Close from '../../common/buttons/Close'

const GlobalModal = ({ title, buttons, children, size }) => {
  const id = `oledcomm-modal-${title?.replaceAll(' ', '-')}`
  const { t } = useTranslation()
  useEffect(() => {
    const el = document.getElementById(id)
    if (el) {
      el.classList.replace('scale-x-0', 'scale-x-1')
    }
  })
  const ref = useRef({})

  useOnClickOutside(ref, (e) => {
    if (!document.getElementById(id).contains(e.target)) {
      const action = buttons.find((b) => b.title === t('CLOSE') || b.type === 'secondary')
      if (action) {
        action?.action()
      }
    }
  })

  return (
    <>
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none dark:text-white">
          <div
            className={`relative ${
              size == 'small' ? 'w-1/3 max-w-xl' : 'w-1/2 max-w-3xl'
            }  my-6 mx-auto `}
          >
            {/*content*/}
            <div
              ref={ref}
              id={id}
              className="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none dark:bg-slate-800 shadow-xl transform transition-all ease-in-out duration-500 scale-x-0"
            >
              {/*header*/}
              {title && (
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h5 className="text-xl font-semibold">{title}</h5>
                  {buttons && buttons.some((el) => el.type === 'secondary') && (
                    <Close
                      handleClose={() => buttons.find((el) => el.type === 'secondary').action()}
                    />
                  )}
                </div>
              )}
              {/*body*/}
              <div className="p-4">{children}</div>
              {/*footer*/}
              <div
                className={`flex items-center justify-end py-3 px-4 mt-5 border-t border-solid border-blueGray-200 rounded-b space-x-4`}
              >
                {buttons
                  ? buttons.map((el, key) => {
                      if (el && el.title && (el.type !== 'secondary' || !title)) {
                        return (
                          <Button
                            key={key}
                            onClick={() => el.action()}
                            type={el.type}
                            title={el.title}
                          />
                        )
                      }
                    })
                  : null}{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    </>
  )
}

export default GlobalModal
