import io from 'socket.io-client'
import { getCleanUrl } from './urlManip'

let socket
let timerId

export const initiateSocket = () => {
  socket = io(getCleanUrl('device'), {
    path: '/api/device/socket.io',
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    auth: {
      token: localStorage.getItem('token')
    }
  })
}

export const disconnectSocket = () => {
  if (socket) socket.disconnect()
}
export const subscribeToDeviceAlert = (cb) => {
  if (socket)
    socket.on('alert/getAll', (msg) => {
      return cb(msg)
    })
}
export const subscribeToDeviceStatus = (cb) => {
  if (!socket) return true
  socket.on('device/status', (msg) => {
    return cb(msg)
  })
}

export const subscribeToUploadProgress = (fileName, cb) => {
  if (!socket) return true
  socket.on(`file/progress/${fileName}`, (msg) => {
    return cb(msg)
  })
}
export const subscribeToDownloadProgress = (artifactId, cb) => {
  if (!socket) return true
  socket.on(`file/progress/${artifactId}`, (msg) => {
    return cb(msg)
  })
}

export const subscribeToUpdateDeviceConfig = (device_uuid, cb) => {
  if (!socket) return true

  socket.on(`device/config/${device_uuid}`, (msg) => {
    return cb(msg)
  })
}

export const sendMessage = (room, message) => {
  if (!socket || !socket.connected) {
    timerId = setTimeout(() => sendMessage(room, message), 500)
    return true
  }
  clearTimeout(timerId)
  socket.emit(room, message)
}
