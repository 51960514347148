import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { switchOganization } from '../../store/currentUser/auth'
import useOnClickOutside from '../../utils/useOnClickOutside'

function Dropdown({ organizations, currentOrganization }) {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef()
  const dispatch = useDispatch()
  useOnClickOutside(ref, () => setShowMenu(false))
  return (
    <div className="relative inline-block text-left mr-3">
      <div>
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="relative flex justify-center dark:text-white border border-gray-200 items-center h-10 w-8 md:w-auto md:px-3 rounded-tl-lg rounded-tr-lg rounded-br-lg dark:bg-slate-800"
        >
          {currentOrganization ? currentOrganization.name : 'N/A'}{' '}
          {showMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
        <div
          ref={ref}
          className={` ${
            showMenu ? 'flex' : 'hidden'
          } origin-top-right fixed rounded-tr-lg rounded-br-lg rounded-bl-lg shadow-lg bg-white border-x border-gray-200 foucus:outline-none z-30`}
        >
          <div className="py-1 flex justify-center items-center">
            <div className="lg:w-48 w-40">
              <ul className="py-1">
                {organizations.map((el, key) => {
                  if (el.organization_id !== currentOrganization.organization_id) {
                    return (
                      <li
                        onClick={() => {
                          dispatch(switchOganization(el.organization_id))
                        }}
                        key={key}
                      >
                        <span className="block px-4 py-2 hover:bg-gray-100">{el.name}</span>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropdown
