import { createSlice } from '@reduxjs/toolkit'
import { t } from 'i18next'

const initialState = {
  message: null,
  type: null
}

const requestSlice = createSlice({
  name: 'requestInfo',
  initialState,
  reducers: {
    success: (state, msg) => {
      return { message: msg.payload ? msg.payload : t('OPERATION_SUCCESSFUL'), type: 'success' }
    },
    error: (state, msg) => {
      return { message: msg.payload, type: 'error' }
    },
    reset: (state) => {
      return initialState
    }
  }
})
const { reducer } = requestSlice
const reset = requestSlice.actions.reset
const success = requestSlice.actions.success
const error = requestSlice.actions.error
export { reset, success, error }
export default reducer
