import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import Action from '../actions/row/Actions'

const Row = ({ cols, row, onDelete, onEdit }) => {
  return (
    <tr className="border-b dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 " />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap">
              {col.key === 'created_at'
                ? fullDateFormat(row[col.key])
                : col.key === 'roles'
                ? capitalizeFirstLetter(row[col.key].toString().replaceAll('_', ' '))
                : row[col.key]}
            </td>
          )
        } else if (col.label === 'Actions') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <Action
                row={row}
                onDelete={(row) => onDelete(row.device_configuration_id)}
                onEdit={onEdit}
              />
            </td>
          )
        }
      })}
    </tr>
  )
}

export default Row
