import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/group`

const initialState = {
  loading: false,
  data: [],
  error: null,
  isCombineted: false
}

export const getAllGroups = createAsyncThunk('groups/getAll', async (page, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll?user=1&orderBy=created_at&orderType=1`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const groupSlice = createSlice({
  name: 'getAllGroups',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getAllGroups.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllGroups.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [getAllGroups.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    }
  }
})
const { reducer } = groupSlice
const reset = groupSlice.actions.reset
export { reset }
export default reducer
