import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ProgressBar({ progress, step, isDownload }) {
  const navigate = useNavigate()

  useEffect(() => {
    if (progress === 100) {
      navigate('/firmwares')
    }
  }, [progress])

  return (
    <div className={`h-full flex justify-center items-center absolute z-10 inset-0 p-0`}>
      <div className="fixed inset-0 z-10">
        <div className="absolute inset-0 bg-gray-600 opacity-50" />
      </div>
      <div className="bg-white z-50 absolute w-full max-w-lg rounded-md  p-0">
        <div className="w-full  flex justify-center flex-col space-y-4 p-12">
          <span className="text-gray-600 text-md font-medium">
            {isDownload ? (
              parseInt(progress) < 100 ? (
                `Downloading ${Math.floor(progress)}%`
              ) : (
                <div className="flex flex-col space-y-1">
                  <span className="w-full">{'FILE_PREPARATION'}</span>
                  <span className="text-xs text-gray-400">{'LONG_OPERATION'}</span>
                </div>
              )
            ) : (
              `${step}/2 Uploading ${Math.floor(progress)}%`
            )}
          </span>
          <div className="h-4 w-full bg-gray-300 rounded-full ">
            <div style={{ width: `${progress}%` }} className={`h-full rounded-full bg-blue-500`} />
          </div>
        </div>
      </div>
    </div>
  )
}
