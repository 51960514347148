import React from 'react'

import PageButton from '../../common/pagination/PageButton'
import PageNumber from '../../common/pagination/PageNumber'
import { useTranslation } from 'react-i18next'

const Footer = ({ total, perPage, currentPage, handlePageChange }) => {
  const { t } = useTranslation()
  const pageCount = Math.ceil(total / 10)
  return (
    <div className="dark:bg-slate-700 dark:text-gray-200 flex justify-between items-center h-12 px-4 flex-shrink-0 bg-whit border-t border-gray-200 text-window-icon">
      <div className="hidden md:block text-window-muted">
        {!total ? '' : `${t('SHOWING_PAGE', { perPage: perPage, total: total })}`}
      </div>
      <div className="flex justify-between items-center w-full md:w-auto space-x-5">
        <PageButton
          orientation={'left'}
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
        />
        <PageNumber
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
        />
        <PageButton
          orientation={'right'}
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default Footer
