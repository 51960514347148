import { capitalizeFirstLetter } from '../../helpers/string'
import DeviceDebitsChart from './DeviceDebitsChart'
import { fullDateFormat } from '../../helpers/date'
import Button from '../../common/buttons/Button'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const GlobalDeviceInfo = ({ device, onEdit }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [window, setWindow] = useState(0)
  const wiredAddress = device?.properties?.interfaces.find((i) => i.name === 'lan' && i.mac_address)
  const hide = device?.informations?.length == 0 || !device.editable ? false : true

  return (
    <div className="flex flex-col rounded-lg bg-white h-full overflow-hidden border border-gray-200 shadow-md">
      <div className="flex items-center justify-between p-2 border-b">
        <div className="text-lg text-gray-700 flex justify-between w-full">
          <div className="flex items-center">
            <span>{t('DEVICE_DETAILS')}</span>
          </div>

          <div className="flex flex-row">
            <div className="mr-4">
              <Button
                type={'back'}
                onClick={() => {
                  navigate('/devices')
                }}
                title={t('TO_ALL_DEVICES')}
              />
            </div>
            <div className="flex space-x-2">
              {hide && <Button onClick={onEdit} title={t('CONFIG')} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 h-full sm:overflow-auto overflow-hidden">
        <div className="text-gray-600 flex w-full h-full flex-col items-start">
          <ul className="w-full flex items-center justify-between border-b">
            <li
              className={`p-3 px-6 w-full ${
                window === 0 && 'border-b-4 border-blue-500 text-blue-600'
              } hover:bg-gray-100 cursor-pointer hover:text-blue-500 text-center`}
              onClick={() => setWindow(0)}
            >
              {t('GENERAL')}
            </li>
            <li
              className={`p-3 px-6 w-full ${
                window === 1 && 'border-b-4 border-blue-500 text-blue-600'
              } hover:bg-gray-100 cursor-pointer hover:text-blue-500 text-center`}
              onClick={() => setWindow(1)}
            >
              {t('HARDWARE')}
            </li>
            <li
              className={`p-3 px-6 w-full ${
                window === 2 && 'border-b-4 border-blue-500 text-blue-600'
              } hover:bg-gray-100 cursor-pointer hover:text-blue-500 text-center`}
              onClick={() => setWindow(2)}
            >
              {t('NETWORK')}
            </li>
            <li
              className={`p-3 px-6 w-full ${
                window === 3 && 'border-b-4 border-blue-500 text-blue-600'
              } hover:bg-gray-100 cursor-pointer hover:text-blue-500 text-center`}
              onClick={() => setWindow(3)}
            >
              {t('SECURITY')}
            </li>

            <li
              className={`p-3 px-6 w-full ${
                window === 5 && 'border-b-4 border-blue-500 text-blue-600'
              } hover:bg-gray-100 cursor-pointer hover:text-blue-500 text-center`}
              onClick={() => setWindow(5)}
            >
              {t('DEBITS')}
            </li>
          </ul>
          {!window ? (
            <ul className="w-full">
              <li className="p-2 px-4 space-y-2">
                <div className="space-x-2 text-gray-500 text-sm font-medium dark:text-white flex items-center">
                  <p>{t('TAGS')} :</p>
                  {device?.informations[0]?.tags.map((tag) => {
                    return (
                      <div
                        key={tag.device_tag_id}
                        className={`rounded-lg p-1 flex items-center space-x-2 border border-gray-200`}
                      >
                        <div className={`p-2 ${tag.color} rounded-full`} />
                        <p>{capitalizeFirstLetter(tag.name)}</p>
                      </div>
                    )
                  })}
                </div>
                <hr />
              </li>
              <li className="p-2 px-4 space-y-2">
                <div className="space-x-2 text-gray-500 text-sm font-medium dark:text-white flex items-center">
                  <p>{t('GROUPS')} :</p>
                  {device?.informations[0]?.groups.map((group) => {
                    return (
                      <div
                        key={group.device_group_id}
                        className="bg-purple-500  w-max px-2 rounded-xl p-1 text-white flex items-center"
                      >
                        {capitalizeFirstLetter(group.name)}
                      </div>
                    )
                  })}
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('ALIAS')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.alias || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('LOCATION')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.location || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('SOFTWARE_VERSION')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.properties?.software_version || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('DEVICE_UUID')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.device_uuid || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
            </ul>
          ) : window == 1 ? (
            <ul className="w-full">
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('DEVICE_TYPE')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.properties?.device_type || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              {wiredAddress && (
                <li className="p-3 px-4 space-y-2">
                  <div className="flex">
                    <label className="text-sm font-medium text-gray-500 dark:text-white">
                      {t('WIRED_INTERFACE')} :
                    </label>
                    <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                      {wiredAddress.mac_address}
                    </span>
                  </div>
                  <hr />
                </li>
              )}
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('HARDWARE_VERSION')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.properties?.hardware_revision || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
            </ul>
          ) : window == 2 ? (
            <ul className="w-full">
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('DHCP')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.dhcp ? (
                      <span className="font-bold pl-2 text-green-600">{t('ENABLE')}</span>
                    ) : (
                      <span className="font-bold pl-2 text-red-600">{t('DISABLE')}</span>
                    )}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('IP')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.ip_address || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('MAC')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.properties?.interfaces[1]?.mac_address || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('NETMASK')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.netmask || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('GATEWAY')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.gateway || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('PRIMARY_DNS')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.dns1 || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('SECONDARY_DNS')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.dns2 || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('HOST_NAME')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.hostname || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('NTP')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.network?.ntp || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
            </ul>
          ) : window == 3 ? (
            <ul className="w-full h-full">
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('LPA_LEVEL')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.security?.lpa?.auth_type === 0
                      ? t('BASIC_LPA')
                      : device?.informations[0]?.configs?.security?.lpa?.auth_type === 1
                      ? t('INTERMEDIATE_LPA')
                      : device?.informations[0]?.configs?.security?.lpa?.auth_type === 2
                      ? t('ADVANCED_LPA')
                      : 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('TLS_TYPE')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.security?.tls?.type || 'N/A'}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('ISSUER')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.security?.tls?.issuer || t('FACTORY_CERT')}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('SUBJECT')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.security?.tls?.subject || t('FACTORY_CERT')}
                  </span>
                </div>
                <hr />
              </li>
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('TLS_EXPIRATION_DATE')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {device?.informations[0]?.configs?.security?.tls?.expiration_date
                      ? fullDateFormat(
                          device?.informations[0]?.configs?.security?.tls?.expiration_date
                        )
                      : t('FACTORY_CERT')}
                  </span>
                </div>
                <hr />
              </li>
              {device?.informations[0]?.configs?.security?.lpa?.auth_type === 1 ? (
                <>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('IP_RADIUS')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {(device?.informations[0]?.configs?.security?.lpa?.auth_type === 1 &&
                          device?.informations[0]?.configs?.security?.lpa?.ip_radius) ||
                          'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('PORT_RADIUS')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {(device?.informations[0]?.configs?.security?.lpa?.auth_type === 1 &&
                          device?.informations[0]?.configs?.security?.lpa?.port_radius) ||
                          'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                </>
              ) : device?.informations[0]?.configs?.security?.lpa?.auth_type === 2 ? (
                <>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('IP_RADSEC')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {(device?.informations[0]?.configs?.security?.lpa?.auth_type === 2 &&
                          device?.informations[0]?.configs?.security?.lpa?.ip_radsec) ||
                          'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('PORT_RADSEC')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {(device?.informations[0]?.configs?.security?.lpa?.auth_type === 2 &&
                          device?.informations[0]?.configs?.security?.lpa?.port_radsec) ||
                          'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="p-3 px-4 space-y-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('802.1X')} :
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white space-x-8">
                    {device?.informations[0]?.configs?.security?.dot1X?.active ? (
                      <span className="font-bold pl-2 text-green-600">{t('ENABLE')}</span>
                    ) : (
                      <span className="font-bold pl-2 text-red-600">{t('DISABLE')}</span>
                    )}
                  </span>
                </div>
                <hr />
              </li>
              {device?.informations[0]?.configs?.security?.dot1X?.active ? (
                <>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('802.1X_TYPE')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {device?.informations[0]?.configs?.security?.dot1X?.type || 'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('802.1X_ISSUER')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {device?.informations[0]?.configs?.security?.dot1X?.issuer || 'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('802.1X_SUBJECT')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {device?.informations[0]?.configs?.security?.dot1X?.subject || 'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                  <li className="p-3 px-4 space-y-2">
                    <div className="flex">
                      <label className="text-sm font-medium text-gray-500 dark:text-white">
                        {t('802.1X_EXPIRATION_DATE')} :
                      </label>
                      <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                        {device?.informations[0]?.configs?.security?.dot1X?.expiration_date
                          ? fullDateFormat(
                              device?.informations[0]?.configs?.security?.dot1X?.expiration_date
                            )
                          : 'N/A'}
                      </span>
                    </div>
                    <hr />
                  </li>
                </>
              ) : null}
            </ul>
          ) : (
            <li className=" list-none w-full h-full px-6">
              <DeviceDebitsChart uuid={device?.device_uuid} />
            </li>
          )}
        </div>
      </div>
    </div>
  )
}

export default GlobalDeviceInfo
