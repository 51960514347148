import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`

const deleteFile = async (moduleId, artifactId) => {
  return await axios(`${basedUrl}/deleteArtifact/${moduleId}/${artifactId}`, 'DELETE')
    .then((res) => {
      if (res.status === 'success') {
        return true
      }
      return false
    })
    .catch((error) => {
      console.error('Error downloading file:', error.message)
      return false
    })
}

export default deleteFile
