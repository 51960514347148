import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllDistributions = createAsyncThunk(
  'distributions/getAll',
  async (data, thunkAPI) => {
    const res = await axios(`${basedUrl}/getAllDistributions`, 'GET')
    if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
    return res.data?.content || []
  }
)

const distributionSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [getAllDistributions.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllDistributions.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllDistributions.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = distributionSlice
export default reducer
