import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/certificate`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllCertificate = createAsyncThunk('certificate/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data || []
})

const certificateSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [getAllCertificate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllCertificate.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllCertificate.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = certificateSlice
export default reducer
