import { useState } from 'react'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

const Input = ({
  label,
  value,
  onChange,
  readOnly,
  placeholder,
  error,
  type,
  hasVisable,
  pattern,
  classLabel
}) => {
  const [visible, setVisable] = useState(false)
  return (
    <div className={`w-full`}>
      <label
        htmlFor={'input_' + placeholder}
        className={
          'block lg:text-sm text-lg leading-5 text-primary dark:text-white ' +
          `${classLabel ? classLabel : 'font-medium'}`
        }
      >
        {label}
      </label>
      <div className="relative block">
        <input
          // id={'input_' + placeholder}
          type={visible ? 'text' : type}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder || label}
          readOnly={readOnly || false}
          name={label}
          pattern={pattern}
          className={` ${
            error
              ? 'border-red-600'
              : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
          } mt-1  dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none h-10`}
        />
        {hasVisable && (
          <span
            onClick={() => setVisable(!visible)}
            className="absolute inset-y-0 right-0 flex items-center mr-3"
          >
            {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </span>
        )}
      </div>
      {error && <span className="w-full text-sm text-red-500">{error}</span>}
    </div>
  )
}
Input.defaultProps = {
  type: 'text'
}
export default Input
