import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/information`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllInformations = createAsyncThunk('informations/getAll', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll?orderBy=created_at&orderType=0`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getAllInformations.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllInformations.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllInformations.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    }
  }
})
const { reducer } = informationSlice
const reset = informationSlice.actions.reset
export { reset }
export default reducer
