import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import TimelineIcon from '@mui/icons-material/Timeline'
import LineDebitDevice from './LineDebitDevice'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

ChartJS.register(ArcElement, Tooltip, Legend)

const ChartConnectedDevices = ({ metrics, loading }) => {
  const [devicesStatus, setDevicesStatus] = useState({ online: 0, inUse: 0, offline: 0 })
  const totalDevices = useSelector((state) => state.dashboard.data.deviceCount)
  const [averageDebit, setAverageDebit] = useState({ rx: 0, tx: 0 })
  const [trigger, setTrigger] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    let categorizedDevices
    let sumDebit = { rx: 0, tx: 0, cpt: 0 }
    if (metrics.length === 0) {
      categorizedDevices = { online: 0, inUse: 0, offline: totalDevices }
    } else {
      categorizedDevices = metrics.reduce(
        (status, device) => {
          const rx = parseFloat(device.values.rx_mbps)
          const tx = parseFloat(device.values.tx_mbps)

          const hasDebit = rx > 0 || tx > 0

          if (!hasDebit) {
            status.online += 1
          } else {
            status.inUse += 1
            sumDebit.rx = sumDebit.rx + rx
            sumDebit.tx = sumDebit.tx + tx
            sumDebit.cpt = sumDebit.cpt + 1
          }
          calculateAverageDebit(sumDebit)
          return status
        },
        { online: 0, inUse: 0, offline: totalDevices - metrics.length }
      )
    }
    calculateAverageDebit(sumDebit)
    setDevicesStatus(categorizedDevices)
  }, [metrics, totalDevices])

  const calculateAverageDebit = (sumDebit) => {
    setAverageDebit({
      rx: sumDebit.cpt !== 0 ? sumDebit.rx / sumDebit.cpt : 0,
      tx: sumDebit.cpt !== 0 ? sumDebit.tx / sumDebit.cpt : 0
    })

    setTrigger(!trigger)
  }
  const data = {
    labels: [t('CONNECTED'), t('IN_USE'), t('OFFLINE')],
    datasets: [
      {
        data: [devicesStatus.online, devicesStatus.inUse, devicesStatus.offline],
        backgroundColor: ['#93c5fd', '#86efac', '#fca5a5'],
        borderColor: ['#3b82f6', '#22c55e', '#ef4444'],
        borderWidth: 1
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return (
    <>
      <div className="h-full border border-gray-200 bg-white rounded-lg dark:bg-slate-800 overflow-hidden shadow-sm">
        <div className="flex items-center h-full">
          {loading ? (
            <div className="h-full flex w-full p-4 animate-pulse">
              <div className="grid grid-rows-3 pl-4">
                <div className="row-span-2 relative flex items-center justify-center">
                  <div className="absolute h-28 w-28 rounded-full bg-white" />
                  <div className="h-64 w-64 rounded-full bg-gray-200" />
                </div>
                <div className="text-lg flex justify-center items-center">
                  <ul className="space-y-4">
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border bg-gray-200" />
                      <div className="w-32 h-4 rounded-lg bg-gray-100" />
                    </li>
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border bg-gray-200" />
                      <div className="w-32 h-4 rounded-lg bg-gray-100" />
                    </li>
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border bg-gray-200" />
                      <div className="w-32 h-4 rounded-lg bg-gray-100" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="h-full w-full flex items-center justify-center text-gray-200">
                <TimelineIcon style={{ fontSize: 64 }} />
              </div>
            </div>
          ) : (
            <div className="h-full flex w-full p-4">
              <div className="grid grid-rows-3">
                <div className="row-span-2">
                  <Doughnut data={data} options={options} />
                </div>
                <div className="text-lg flex justify-center items-center">
                  <ul className="space-y-1">
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border border-blue-500 bg-blue-200" />
                      <p>
                        {devicesStatus.online} {t('CONNECTED')}
                      </p>
                    </li>
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border border-red-500 bg-red-200" />
                      <p>
                        {isNaN(devicesStatus.offline) ||
                        devicesStatus.offline <= 0 ||
                        devicesStatus.offline === undefined ||
                        devicesStatus.offline === null
                          ? 0
                          : devicesStatus.offline}{' '}
                        {t('OFFLINE')}
                      </p>
                    </li>
                    <li className="flex items-center space-x-2">
                      <div className="w-4 h-4 border border-green-500 bg-green-200" />
                      <p>
                        {devicesStatus.inUse} {t('IN_USE')}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="h-full w-full">
                <p className="text-lg"> {t('AVG_DEVICES')}</p>
                <hr />
                <div className="flex items-center h-full py-4">
                  <LineDebitDevice
                    rx={averageDebit.rx}
                    tx={averageDebit.tx}
                    trigger={trigger}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      animation: {
                        duration: 0
                      },
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltips: {
                          enabled: false
                        }
                      },
                      scales: {
                        x: {
                          display: false
                        },
                        y: {
                          min: 0,
                          max: 1400,
                          stepSize: 200
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ChartConnectedDevices
