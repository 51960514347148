import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'

import { createWidget as create, resetCreate } from '../../store/widgets/create'
import { editWidget as update, resetUpdate } from '../../store/widgets/edit'
import Success from '../../common/stateInfo/StateInfo'
import Input from '../../common/fields/Input'
import GlobalModal from './Layout'
import { useTranslation } from 'react-i18next'

const WidgetModal = ({ handleClose, row }) => {
  const { t } = useTranslation()
  const createWidget = useSelector((state) => state.createWidget)
  const updateWidget = useSelector((state) => state.updateWidget)
  const [alertType, setAlertType] = useState(null)
  const [error, setError] = useState({})
  const [name, setName] = useState(row ? row.name : '')
  const [slugname, setSlagname] = useState(row ? row.slugname : '')
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useDispatch()
  const prevCreateWidget = useRef()
  const prevUpdateWidget = useRef()
  useEffect(() => {
    if (prevCreateWidget.current && !createWidget.loading) {
      if (createWidget.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetCreate())
    }
    prevCreateWidget.current = createWidget.loading
  }, [createWidget])

  useEffect(() => {
    if (prevUpdateWidget.current && !updateWidget.loading && updateWidget.data) {
      if (updateWidget.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetUpdate())
    }
    prevUpdateWidget.current = updateWidget.loading
  }, [updateWidget])

  const handleSubmit = () => {
    const newError = {}
    if (!name) {
      newError.name = t('WIDGET_NAME_REQUIRED')
    }
    if (!slugname) {
      newError.slugname = t('SLUG_NAME_REQUIRED')
    }
    setError(newError)
    if (!Object.keys(newError).length) {
      if (row) {
        // update
        dispatch(update({ slugname, name, id: row.widget_item_id }))
      } else {
        dispatch(create({ slugname, name }))
      }
    }
  }

  return (
    <GlobalModal
      title={!row ? t('CREATE_WIDGET') : t('EDIT_WIDGET')}
      size="small"
      buttons={[
        !showAlert
          ? {
              title: row ? t('UPDATE') : t('CREATE'),
              action: () => handleSubmit()
            }
          : {},
        {
          type: 'secondary',
          title: 'Close',
          action: handleClose
        }
      ]}
    >
      {showAlert ? (
        <Success type={alertType} />
      ) : (
        <div className="relative p-6 flex-auto">
          <form className="w-full">
            <div className="mb-2 w-full">
              <Input
                label={t('NAME')}
                value={name}
                onChange={setName}
                placeholder="name"
                error={error.name}
              />
            </div>
            <div className="mb-2 w-full">
              <Input
                label="SlugName"
                value={slugname}
                onChange={setSlagname}
                placeholder="SlugName"
                error={error.slugname}
              />
            </div>
          </form>
        </div>
      )}
    </GlobalModal>
  )
}

export default WidgetModal
