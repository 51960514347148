import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('auditing')}/api/audit`

const getMetrics = async (device_uuid) => {
  const res = await axios(`${basedUrl}/metrics?device_uuid=${device_uuid}`, 'GET')
  if (res.status === 'error') return {}
  return res.data
}
export default getMetrics
