import React, { useState, useRef } from 'react'
import useOnClickOutside from '../../utils/useOnClickOutside'

const PageNumber = ({ currentPage, pageCount, handlePageChange }) => {
  const [showPageMenu, setShowPageMenu] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => setShowPageMenu(false))
  function reactToClickPage(pageNumber) {
    setShowPageMenu((showPageMenu) => !showPageMenu)
    handlePageChange(pageNumber + 1)
  }

  if (pageCount !== 0) {
    if (pageCount !== 0) {
      return (
        <div className="relative">
          <button
            onClick={() => setShowPageMenu((showPageMenu) => !showPageMenu)}
            className="px-4 text-window-muted rounded-full bg-window hover:text-window-icon-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-window focus:ring-window transition duration-100"
          >
            {currentPage + ' / ' + pageCount}
          </button>
          {showPageMenu && (
            <div
              ref={ref}
              className="absolute grid grid-cols-2 bottom-0 left-1/2 m-auto w-28 mb-10 p-2 bg-white dark:bg-slate-700 dark:text-gray-200 rounded-3xl shadow transform -translate-x-1/2"
            >
              {[...Array(pageCount)].map((page, index) => (
                <button
                  key={page}
                  onClick={() => reactToClickPage(index)}
                  className={`px-3 py-2 m-1 border rounded-md ${
                    index + 1 == currentPage
                      ? 'border-blue-400 text-blue-500'
                      : 'hover:text-blue-500'
                  }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </div>
      )
    } else {
      return ''
    }
  } else {
    return <div className="px-4 text-window-icon-muted rounded-full">0 / 0</div>
  }
}

export default PageNumber
