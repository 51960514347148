import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getCertificatInfoManager } from './getCertificatInfoManager'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/setting`
const initialState = {
  uploadProgress: 0,
  data: {},
  loading: false,
  error: null
}

export const uploadManagerCertificate = createAsyncThunk(
  'certificate/upload',
  async ({ data }, thunkAPI) => {
    let progress

    const res = await axios(
      `${basedUrl}/uploadManagerCertificate`,
      'POST',
      data,
      {
        'Content-Type': 'multipart/form-data',
        'Content-Encoding': 'gzip'
      },
      (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (progress !== percentCompleted) {
          progress = percentCompleted
          thunkAPI.dispatch(onProgress(percentCompleted))
        }
      }
    )
    if (res.status === 'error') {
      thunkAPI.dispatch(error(res.message))
      return thunkAPI.rejectWithValue(res.message)
    }
    thunkAPI.dispatch(getCertificatInfoManager())
    thunkAPI.dispatch(success())
    return res.data
  }
)
const certificateSlice = createSlice({
  name: 'cert/create',
  initialState,
  reducers: {
    onProgress: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload
      }
    }
  },
  extraReducers: {
    [uploadManagerCertificate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [uploadManagerCertificate.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false, uploadProgress: 0 }
    },
    [uploadManagerCertificate.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false, uploadProgress: 0 }
    }
  }
})

const { reducer } = certificateSlice
const { onProgress } = certificateSlice.actions
export default reducer
