import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'

import { updateUser as update, resetUpdate } from '../../store/users/update'
import { signup, resetSignup } from '../../store/users/create'
import MultiSelect from '../../common/fields/MutliSelect'
import Success from '../../common/stateInfo/StateInfo'
import { isValidEmail } from '../../helpers/form'
import Switch from '../../common/switch/Switch'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'
import GlobalModal from './Layout'

const UserModal = ({ handleClose, row, groups }) => {
  const { t } = useTranslation()
  const createUser = useSelector((state) => state.createUser)
  const updateUser = useSelector((state) => state.updateUser)
  const [alert, setAlertType] = useState({})
  const [error, setError] = useState({})
  const [firstName, setFirstName] = useState(row ? row.first_name : '')
  const [lastName, setLastName] = useState(row ? row.last_name : '')
  const [email, setEmail] = useState(row ? row.email : '')
  const [blocked, setBlocked] = useState(row ? row.blocked : false)
  const [groupIds, setGroupIds] = useState(row ? row.groups : [])
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useDispatch()
  const prevCreateUser = useRef()
  const prevUpdateUser = useRef()
  useEffect(() => {
    if (prevCreateUser.current && !createUser.loading) {
      if (createUser.error) {
        setAlertType({ type: 'error', message: createUser.error })
      } else {
        setAlertType({ type: 'success', message: null })
      }
      setShowAlert(true)
      dispatch(resetSignup())
    }
    prevCreateUser.current = createUser.loading
  }, [createUser])

  useEffect(() => {
    if (prevUpdateUser.current && !updateUser.loading && updateUser.data) {
      if (updateUser.error) {
        setAlertType({ type: 'error', message: updateUser.error })
      } else {
        setAlertType({ type: 'success', message: null })
      }
      setShowAlert(true)
      dispatch(resetUpdate())
    }
    prevUpdateUser.current = updateUser.loading
  }, [updateUser])

  const handleSubmit = () => {
    const errorList = {}
    if (!firstName) {
      errorList.firstName = t('NAME_ERROR')
    }
    if (!lastName) {
      errorList.lastName = t('NAME_ERROR')
    }
    if (!email || !isValidEmail(email)) {
      errorList.email = t('EMAIL_ERROR')
    }
    if (!groupIds.length) {
      errorList.groups = t('USER_GROUP_ERROR')
    }
    if (!Object.keys(errorList).length) {
      if (row) {
        // update
        dispatch(
          update({
            first_name: firstName,
            last_name: lastName,
            groups: groupIds,
            email,
            blocked,
            id: row.id
          })
        )
      } else {
        dispatch(signup({ first_name: firstName, last_name: lastName, groups: groupIds, email }))
      }
    }
    setError(errorList)
  }

  return (
    <GlobalModal
      title={!row ? `${t('CREATE')} ${t('USER')}` : `${t('EDIT')} ${t('USER')}`}
      size="small"
      buttons={[
        !showAlert
          ? {
              title: !row ? `${t('CREATE')}` : `${t('EDIT')}`,
              action: () => handleSubmit()
            }
          : {},
        {
          type: 'secondary',
          title: t('CLOSE'),
          action: handleClose
        }
      ]}
    >
      {showAlert ? (
        <Success type={alert.type} description={alert.message} />
      ) : (
        <div className="relative p-6 flex-auto">
          <form className="w-full">
            <div className="mb-2 w-full flex space-x-2">
              <Input
                label={t('FIRST_NAME')}
                value={firstName}
                onChange={setFirstName}
                placeholder={t('FIRST_NAME')}
                error={error.firstName}
              />
              <Input
                label={t('LAST_NAME')}
                value={lastName}
                onChange={setLastName}
                placeholder={t('LAST_NAME')}
                error={error.lastName}
              />
            </div>
            <div className="mb-2 w-full">
              <Input
                label={t('EMAIL')}
                value={email}
                onChange={setEmail}
                placeholder={t('EMAIL')}
                error={error.email}
              />
            </div>
            {row && row.blocked && (
              <div className="mb-2 w-full">
                <Switch title={'Blocked account'} value={blocked} onChange={setBlocked} />
              </div>
            )}
            <div className="mb-2 w-full">
              <MultiSelect
                label={t('GROUPS')}
                choices={groups}
                values={groupIds}
                onChange={setGroupIds}
                error={error.groups}
              />
            </div>
          </form>
        </div>
      )}
    </GlobalModal>
  )
}

export default UserModal
