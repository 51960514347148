import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const Close = ({ handleClose, color }) => {
  return (
    <button
      onClick={handleClose}
      type="button"
      className={`${
        color ? `text-${color}` : 'text-black'
      } inline-flex justify-center p-1 hover:text-red-300 hover:rotate-90 transition ease-in-out delay-150`}
    >
      <CloseOutlinedIcon />
    </button>
  )
}
export default Close
