import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateSetting } from '../../../store/settings/update'
import Button from '../../../common/buttons/Button'
import Input from '../../../common/fields/Input'
import { useTranslation } from 'react-i18next'

const SessionSection = ({ session }) => {
  const { t } = useTranslation()
  const [type, setType] = useState('h')
  const [number, setNumber] = useState()
  const dispatch = useDispatch()
  const [error, setError] = useState()
  useEffect(() => {
    if (session && session.duration) {
      const unit = session.duration.charAt(session.duration.length - 1)
      if (unit === 'd') {
        setType(unit)
      }

      setNumber(session.duration.replace(/\D/g, ''))
    }
  }, [session])

  const handleUpdate = () => {
    let hasError
    if (!number || number === '0') {
      hasError = t(`${type === 'h' ? 'HOURS' : 'DAYS'}_REQUIRED`)
    }
    setError(hasError)
    if (!hasError) {
      dispatch(
        updateSetting({
          session: {
            duration: `${number}${type}`
          }
        })
      )
    }
  }

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
      <div className="dark:bg-slate-700 bg-gray-200 h-12 flex items-center p-5">
        <h3 className="text-lg">
          {t('CONFIG')} {t('SESSION')}
        </h3>
      </div>
      <div className="p-5">
        <div className="grid grid-cols-4 gap-4 items-center justify-center">
          <label
            htmlFor="type"
            className="block lg:text-sm text-lg leading-5 font-medium text-primary dark:text-white"
          >
            {t('SESSION_DURATION')} :
          </label>
          <select
            id="type"
            onChange={(e) => setType(e.target.value)}
            value={type}
            className="mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none h-10"
          >
            <option value="h">{t('HOURS')}</option>
            <option value="d">{t('DAYS')}</option>
          </select>
          <Input
            error={error}
            value={number}
            placeholder={type === 'h' ? t('HOURS_NUMBER') : t('DAYS_NUMBER')}
            onChange={(val) => setNumber(val.replace(/\D/g, ''))}
          />
        </div>
        <div className="flex justify-end space-x-4 mt-5">
          <Button title={t('SAVE')} onClick={handleUpdate} />
        </div>
      </div>
    </div>
  )
}
export default SessionSection
