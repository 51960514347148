import { Route, Routes, Navigate } from 'react-router-dom'
import { useEffect } from 'react'

import {
  initiateSocket,
  subscribeToDeviceAlert,
  disconnectSocket,
  sendMessage
} from '../utils/socket'

// eslint-disable-next-line
import FirmwareDetails from '../container/FirmwareDetails'
import ServerSetting from '../container/ServerSetting'
import DeviceDetails from '../container/DeviceDetails'
import Maintenance from '../container/Maintenance'
import SuperAdmin from '../container/SuperAdmin'
import Topologic from '../container/Topologic'
import Dashboard from '../container/Dashboard'
import Firmware from '../container/Firmware'
import Devices from '../container/Devices'
import Widget from '../container/Widget'
import Admin from '../container/Admin'
import Profil from '../container/Profil'
import Tags from '../container/Tags'
import Logs from '../container/Logs'
import { ROLES } from '../constant'

const PrivateRoute = ({ user, alerts, onAlertChange }) => {
  useEffect(() => {
    if (user.data.superiorRole === ROLES.ADMIN || user.data.isSuperAdmin) {
      initiateSocket()
      if (
        user.data.superiorRole === ROLES.ADMIN ||
        (user.data.isSuperAdmin && user.data.supervision_mode)
      ) {
        subscribeToDeviceAlert((data) => {
          onAlertChange(data)
        })
        sendMessage('alert/list', () => null)
      }
    }
    return () => {
      disconnectSocket()
    }
  }, [])
  return (
    <Routes>
      {user.data.isSuperAdmin && !user.data.supervision_mode ? (
        <>
          <Route exact path="/dashboard" element={<SuperAdmin />} />
          <Route exact path="/profil" element={<Profil />} />
          <Route exact path="/widget" element={<Widget />} />
          <Route exact path="/firmwares" element={<Firmware />} />
          <Route exact path="/firmware/:id" element={<FirmwareDetails />} />
          <Route exact path="/firmware" element={<FirmwareDetails />} />
        </>
      ) : (
        <>
          <Route exact path="/dashboard" element={<Dashboard alerts={alerts} />} />
          <Route exact path="/logs" element={<Logs />} />
          <Route exact path="/devices" element={<Devices />} />
          <Route exact path="/device/:id" element={<DeviceDetails />} />
          <Route exact path="/profil" element={<Profil />} />
          <Route exact path="/topologicalmap" element={<Topologic />} />
          {(user.data.superiorRole === ROLES.ADMIN ||
            (user.data.isSuperAdmin && user.data.supervision_mode)) && (
            <>
              <Route exact path="/tags" element={<Tags />} />
              <Route exact path="/settings/admin" element={<Admin />} />
              <Route exact path="/settings/server" element={<ServerSetting />} />
              <Route exact path="/settings/updates" element={<Maintenance />} />
            </>
          )}
        </>
      )}
      {/* redirect to default route */}
      <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
    </Routes>
  )
}

export default PrivateRoute
