const GlobalStats = ({ number, title, description, icon, color, loading }) => {
  return (
    <>
      {loading ? (
        <>
          <div className="border border-gray-200 shadow-lg rounded-lg w-full m-auto h-32 bg-white flex relative">
            <div className="animate-pulse h-full bg-gray-100 rounded-r-full pr-28 " />
            <div className="animate-pulse absolute h-full bg-gray-200 rounded-r-full pr-24 " />
            <div className="animate-pulse absolute h-full bg-gray-300 rounded-r-full pr-20 " />

            <div className="grid grid-rows-2 w-full h-full p-4 ">
              <div className="flex items-center justify-start">
                <div className="animate-pulse bg-slate-100 w-24 h-3 rounded-lg" />
              </div>
              <div className="animate-pulse bg-slate-200 w-10 h-3 rounded-lg" />
              <div className=" animate-pulse hidden lg:block bg-slate-100 w-32 h-3 rounded-lg" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="border border-gray-200 shadow-md rounded-lg w-full m-auto h-32 bg-white flex relative">
            <div className={`h-full bg-${color}-200 pr-28 rounded-r-full`} />
            <div className={`absolute h-full bg-${color}-300 rounded-r-full pr-24`} />
            <div className={`absolute h-full bg-${color}-400 rounded-r-full pr-7`}>
              <div className="flex h-full items-center justify-center ml-4 text-white">{icon}</div>
            </div>
            <div className="grid grid-rows-2 w-full h-full p-4 ">
              <div className="flex items-center justify-start">
                <div className="font-bold text-lg">{title}</div>
              </div>
              <div className="text-md">{number}</div>
              <div className=" text-gray-500 text-sm hidden lg:block">{description}</div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default GlobalStats
