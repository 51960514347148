import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/organization`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllCompanies = createAsyncThunk('companies/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return { data: res.data, user: thunkAPI.getState().currentUser }
})

const companySlice = createSlice({
  name: 'customer',
  initialState,
  extraReducers: {
    [getAllCompanies.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllCompanies.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllCompanies.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = companySlice
export default reducer
