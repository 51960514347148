import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/hardware`

const initialState = {
  loading: false,
  data: {},
  error: null
}
export const getOne = createAsyncThunk('device/getOne', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOne/${id}?info=true`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  const user = thunkAPI.getState().currentUser
  return thunkAPI.fulfillWithValue({ data: res.data, user })
})
const hasPermisson = (userGroup, deviceGroup) => {
  for (const id of userGroup) {
    for (const group of deviceGroup) {
      if (group.edit.indexOf(id) !== -1) return true
    }
  }
  return false
}
const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    deleteTagFromDevice: (state, action) => {
      if (state.data.informations && state.data.informations.length && action.payload) {
        return {
          ...state,
          data: {
            ...state.data,
            informations: state.data.informations.map((el, index) => {
              if (index === 0) {
                return { ...el, tags: el.tags.filter((el) => el.device_tag_id !== action.payload) }
              } else {
                return el
              }
            })
          }
        }
      }
    },
    update: (state, action) => {
      return {
        ...state,
        data: {
          ...action.payload.data,
          editable: true
        }
      }
    },
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getOne.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getOne.fulfilled]: (state, action) => {
      return {
        error: null,
        data: {
          ...action.payload.data,
          editable:
            action.payload.user.data.superiorRole === 'user'
              ? hasPermisson(
                  action.payload.user.data.groupIds,
                  action.payload.data.informations[0]?.groups
                )
              : true
        },
        loading: false
      }
    },
    [getOne.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = deviceSlice
const { update, reset, deleteTagFromDevice } = deviceSlice.actions
export { deleteTagFromDevice, update, reset }
export default reducer
