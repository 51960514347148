import { useEffect, useState } from 'react'

const Button = ({ onClick, title, type, font, disabled }) => {
  const [className, setClassName] = useState(
    'text-white bg-blue-600 hover:bg-blue-500 focus:ring-blue-300'
  )
  useEffect(() => {
    switch (type) {
      case 'secondary':
        // setClassName('text-gray-400 bg-gray-50 hover:bg-white')
        setClassName('text-white bg-red-500 hover:bg-red-400 focus:ring-red-300')
        break
      case 'disabled':
        setClassName('text-gray-400 bg-gray-200 cursor-not-allowed')
        break
      case 'back':
        setClassName('bg-gray-200 hover:bg-gray-300')
        break
      default:
        setClassName('text-white bg-blue-600 hover:bg-blue-500 focus:ring-blue-300')
        break
    }
  }, [type])

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      aria-label={title}
      type="button"
      className={`${className} inline-flex justify-center py-2 px-4 border border-transparent shadow-md font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        font ? `text-${font}` : 'text-sm'
      } `}
    >
      {title}
    </button>
  )
}
export default Button
