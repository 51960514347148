import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/setting`
const initialState = {
  data: {},
  loading: false,
  error: null
}

export const getCertificatInfoManager = createAsyncThunk(
  'certificate/get',
  async (data, thunkAPI) => {
    const res = await axios(`${basedUrl}/getInfoCertificat`, 'GET')
    if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
    return res.data
  }
)

const certificateSlice = createSlice({
  name: 'ManagerCertificat',
  initialState,
  extraReducers: {
    [getCertificatInfoManager.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getCertificatInfoManager.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getCertificatInfoManager.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = certificateSlice
export default reducer
