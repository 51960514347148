import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getAllTags } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/tag`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const updateTag = createAsyncThunk('tag/update', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update/${data.id}`, 'PUT', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllTags())
  return res.data
})

export const resetUpdate = createAsyncThunk('tags/reset', async () => {
  return true
})

const tagSlice = createSlice({
  name: 'updateTag',
  initialState,
  extraReducers: {
    [updateTag.pending]: (state) => {
      return { ...state, loading: true }
    },
    [updateTag.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [updateTag.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetUpdate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = tagSlice
export default reducer
