import AddIcon from '@mui/icons-material/Add'

const AddButton = ({ onAdd, size, disabled, id }) => {
  return (
    <div className="w-full flex justify-end ">
      <button
        aria-label="add"
        id={id || 'add-button'}
        onClick={!disabled ? onAdd : null}
        disabled={disabled}
        className={`${size === 'small' ? 'p-1 h-8 rounded-md' : 'p-2'} ${
          disabled ? ' opacity-40 cursor-not-allowed' : 'hover:bg-blue-400'
        } flex items-center rounded-full border bg-gray-400 shadow-md text-white `}
      >
        <AddIcon fontSize="inherit" className="flex" />
      </button>
    </div>
  )
}

export default AddButton
