import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('auditing')}/api/audit`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getLogs = createAsyncThunk('app/logs/getAll', async (data, thunkAPI) => {
  const { startDate, endDate, query, count } = data
  const res = await axios(
    `${basedUrl}/logs?start=${startDate}&end=${endDate}&query=${query}&limit=5000&count=${
      count ? count : false
    }`,
    'GET'
  )
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const userSlice = createSlice({
  name: 'app-logs',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state) => {
        state.loading = true
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(getLogs.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload
      })
  }
})
const { reducer } = userSlice
const reset = userSlice.actions.reset
export { reset }
export default reducer
