import { uploadManagerCertificate } from '../../../store/certificate/uploadManagerCertificate'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { FileConvertSize } from '../../../helpers/string'
import SecurityIcon from '@mui/icons-material/Security'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../common/buttons/Button'
import Input from '../../../common/fields/Input'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Explain from '../../../common/Tooltip/Explain'

const CertificateSection = ({ data }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState()
  const [certFile, setCertFile] = useState({ file: null, fileName: '', size: null })
  const [error, setError] = useState({})
  const dispatch = useDispatch()
  const upload = useSelector((state) => state.uploadManagerCertificate)
  const validate = () => {
    let error = {}
    if (!certFile.fileName) {
      error.file = t('SELECT_FILE')
    }
    if (!password) {
      error.password = t('ENTER_PASSWORD')
    }
    setError(error)
    if (Object.keys(error).length === 0) {
      let file = new FormData()
      file.append('file', certFile.file, 'cert.p12')
      const blob = new Blob([password], { type: 'plain/text' })
      file.append('pwd', blob, `cert.pwd`)
      dispatch(uploadManagerCertificate({ data: file }))
    }
  }
  const handleFile = (file, set) => {
    if (file) {
      set((prev) => ({
        ...prev,
        fileName: file.name,
        file: file,
        size: file.size
      }))
    }
  }

  useEffect(() => {
    if (upload.status === 'success') {
      setCertFile({ file: null, fileName: '', size: null })
      setPassword('')
    }
  }, [upload])

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
      <div className="dark:bg-slate-700 bg-gray-200 h-12 flex items-center p-5">
        <h3 className="text-lg">{t('CERT_CONFIG')}</h3>
      </div>
      <div className="p-5">
        <div className="flex justify-between space-x-4 pb-8">
          <div>
            <p className="flex items-center space-x-2">
              <SecurityIcon />
              {t('IDENTITY_CERTIFICATE')}
            </p>
            <div className="border border-gray-200 h-full">
              <div className="p-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('EXPIRATION_DATE')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.expiration_date || 'N/A'}
                  </span>
                </div>
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('MANAGER_ISSUER')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.issuer || 'N/A'}
                  </span>
                </div>
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('MANAGER_SUBJECT')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.subject || 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="flex items-center space-x-2">
              <SecurityIcon />
              {t('TRUSTED_CA_CERT_INFO')}
            </p>
            <div className="border border-gray-200 h-full">
              <div className="p-2">
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('EXPIRATION_DATE')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.ca_expiration_date || 'N/A'}
                  </span>
                </div>
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('MANAGER_ISSUER')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.issuer1 || 'N/A'}
                  </span>
                </div>
                <div className="flex">
                  <label className="text-sm font-medium text-gray-500 dark:text-white">
                    {t('MANAGER_SUBJECT')}:
                  </label>
                  <span className="text-sm text-gray-500 pl-3 font-normal dark:text-white">
                    {data?.subject1 || 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" text-sm font-medium flex space-x-2">
          <p>{t('SELECT_CERTIFICATE_FILE')}</p>
          <Explain description={t('UPLOAD_MANAGER_CERT')} />
        </div>
        <div className="flex flex-row items-center">
          <input
            type="file"
            id="ca"
            onChange={(e) => {
              handleFile(e.target.files[0], setCertFile)
              setError({})
            }}
            hidden
          />
          <label
            htmlFor="ca"
            className="block text-sm py-2 px-4 whitespace-nowrap rounded-l-md border-0 font-semibold bg-blue-50 text-blue-700 hover:bg-blue-100 cursor-pointer"
          >
            {t('CHOOSE_FILE')}
          </label>
          <label
            className={`text-sm p-2 text-slate-500 border ${
              error.file ? 'border-red-600' : 'border-gray-200'
            } rounded-r-md h-9 w-full flex `}
          >
            {certFile.fileName}
            {certFile.size && (
              <div className="bg-green-600 px-2 text-white rounded-full py-0.5 text-xs ml-4 flex items-center justify-center">
                {FileConvertSize(certFile.size)}
              </div>
            )}
          </label>
        </div>
        {error.file && <p className="text-sm text-red-500">{error.file}</p>}
        <Input
          label={t('PASSWORD')}
          value={password}
          onChange={(v) => {
            setPassword(v)
            setError({})
          }}
          type="password"
          error={error.password}
        />
        <div className="flex justify-end w-full py-10">
          <Button title={t('SAVE')} onClick={validate} disabled={upload.loading} />
        </div>
      </div>
    </div>
  )
}

export default CertificateSection
