import { NavLink } from 'react-router-dom'

const Link = (props) => {
  const isActive = window.location.pathname.includes(props.name.toLowerCase()) ? true : false
  const isActiveDevices =
    props.path === '/devices'
      ? window.location.pathname.includes('/device')
        ? true
        : false
      : isActive
  return (
    <NavLink
      to={props.disable ? window.location.pathname : props.path}
      className={() => {
        props.disable
          ? ' dark:text-white text-slate-700 flex items-center justify-center text-md font-medium relative '
          : 'dark:text-white text-slate-700 flex items-center justify-center text-md font-medium relative'
      }}
    >
      {props.disable ? (
        <div className="rounded-2xl mx-1 px-3 py-4 mt-2 cursor-default">
          <div className={`text-gray-300 dark:text-slate-200/50`}>
            <div className="flex justify-center">{props.icon}</div>
            <div className="text-xs text-center">{props.label}</div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            isActiveDevices
              ? `${
                  props.primary ? 'bg-gray-100 dark:bg-slate-600' : 'bg-gray-200 dark:bg-slate-500'
                } `
              : `${
                  props.primary
                    ? 'hover:bg-gray-100 dark:hover:bg-slate-600'
                    : 'hover:bg-gray-200 dark:hover:bg-slate-500'
                } `
          } rounded-2xl mx-1 py-4 mt-2 text-gray-700 dark:text-gray-200`}
        >
          <div className={isActiveDevices ? `text-blue-500 dark:text-blue-400` : ''}>
            <div className="flex justify-center">{props.icon}</div>
            <div className="text-xs text-center text-slate-700 dark:text-gray-200">
              {props.label}
            </div>
          </div>
        </div>
      )}
    </NavLink>
  )
}
export default Link
