import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error, success } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/certificate`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const uploadCertificate = createAsyncThunk('certificate/upload', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/upload/${data.id}`, 'POST', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  return res.data
})

const certificateSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [uploadCertificate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [uploadCertificate.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [uploadCertificate.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = certificateSlice
export default reducer
