import ReactTooltip from 'react-tooltip'

import PersonIcon from '@mui/icons-material/Person'

import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import Action from '../actions/row/Actions'

const Row = ({ cols, row, onDelete, onEdit }) => {
  return (
    <tr className="border-b dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input
          aria-label="Select group"
          id={'group_' + row.group_id}
          type="checkbox"
          className="w-4 h-4 "
        />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap">
              {col.key === 'created_at'
                ? fullDateFormat(row[col.key])
                : col.key === 'roles'
                ? capitalizeFirstLetter(row[col.key].toString().replaceAll('_', ' '))
                : row[col.key]}
            </td>
          )
        } else {
          if (col.label === 'Members') {
            return (
              <td
                key={key}
                className="text-sm font-light px-6 py-4 whitespace-nowrap "
                data-tip=""
                data-for={`info-${row.group_id}`}
                data-class="react-component-tooltip"
              >
                <div className="flex items-stretch">
                  <PersonIcon fontSize="small" /> ● &nbsp;
                  <div>
                    <button>{row.users ? row.users.length : 0}</button>
                    <ReactTooltip id={`info-${row.group_id}`}>
                      <ul>
                        {row.users.map((el, index) => {
                          return (
                            <li key={index} className="text-sm text-secondary">
                              {el.first_name}&nbsp;{el.last_name}
                            </li>
                          )
                        })}
                      </ul>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            )
          }
          if (col.label === 'Action') {
            return (
              <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                <Action row={row} onDelete={(row) => onDelete(row.group_id)} onEdit={onEdit} />
              </td>
            )
          }
        }
      })}
    </tr>
  )
}

export default Row
