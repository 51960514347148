import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/group`
const initialState = {
  loading: false,
  data: [],
  error: null,
  isCombineted: false
}
export const combinetGroup = createAsyncThunk('group/combineted', async (data, thunkAPI) => {
  const currentDeviceGroup = thunkAPI.getState().deviceGroup
  const currentUserGroup = thunkAPI.getState().groups
  if (
    !currentDeviceGroup.isCombineted &&
    !currentDeviceGroup.loading &&
    currentDeviceGroup.data &&
    !currentUserGroup.loading &&
    currentUserGroup.data &&
    currentUserGroup.data.count
  ) {
    return currentUserGroup.data.rows
  }
  return thunkAPI.rejectWithValue(null)
})

export const getAllDeviceGroups = createAsyncThunk('group/getAll', async (page, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const deviceGroupSlice = createSlice({
  name: 'deviceGroups',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getAllDeviceGroups.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllDeviceGroups.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllDeviceGroups.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    },
    [combinetGroup.fulfilled]: (state, action) => {
      return {
        ...state,
        isCombineted: true,
        data: {
          ...state.data,
          rows: state.data.rows.map((el) => ({
            ...el,
            edit:
              el.edit && el.edit.length
                ? el.edit.map((i) => ({
                    group_id: i,
                    name: action.payload.find((k) => k.group_id === i)?.name
                  }))
                : [],
            read:
              el.read && el.read.length
                ? el.read.map((i) => ({
                    group_id: i,
                    name: action.payload.find((k) => k.group_id === i)?.name
                  }))
                : []
          }))
        }
      }
    }
  }
})
const { reducer } = deviceGroupSlice
const reset = deviceGroupSlice.actions.reset
export { reset }
export default reducer
