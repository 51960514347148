import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { switchOganization } from '../../store/currentUser/auth'
import DeleteCompany from '../modals/DeleteCompany'
import EditCompany from '../modals/EditCompany'
import AddCompany from '../modals/AddCompany'
import Spinner from '../spinner/Spinner'

const Companies = ({ data }) => {
  const dispatch = useDispatch()
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const { t } = useTranslation()
  console.log(data.data.data)

  return (
    <div className="w-full">
      {showModalAdd && <AddCompany setShowModalAdd={setShowModalAdd} />}
      {showModalEdit && <EditCompany setShowModalEdit={setShowModalEdit} data={showModalEdit} />}
      {showModalDelete && (
        <DeleteCompany
          name={showModalDelete.name}
          id={showModalDelete.id}
          setShowModalDelete={setShowModalDelete}
        />
      )}

      {data.loading ? (
        <Spinner version={1} isContainer />
      ) : (
        <table className="min-w-full dark:bg-slate-700 dark:text-gray-200">
          <thead className="bg-white border-b dark:bg-slate-700">
            <tr className="">
              <th scope="col" className="text-sm font-medium px-4 py-4 text-left ">
                #
              </th>
              <th scope="col" className="text-sm font-medium px-4 py-4 text-left ">
                {t('NAME')} {t('COMPANY')}
              </th>
              <th scope="col" className="text-sm font-medium px-4 py-4 text-left">
                {t('EMAIL')}
              </th>
              <th scope="col" className="text-sm font-medium px-4 py-4 text-left">
                {t('PHONE')}
              </th>

              <th scope="col" className="text-sm font-medium px-4 py-4 text-left flex justify-end">
                {data.data.data?.length <= 0 && (
                  <button
                    className="p-2 bg-blue-500 rounded-full text-white hover:bg-blue-400"
                    onClick={() => {
                      setShowModalAdd(true)
                    }}
                  >
                    <AddIcon />
                  </button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.data.data?.map((org, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white dark:bg-slate-800 border-b transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-slate-600"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                    {org.organization_id}
                  </td>
                  <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                    {org.name || 'N/A'}
                  </td>
                  <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                    {org.email || 'N/A'}
                  </td>
                  <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                    {org.phone || 'N/A'}
                  </td>
                  <td className="text-sm font-light px-6 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center">
                      <NavLink
                        to="/Dashboard"
                        onClick={() => dispatch(switchOganization(org.organization_id))}
                        className="p-2 text-gray-700 hover:bg-purple-100 hover:text-purple-500 dark:text-white dark:hover:text-purple-500 rounded-full flex items-center justify-center w-fit"
                      >
                        <RemoveRedEyeIcon />
                      </NavLink>
                      <button
                        onClick={() => setShowModalEdit(org)}
                        className="rounded-full flex items-center p-2 text-gray-700 hover:bg-blue-100 hover:text-blue-500 dark:text-white dark:hover:text-blue-500"
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Companies
