import Switch from '../../../common/switch/Switch'
import Input from '../../../common/fields/Input'
import { useTranslation } from 'react-i18next'

const EndPoint = ({ data, handleChange, error, setUploadError, uploadError, id, changes }) => {
  const { t } = useTranslation()
  const selectAuth = (mode) => {
    handleChange('configs.security.ep.auth_mode', mode)
    if (mode === null) {
      handleChange('configs.security.ep.auth_required', false)
    } else {
      handleChange('configs.security.ep.auth_required', true)
    }
  }
  const selectProtocol = (protocol) => {
    handleChange('configs.security.ep.radius.protocol', protocol)
  }
  console.log(data)
  return (
    <form className="w-full flex flex-col space-y-4">
      <Switch
        title={`${t('PSK')} ${changes['configs.security.ep.auth_mode'] ? '●' : ''}`}
        value={data?.auth_mode === 'psk'}
        description={t('PSK_DESC')}
        onChange={(v) => {
          selectAuth(v ? 'psk' : 'psk')
          selectProtocol('')
        }}
      />

      {data?.auth_mode === 'psk' && (
        <Input
          label={`${t('PSK_PASSWORD')} ${changes['configs.security.ep.password'] ? '●' : ''}`}
          type="password"
          hasVisable={true}
          value={data.password}
          onChange={(v) => handleChange('configs.security.ep.password', v)}
          error={error.security.psk_password}
        />
      )}
      <hr />
      <Switch
        title={`${t('EAP')} ${changes['configs.security.ep.auth_mode'] ? '●' : ''}`}
        value={data?.auth_mode === 'EAP'}
        description={t('EAP_DESC')}
        onChange={(v) => {
          selectAuth(v ? 'EAP' : null)
          selectProtocol(v ? 'plain' : '')
        }}
        disabled={true}
      />
      {data?.auth_mode === 'EAP' && (
        <div className="flex flex-col space-y-2">
          <div className=" border p-5 space-y-3">
            <label className="p-2">{`${t('SELECT_EAP_PROTOCOL')} ${
              changes['configs.security.host.radius.protocol'] ? '●' : ''
            }`}</label>
            <hr />
            <div className="flex w-full items-center justify-between px-12">
              <div
                className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
                onClick={() => selectProtocol('plain')}
              >
                <input
                  type="radio"
                  name="protocol"
                  value="plain"
                  checked={data?.radius.protocol === 'plain'}
                />
                <span>{t('PLAIN')}</span>
              </div>
              <div
                className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
                onClick={() => selectProtocol('radsec')}
              >
                <input
                  type="radio"
                  name="protocol"
                  value="radsec"
                  checked={data?.radius.protocol === 'radsec'}
                />
                <span>{t('RADSEC')}</span>
              </div>
            </div>
          </div>
          <Input
            label={`${t('IP_RADIUS')} ${changes['configs.security.ep.radius.ip'] ? '●' : ''}`}
            value={data?.radius.ip}
            onChange={(v) => handleChange('configs.security.ep.radius.ip', v)}
            error={error.security.radius_ip_ep}
          />
          <Input
            label={`${t('PORT_RADIUS')} ${changes['configs.security.ep.radius.port'] ? '●' : ''}`}
            value={data?.radius.port}
            onChange={(v) => handleChange('configs.security.ep.radius.port', parseInt(v))}
            error={error.security.radius_port_ep}
          />

          <Input
            label={`${t('SECRET_RADIUS')} ${
              changes['configs.security.ep.radius.secret'] ? '●' : ''
            }`}
            value={data?.radius.secret}
            type={'password'}
            hasVisable={true}
            onChange={(v) => handleChange('configs.security.ep.radius.secret', v)}
            error={error.security.radius_secret_ep}
          />
        </div>
      )}
      <hr />
      <Switch
        title={`${t('ENFORCE_POLICIES')} ${
          changes['configs.security.ep.enforce_policies'] ? '●' : ''
        }`}
        value={data?.enforce_policies}
        description={t('ENFORCE_POLICIES_DESC')}
        onChange={(v) => handleChange('configs.security.ep.enforce_policies', v)}
        disabled={false}
      />
    </form>
  )
}

export default EndPoint
