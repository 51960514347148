import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('auditing')}/api/audit`
const initialState = {
  loading: false,
  data: [],
  error: null,
  device_uuid: null
}

export const getLogs = createAsyncThunk('device/logs/getAll', async (data, thunkAPI) => {
  const { startDate, endDate, query } = data
  const res = await axios(
    `${basedUrl}/logs?start=${startDate}&end=${endDate}&query=${query}&limit=5000`,
    'GET'
  )
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return thunkAPI.fulfillWithValue({ data: res.data, device_uuid: data.device_uuid })
})

const userSlice = createSlice({
  name: 'device-logs',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLogs.pending, (state) => {
        state.device_uuid = null
        state.loading = true
      })
      .addCase(getLogs.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
        state.device_uuid = action.payload.device_uuid
      })
      .addCase(getLogs.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload
      })
  }
})
const { reducer } = userSlice
export default reducer
