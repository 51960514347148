import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/certificate`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const downloadCsr = createAsyncThunk('certificate/downloadCsr', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getCsr/${data.id}/${data.format}/${data.app}`, 'GET')
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }

  return res
})

const deviceSlice = createSlice({
  name: 'downloadCsr',
  initialState,
  extraReducers: {
    [downloadCsr.pending]: (state) => {
      return { ...state, loading: true }
    },
    [downloadCsr.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [downloadCsr.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = deviceSlice
export default reducer
