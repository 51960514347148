import { PortValidator, capitalizeFirstLetter } from '../../helpers/string'
import { getAllDeviceGroups } from '../../store/deviceGroups/getAll'
import { updateInformation } from '../../store/informations/update'
import ExitPropertiesPanel from '../modals/ExitPropertiesPanel'
import useOnClickOutside from '../../utils/useOnClickOutside'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTags } from '../../store/tags/getAll'
import { useEffect, useState, useRef } from 'react'
import { IpValidator } from '../../helpers/string'
import Button from '../../common/buttons/Button'
import Close from '../../common/buttons/Close'
import { useTranslation } from 'react-i18next'
import DeviceAlerting from './DeviceAlerting'
import DeviceSecurity from './DeviceSecurity'
import DeviceNetwork from './DeviceNetwork'
import equals from '../../helpers/array'
import DeviceInfo from './DeviceInfo'

const PropertiesPanel = ({ handleClose, device, willClose }) => {
  const groups = useSelector((state) => state.deviceGroup)
  const tags = useSelector((state) => state.tags)

  const [levels, setLevels] = useState(device.informations[0]?.tags?.length || 0)
  const [uploadError, setUploadError] = useState({ dot1x: {}, tls: {}, eap: {}, host: {} })
  const [groupsChoice, setGroupsChoices] = useState({})
  const [navIndex, setNavIndex] = useState(0)
  const [error, setError] = useState({ general: {}, network: {}, security: {}, alerting: {} })
  const [data, setData] = useState({
    alias: device.informations[0]?.alias,
    hostname: device.hostname,
    device_hardware_id: device.device_uuid,
    groups: device.informations[0]?.groups.map((el) => el.device_group_id) || [],
    tags:
      device.informations[0]?.tags.map((v) => ({
        value: v.device_tag_id,
        label: v.name,
        color: v.color
      })) || [],
    configs: device.informations[0]?.configs || { network: {}, security: {}, alerting: {} }
  })
  const [changes, setChanges] = useState({})
  const [initialData] = useState(data)
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useDispatch()
  const prevGroups = useRef()

  const { t } = useTranslation()

  const ref = useRef({})

  useOnClickOutside(ref, (e) => {
    if (
      !document.getElementById('proprietePannel').contains(e.target) &&
      !document.getElementById('pushNotification').contains(e.target) &&
      !willClose
    ) {
      console.log(changes)
      if (Object.keys(changes).length > 0) setShowAlert(true)
      else handleClose()
    }
  })

  useEffect(() => {
    setError({ general: {}, network: {}, security: {}, alerting: {} })
    //Check Security
    if (
      data.configs.security.ep?.auth_mode === 'psk' &&
      data.configs.security.ep?.password === ''
    ) {
      setError((prev) => {
        return {
          ...prev,
          security: {
            ...prev.security,
            psk_password: t('PSK_PASSWORD_REQUIRED')
          }
        }
      })
    }

    if (data.configs.security.ep?.auth_mode === 'EAP') {
      if (!IpValidator(data.configs.security.ep?.radius.ip)) {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_ip_ep: t('IP_RADIUS_INVALID')
            }
          }
        })
      }
      if (!PortValidator(data.configs.security.ep.radius.port)) {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_port_ep: t('PORT_RADIUS_INVALID')
            }
          }
        })
      }

      if (data.configs.security.ep.radius.secret === '') {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_secret_ep: t('SECRET_RADIUS_REQUIRED')
            }
          }
        })
      }
    }

    if (data.configs.security.host?.auth_required) {
      if (!IpValidator(data.configs.security.host?.radius.ip)) {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_ip_host: t('IP_RADIUS_INVALID')
            }
          }
        })
      }

      if (!PortValidator(data.configs.security.host?.radius.port)) {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_port_host: t('PORT_RADIUS_INVALID')
            }
          }
        })
      }

      if (
        data.configs.security.host?.radius.secret === '' &&
        data.configs.security.host?.radius.protocol === 'plain'
      ) {
        setError((prev) => {
          return {
            ...prev,
            security: {
              ...prev.security,
              radius_secret_host: t('SECRET_RADIUS_REQUIRED')
            }
          }
        })
      }
    }

    //Check Network
    if (data.configs.network.dhcp === false) {
      if (!IpValidator(data.configs.network.ip_address)) {
        setError((prev) => {
          return {
            ...prev,
            network: {
              ...prev.network,
              ip_address: t('IP_ADDRESS_INVALID')
            }
          }
        })
      }
      if (!IpValidator(data.configs.network.netmask)) {
        setError((prev) => {
          return {
            ...prev,
            network: {
              ...prev.network,
              netmask: t('NETMASK_INVALID')
            }
          }
        })
      }
      if (!IpValidator(data.configs.network.gateway)) {
        setError((prev) => {
          return {
            ...prev,
            network: {
              ...prev.network,
              gateway: t('GATEWAY_INVALID')
            }
          }
        })
      }
    }
    // when dns2 is empty after modifying it
    if (data.configs.network.dns2?.length === 0) {
      // eslint-disable-next-line
      const { dns2, ...network } = data.configs.network
      setData((prev) => ({ ...prev, configs: { ...prev.configs, network } }))
    }

    // when alerting are not in data
    if (data.configs.alerting?.bond0_bytes_threshold === false) {
      // eslint-disable-next-line
      const { alerting, ...config } = data.configs
      setData((prev) => ({ ...prev, configs: config }))
    }
    // when alerting are not in data
    if (data.alias?.length === 0) {
      // eslint-disable-next-line
      const { alias, ..._config } = data
      setData((prev) => ({ ...prev, alias: null }))
    }

    console.log(data, initialData)
    const detectedChanges = deepCompare(initialData, data)
    setChanges(detectedChanges)
  }, [data])
  const deepCompare = (obj1, obj2, path = '', changes = {}) => {
    console.log(path)

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        changes[path] = true
      }
      return changes
    }

    for (let key in obj1) {
      const newPath = path ? `${path}.${key}` : key
      if (!(key in obj2)) {
        changes[newPath] = true
      } else {
        deepCompare(obj1[key], obj2[key], newPath, changes)
      }
    }

    for (let key in obj2) {
      if (!(key in obj1)) {
        const newPath = path ? `${path}.${key}` : key
        changes[newPath] = true
      }
    }

    return changes
  }

  useEffect(() => {
    const el = document.getElementById('proprietePannel')
    setTimeout(() => el?.classList.replace('translate-x-full', 'translate-x-0'), 50)
    if (!groups.data.count && !groups.loading) {
      dispatch(getAllDeviceGroups())
    } else {
      groupChoicesParcer()
    }
    if (!tags.data.length && !tags.loading) {
      dispatch(getAllTags())
    }
    return () => setTimeout(() => el?.classList.replace('translate-x-0', 'translate-x-full'), 50)
  }, [])

  const handleChange = (key, value) => {
    if (key === 'tags') {
      setLevels(value.length)
    }

    setData((prev) => {
      const keys = key.split('.')
      const updatedState = Object.assign({}, prev)

      let nestedState = updatedState
      for (let i = 0; i < keys.length - 1; i++) {
        nestedState[keys[i]] = Object.assign({}, nestedState[keys[i]])
        nestedState = nestedState[keys[i]]
      }
      nestedState[keys[keys.length - 1]] = value
      return updatedState
    })
  }

  const getItems = () => {
    let list = []
    if (!levels && tags.data[levels]) {
      list = tags.data[levels]
    } else if (
      levels &&
      data.tags[levels - 1] &&
      tags.data[levels] &&
      tags.data[levels][data.tags[levels - 1].value]
    ) {
      list = tags.data[levels][data.tags[levels - 1].value]
    } else {
      return list
    }
    return list && list.length
      ? list.map((el) => ({ label: el.name, value: el.device_tag_id, color: el.color }))
      : []
  }

  useEffect(() => {
    if (prevGroups.current && !groups.loading && groups.data.count) {
      groupChoicesParcer()
    }
    prevGroups.current = groups.loading
  }, [groups])

  const groupChoicesParcer = () => {
    const list = {}
    for (const group of groups.data.rows) {
      list[group.device_group_id] = `${capitalizeFirstLetter(group.name)}`
    }
    setGroupsChoices(list)
  }

  const handleSave = () => {
    if (
      !Object.keys(error.security).length &&
      !Object.keys(error.network).length &&
      !Object.keys(error.general).length
    ) {
      let body = data
      const newTags = data.tags.map((el) => el.value)
      if (
        !equals(
          newTags,
          device.informations[0].tags.map((e) => e.device_tag_id)
        )
      ) {
        body.tags = newTags
      }
      body.device_uuid = device.device_uuid
      body.device_hardware_id = device.device_hardware_id
      body.id = device.informations[0]?.device_informations_uuid
      dispatch(updateInformation(body))
      handleClose()
    }
  }

  const exportConfig = () => {
    let dataToExport = {
      alias: device.informations[0].alias,
      network: device.informations[0].configs.network,
      security: device.informations[0].configs.security,
      tags: device.informations[0].tags.map((tag) => ({
        value: tag.device_tag_id,
        label: tag.name,
        color: tag.color
      }))
    }
    dataToExport = JSON.stringify(dataToExport)
    const file = new Blob([dataToExport], { type: 'application/json' })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = 'device_config-' + device.device_uuid + '.config'
    document.body.appendChild(element)
    element.click()
  }

  return (
    <div className="fixed inset-0 z-20 overflow-hidden">
      {showAlert && (
        <ExitPropertiesPanel
          handleClose={() => setShowAlert(false)}
          handleConfirm={() => {
            setShowAlert(false)
            handleClose()
          }}
        />
      )}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-gray-600 opacity-50" />
      </div>
      <div className="absolute inset-0 z-0 overflow-hidden">
        <section className="absolute inset-y-0 max-w-full right-0 flex">
          <div className="w-screen max-w-md">
            <div
              ref={ref}
              id="proprietePannel"
              className="h-full divide-y divide-gray-200 flex flex-col bg-white dark:bg-slate-800 shadow-xl transform transition-all duration-500 ease-in-out translate-x-full"
              style={{ transform: willClose === true && 'translateX(500px)' }}
            >
              <div className="flex-1 h-0">
                <header className="space-y-1 h-15 w-full top-0 py-4 px-4 bg-blue-500 sm:px-6">
                  <div className="flex items-center justify-between space-x-3">
                    <h2 className="text-lg leading-7 font-medium text-white">
                      {t('CONFIG_PANEL')}
                    </h2>
                    <div className="h-7 flex items-center">
                      <Close handleClose={handleClose} color="white" />
                    </div>
                  </div>
                </header>

                <div className="h-full flex flex-col justify-between">
                  <div className="w-full border-b border-gray-200 flex justify-between">
                    <button
                      onClick={() => setNavIndex(0)}
                      className={`${
                        !navIndex
                          ? ' text-blue-600 bg-gray-100'
                          : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100 dark:text-white'
                      } py-4 px-4 text-sm text-center w-full`}
                    >
                      {t('GENERAL')}
                    </button>
                    <button
                      onClick={() => setNavIndex(1)}
                      className={`${
                        navIndex === 1
                          ? ' text-blue-600 bg-gray-100'
                          : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100 dark:text-white'
                      } py-4 px-4 text-sm text-center w-full`}
                    >
                      {`${t('NETWORK')} ${
                        Object.keys(changes).some((key) => key.includes('network')) ? '●' : ''
                      } `}
                    </button>
                    <button
                      onClick={() => setNavIndex(2)}
                      className={`${
                        navIndex === 2
                          ? ' text-blue-600 bg-gray-100'
                          : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100 dark:text-white'
                      } py-4 px-4 text-sm text-center w-full`}
                    >
                      {`${t('SECURITY')} ${
                        Object.keys(changes).some((key) => key.includes('security')) ? '●' : ''
                      }`}
                    </button>
                    <button
                      onClick={() => setNavIndex(3)}
                      className={`${
                        navIndex === 3
                          ? ' text-blue-600 bg-gray-100'
                          : 'text-gray-500 hover:text-blue-500 hover:bg-gray-100 dark:text-white'
                      } py-4 px-4 text-sm font-medium text-center w-full`}
                    >
                      {t('ALERTING')}
                    </button>
                  </div>
                  <div className=" divide-y overflow-auto h-full divide-gray-200">
                    {!navIndex ? (
                      <div className="">
                        <DeviceInfo
                          data={data}
                          handleChange={handleChange}
                          groupsChoice={groupsChoice}
                          tags={getItems}
                          exportConfig={exportConfig}
                          error={error}
                          changes={changes}
                        />
                      </div>
                    ) : navIndex == 1 ? (
                      <div className="">
                        <DeviceNetwork
                          data={data.configs.network}
                          handleChange={handleChange}
                          error={error}
                          changes={changes}
                        />
                      </div>
                    ) : navIndex == 2 ? (
                      <div className="">
                        <DeviceSecurity
                          data={data.configs.security}
                          handleChange={handleChange}
                          error={error}
                          setError={setError}
                          id={data.device_hardware_id}
                          uploadError={uploadError}
                          setUploadError={setUploadError}
                          handleClose={handleClose}
                          changes={changes}
                        />
                      </div>
                    ) : (
                      <div className="">
                        <DeviceAlerting
                          data={data.configs.alerting}
                          handleChange={handleChange}
                          error={error}
                          changes={changes}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0 px-4 py-4 shadow-lg space-x-4 z-20 flex justify-end bg-white">
                <span className="inline-flex rounded-md shadow-sm">
                  <Button onClick={() => handleClose()} title={t('CANCEL')} type="secondary" />
                </span>
                <span className="inline-flex rounded-md shadow-sm">
                  <Button onClick={() => handleSave()} title={t('SAVE')} />
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default PropertiesPanel
