import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`

const download = async (moduleId, artifactId, fileName) => {
  return await axios(`${basedUrl}/downloadArtifact/${moduleId}/${artifactId}`, 'GET', {
    Connection: 'keep-alive'
  })
    .then((response) => {
      if (response.status === 'success') {
        const fileData = response.data
        const blob = new Blob([new Uint8Array(fileData.data)], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)

        // Create a temporary anchor element to trigger the download
        const tempAnchor = document.createElement('a')
        tempAnchor.href = url
        tempAnchor.download = fileName // Replace 'file.extension' with the desired filename and extension
        tempAnchor.click()

        // Release the object URL after the download has started
        URL.revokeObjectURL(url)
      }
      return true
    })
    .catch((error) => {
      console.error('Error downloading file:', error.message)
      return false
    })
}

export default download
