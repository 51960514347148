import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/widgetItem`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllWidgets = createAsyncThunk('widget/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [getAllWidgets.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllWidgets.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllWidgets.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = widgetSlice
export default reducer
