import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getAllDevices } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/hardware`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const deleteDevice = createAsyncThunk('device/deleteDevice', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/delete/${id}`, 'DELETE')
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.error))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(getAllDevices())
  thunkAPI.dispatch(success(res.msg))
  return res.data
})

const deviceSlice = createSlice({
  name: 'deleteDevice',
  initialState,
  extraReducers: {
    [deleteDevice.pending]: (state) => {
      return { ...state, loading: true }
    },
    [deleteDevice.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [deleteDevice.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = deviceSlice
export default reducer
