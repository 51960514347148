import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import DeleteConfiramation from '../components/modals/DeleteConfirmation'
import TagModal from '../components/modals/TagModal'
import { getAllTags } from '../store/tags/getAll'
import { deleteTag } from '../store/tags/delete'
import Tree from '../components/tags/Tree'

const Tags = () => {
  const [data, setData] = useState({ show: false, parentId: null, row: null })
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null)
  const tags = useSelector((state) => state.tags)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!tags.data.length && !tags.loading) {
      dispatch(getAllTags())
    }
  }, [])
  const handleDelete = () => {
    dispatch(deleteTag(itemToBeDeleted))
    setItemToBeDeleted(null)
  }
  return (
    <>
      {!!itemToBeDeleted && (
        <DeleteConfiramation
          action="tag"
          handleClose={() => setItemToBeDeleted(null)}
          handleConfirm={handleDelete}
        />
      )}
      {data.show && (
        <TagModal
          parentId={data.parentId}
          row={data.row}
          handleClose={() =>
            setData({
              show: false,
              parentId: null,
              row: null
            })
          }
        />
      )}

      <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar">
        <div className="flex flex-col border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden">
          <div className="h-full inline-block w-full">
            <Tree
              data={tags.data}
              isLoading={tags.loading}
              onEdit={(row) => {
                setData({
                  show: true,
                  row
                })
              }}
              onDelete={setItemToBeDeleted}
              onAdd={(parentId) => {
                setData({
                  show: true,
                  parentId
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Tags
