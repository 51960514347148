import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllUpdate = createAsyncThunk('update/getAllRollout', async (data, thunkAPI) => {
  const query = data?.limit ? `?limit=${data?.limit}&offset=${data?.offset}` : ''
  const res = await axios(`${basedUrl}/getAllRollout${query}`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const allUpdateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getAllUpdate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllUpdate.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getAllUpdate.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    }
  }
})
const { reducer } = allUpdateSlice
const reset = allUpdateSlice.actions.reset
export { reset }
export default reducer
