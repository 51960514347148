import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import reportWebVitals from './reportWebVitals'
import './assets/css/tailwind.css'
import store from './store'
import React, { Suspense } from 'react'
import App from './App'
import './i18n'
import Spinner from './components/spinner/Spinner'

const root = ReactDOM.createRoot(document.getElementById('root'))
let persistor = persistStore(store)
root.render(
  <Router>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
