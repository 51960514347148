import { createAsyncThunk } from '@reduxjs/toolkit'

import { reset as resetDeviceConfig } from '../defaultDevicesConfigs/getOne'
import { reset as resetInformations } from '../informations/getAll'
import { reset as resetGroupDevice } from '../deviceGroups/getAll'
import { reset as resetSettings } from '../settings/getSetting'
import { reset as resetDevices } from '../devices/getAll'
import { reset as restDevice } from '../devices/getOne'
import { reset as resetGroups } from '../groups/getAll'
import { reset as resetLogs } from '../logs/globalLogs'
import { reset as resetUsers } from '../users/getAll'

export const resetStore = createAsyncThunk('reset/store', async (data, thunkAPI) => {
  await thunkAPI.dispatch(resetDevices())
  await thunkAPI.dispatch(resetLogs())
  await thunkAPI.dispatch(resetGroups())
  await thunkAPI.dispatch(restDevice())
  await thunkAPI.dispatch(resetSettings())
  await thunkAPI.dispatch(resetInformations())
  await thunkAPI.dispatch(resetGroupDevice())
  await thunkAPI.dispatch(resetDeviceConfig())
  return await thunkAPI.dispatch(resetUsers())
})
