import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PushNotification from './common/stateInfo/PushNotification'
import OutService from './components/errorPages/OutService'
import { getCurrentUser } from './store/currentUser/auth'
import SubSideBar from './components/sidebar/SubSidebar'
import Spinner from './components/spinner/Spinner'
import SideBar from './components/sidebar/SideBar'
import NavBar from './components/navbar/NavBar'
import { init } from './store/currentUser/init'
import PrivateRoute from './routes/PrivatRoute'
import { ThemeProvider } from './ThemeContext'
import PublicRoute from './routes/PublicRoute'
import Logo from './assets/img/logo.png'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.currentUser)
  const [showAnimation, setShowAnimation] = useState(false)
  const currentUser = useRef()
  const location = useLocation()
  const [alerts, setAlerts] = useState([])
  const [showSideBar, setShowSideBar] = useState(false)
  useEffect(() => {
    // did mount function
    if (localStorage.getItem('token') && !user.loading && !user.data.user_id) {
      dispatch(getCurrentUser())
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', localStorageUpdated)
      if (window.screen.width <= 1024) setShowSideBar(false)
    }
    return () => {
      // did unmount function
      if (typeof window !== 'undefined') {
        window.removeEventListener('storage', localStorageUpdated)
      }
    }
  }, [])
  useEffect(() => {
    if (currentUser.current && !user.loading && !user.error) {
      dispatch(init())
    }
    if (user.isSwitch && user.loading) {
      setShowAnimation(true)
      setTimeout(() => {
        setShowAnimation(false)
      }, 1800)
    }
    currentUser.current = user.loading
  }, [user])
  const localStorageUpdated = (event) => {
    // check token in storage
    if (event.key === 'token' && !user.loading) {
      const token = localStorage.getItem('token')
      if ((!token || typeof token !== 'string') && window.location.pathname !== '/login') {
        localStorage.removeItem('token')
        window.location.reload()
      } else if (token && typeof token === 'string' && window.location.pathname === '/login') {
        window.location.reload()
      }
    }
  }
  return (
    <ThemeProvider>
      {showAnimation && (
        <div id="oledcomm-animation" className="loading loading-visible">
          <div className="loading-logo">
            <img className="h-32 w-auto" src={Logo} alt="Workflow" />
          </div>
          <div className="loading-bg" />
        </div>
      )}
      {user.loading && !user.isSwitch ? (
        <div className="absolute w-full h-full z-50 ">
          <Spinner version={1} />
        </div>
      ) : user.data.user_id && !user.isSwitch ? (
        <div className="relative h-screen w-screen overflow-hidden ">
          <PushNotification />
          <div className="h-full w-full relative">
            <NavBar
              burgerToogle={[showSideBar, setShowSideBar]}
              alerts={alerts}
              onAlertChange={setAlerts}
            />
            <div className="pt-14 flex-1 h-full flex">
              <SideBar user={user} burgerToogle={[showSideBar, setShowSideBar]} />
              {location.pathname.includes('/settings') ? <SubSideBar /> : null}
              <main className="flex-1 h-full overflow-y-auto flex dark:bg-slate-900">
                <div className=" w-full">
                  <div className="flex flex-col h-full ">
                    <PrivateRoute user={user} alerts={alerts} onAlertChange={setAlerts} />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      ) : !localStorage.getItem('token') ? (
        <PublicRoute />
      ) : (
        <OutService />
      )}
    </ThemeProvider>
  )
}

export default App
