import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'

import { update, resetUpdate } from '../../store/groups/update'
import { create, resetCreate } from '../../store/groups/create'
import MultiSelect from '../../common/fields/MutliSelect'
import Success from '../../common/stateInfo/StateInfo'
import CheckBox from '../../common/fields/CheckBox'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'
import { ROLES } from '../../constant'
import GlobalModal from './Layout'

const GroupModal = ({ handleClose, row, users }) => {
  const { t } = useTranslation()
  const createGroup = useSelector((state) => state.createGroup)
  const updateGroup = useSelector((state) => state.updateGroup)
  const [alertType, setAlertType] = useState(null)
  const [error, setError] = useState({})
  const [name, setName] = useState(row ? row.name : '')
  const [roles, setRoles] = useState(row ? row.roles : [])
  const [userIds, setUserIds] = useState(row ? row.users : [])
  const [showAlert, setShowAlert] = useState(false)
  const choices = [
    { value: ROLES.DEVICE_MANAGER, label: 'Device Manager' },
    { value: ROLES.USER, label: 'User' }
  ]
  const dispatch = useDispatch()
  const prevCreateGroup = useRef()
  const prevUpdateGroup = useRef()
  useEffect(() => {
    if (prevCreateGroup.current && !createGroup.loading) {
      if (createGroup.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetCreate())
    }
    prevCreateGroup.current = createGroup.loading
  }, [createGroup])

  useEffect(() => {
    if (prevUpdateGroup.current && !updateGroup.loading && updateGroup.data) {
      if (updateGroup.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetUpdate())
    }
    prevUpdateGroup.current = updateGroup.loading
  }, [updateGroup])

  const handleSubmit = () => {
    const errorList = {}
    if (!name) {
      errorList.name = t('NAME_ERROR')
    }
    if (!roles.length) {
      errorList.roles = t('ROLE_ERROR')
    }
    if (!userIds.length) {
      errorList.users = t('GROUP_USER_ERROR')
    }
    if (!Object.keys(errorList).length) {
      if (row) {
        // update
        dispatch(update({ users: userIds, roles, name, id: row.id }))
      } else {
        dispatch(create({ users: userIds, roles, name }))
      }
    }
    setError(errorList)
  }

  return (
    <GlobalModal
      title={!row ? `${t('CREATE')} ${t('GROUP')}` : `${t('EDIT')} ${t('GROUP')}`}
      size="small"
      buttons={[
        !showAlert
          ? {
              title: t('CREATE'),
              action: () => handleSubmit()
            }
          : {},
        {
          type: 'secondary',
          title: t('CLOSE'),
          action: handleClose
        }
      ]}
    >
      {showAlert ? (
        <Success type={alertType} />
      ) : (
        <div className="relative p-6 flex-auto">
          <form className="w-full">
            <div className="mb-2 w-full">
              <Input
                label={t('GROUP_NAME')}
                value={name}
                onChange={setName}
                placeholder="name"
                error={error.name}
              />
            </div>
            <div className="mb-2 w-full">
              <CheckBox
                error={error.roles}
                choices={choices}
                label={t('GROUP_ROLE')}
                values={roles}
                onChange={setRoles}
                disposition="2"
              />
            </div>
            <div className="mb-2 w-full">
              <MultiSelect
                choices={users}
                values={userIds}
                onChange={setUserIds}
                error={error.users}
              />
            </div>
          </form>
        </div>
      )}
    </GlobalModal>
  )
}

export default GroupModal
