import useOnClickOutside from '../../utils/useOnClickOutside'
import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Avatar from 'react-avatar'

function Profile() {
  const user = useSelector((state) => state.currentUser.data)
  const loaction = useLocation()
  const loctionRef = useRef()
  const ref = useRef()
  const [showProfileMenu, setProfileMenu] = useState(false)
  useOnClickOutside(ref, () => setProfileMenu(false))
  useEffect(() => {
    if (showProfileMenu && loctionRef.current !== loaction.pathname) {
      setProfileMenu(false)
    }
    loctionRef.current = loaction.pathname
  }, [loaction])
  return (
    <div className="relative w-10">
      <NavLink
        to="/profil"
        className="dropdown-item text-sm block w-full whitespace-nowrap bg-transparent text-gray-700 "
      >
        <Avatar
          name={`${user.first_name} ${user.last_name}`}
          round
          size="36"
          className="h-6 cursor-pointer "
        />
      </NavLink>
    </div>
  )
}

export default Profile
