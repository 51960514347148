import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { capitalizeFirstLetter } from '../../../helpers/string'
import getMetrics from '../../../store/devices/metrics'
import VerifiedIcon from '@mui/icons-material/Verified'
import DownloadIcon from '@mui/icons-material/Download'
import { update } from '../../../store/devices/getOne'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'

const DeviceRow = ({ cols, row, onEdit, onDelete }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [metrics, setMetrics] = useState({ loading: true })

  const handleDetails = () => {
    dispatch(update(row))
    navigate(`/device/${row.device_hardware_id}`)
  }
  const fetchMetrics = async (id) => {
    const res = await getMetrics(id)
    setMetrics(res)
  }
  useEffect(() => {
    fetchMetrics(row.device_uuid)
  }, [row])
  return (
    <tr onClick={handleDetails} className="border-b hover:bg-gray-100 cursor-pointer">
      <td className="whitespace-nowrap text-sm font-medium p-4">
        <input
          aria-label="select device"
          id={row.device_hardware_id}
          type="checkbox"
          className="w-4 h-4 "
        />
      </td>
      {cols.map((col, index) => {
        if (col.key) {
          return (
            <td
              key={index}
              className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap p-4"
            >
              <p>{row[col.key]?.toString() || 'N/A'}</p>
            </td>
          )
        } else {
          if (col.label === 'Tag') {
            if (
              row.informations &&
              row.informations[0] &&
              row.informations[0].tags &&
              row.informations[0].tags.length
            ) {
              const tagColor = row.informations[0].tags[0].color
              return (
                <td
                  key={index}
                  className="text-sm font-light text-ellipsis whitespace-nowrap overflow-hidden p-4"
                >
                  <div className="whitespace-nowrap text-sm text-secondary flex items-center">
                    <div className="flex items-center space-x-1 border border-gray-200 p-1 rounded-lg">
                      <div className={`p-2 ${tagColor} rounded-full`} />
                      <span className="text-sm text-center flex justify-center ">
                        {capitalizeFirstLetter(row.informations[0].tags[0].name)}
                      </span>
                    </div>

                    {row.informations[0].tags.length > 1 && (
                      <div className="px-2 text-secondary group">
                        <button
                          className="rounded-lg border  text-sm"
                          data-tip=""
                          data-for={`info-${row.device_hardware_id}`}
                          data-class="react-component-tooltip"
                        >
                          <span className="p-1">+{row.informations[0].tags.length - 1}</span>
                        </button>
                        <ReactTooltip id={`info-${row.device_hardware_id}`}>
                          <ul>
                            {row.informations[0].tags.map((el, index) => {
                              if (index !== 0) {
                                return (
                                  <li key={index} className="text-sm text-secondary">
                                    {el.name}
                                  </li>
                                )
                              }
                            })}
                          </ul>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                </td>
              )
            } else {
              return (
                <td
                  key={index}
                  className="text-sm font-light text-ellipsis  whitespace-nowrap overflow-hidden p-4"
                >
                  <span>N/A</span>
                </td>
              )
            }
          } else if (col.label === 'Status') {
            return (
              <td key={index} className="text-sm font-light whitespace-nowrap p-4">
                {metrics.loading ? (
                  <div className="flex space-x-2">
                    <div className="animate-pulse w-20 h-4 bg-gray-200 rounded-lg" />
                    <div className="animate-pulse w-4 h-4 bg-gray-200 rounded-lg" />
                  </div>
                ) : Object.keys(metrics).length == 0 ? (
                  <div
                    title="Modem non accessible ou hors tension"
                    className="flex space-x-2 items-center w-20"
                  >
                    <h3 className="text-slate-500 dark:text-white">{t('OFFLINE')}</h3>
                    <VerifiedIcon className="text-gray-400" fontSize="small" />
                  </div>
                ) : metrics.tx_mbps > 0 || metrics.rx_mbps > 0 ? (
                  <div
                    title="Modem connecté au réseau et en LiFi"
                    className="flex space-x-2 items-center w-20"
                  >
                    <h3 className="text-slate-500 dark:text-white">{t('CONNECTED')}</h3>
                    <VerifiedIcon className="text-green-600" fontSize="small" />
                  </div>
                ) : (
                  <div
                    title="Modem connecté au réseau mais non connecté en LiFi"
                    className="flex space-x-2 items-center  w-20"
                  >
                    <h3 className="text-slate-500">{t('IDDLE')}</h3>
                    <VerifiedIcon className="text-orange-400" fontSize="small" />
                  </div>
                )}
              </td>
            )
          } else if (col.label === 'Alias') {
            return (
              <td
                key={index}
                className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap p-4"
              >
                {row?.informations[0]?.alias || 'N/A'}
              </td>
            )
          } else if (col.label === 'Type') {
            return (
              <td
                key={index}
                className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap"
              >
                {row?.properties?.device_type || 'N/A'}
              </td>
            )
          } else if (col.label === 'Up') {
            return (
              <td
                key={index}
                className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap w-24 p-4"
              >
                <div className="flex items-center">
                  {metrics.loading ? (
                    <div className="animate-pulse w-20 h-4 bg-gray-200 rounded-lg" />
                  ) : (
                    <div>
                      {metrics && metrics.tx_mbps ? metrics.tx_mbps : '0 '} Mbps
                      <UploadIcon className="text-green-600 ml-1" fontSize="small" />
                    </div>
                  )}
                </div>
              </td>
            )
          } else if (col.label === 'Down') {
            return (
              <td
                key={index}
                className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap w-24 p-4"
              >
                <div className="flex items-center">
                  {metrics.loading ? (
                    <div className="animate-pulse w-20 h-4 bg-gray-200 rounded-lg" />
                  ) : (
                    <div>
                      {metrics && metrics.rx_mbps ? metrics.rx_mbps : '0 '} Mbps
                      <DownloadIcon className="text-purple-600 ml-1" fontSize="small" />
                    </div>
                  )}
                </div>
              </td>
            )
          } else if (col.label === 'Host_name') {
            return (
              <td
                key={index}
                className="text-sm font-light text-ellipsis overflow-hidden whitespace-nowrap p-4"
              >
                {row?.informations[0]?.configs?.network?.hostname || row?.hostname || 'N/A'}
              </td>
            )
          }
        }
      })}
      <td onClick={(e) => e.stopPropagation()} className="text-sm font-light whitespace-nowrap p-4">
        <div className="flex">
          {
            <button
              aria-label="delete device"
              className="rounded-full flex items-center p-2 text-gray-700 hover:bg-red-100 hover:text-red-500"
              onClick={() => {
                onDelete(row.device_hardware_id, row.hostname, 'device')
              }}
            >
              <DeleteIcon />
            </button>
          }
          {row.to_be_validated && (
            <button
              onClick={() => onEdit(row)}
              className="rounded-full flex items-center p-2 text-gray-700 hover:bg-gray-200 hover:text-orange-500 "
            >
              <div className="relative">
                <ErrorOutlineIcon />
                <div className="absolute top-0 right-0 -mr-1 -mt-1 w-3 h-3 rounded-full bg-orange-500 animate-ping" />
                <div className="absolute top-0 right-0 -mr-1 -mt-1 w-3 h-3 rounded-full bg-orange-500" />
              </div>
            </button>
          )}
        </div>
      </td>
    </tr>
  )
}

export default DeviceRow
