import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getAllGroups } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const create = createAsyncThunk('groups/create', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/create`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllGroups())
  return res.data
})

export const resetCreate = createAsyncThunk('groups/reset', async () => {
  return true
})

const groupSlice = createSlice({
  name: 'createGroups',
  initialState,
  extraReducers: {
    [create.pending]: (state) => {
      return { ...state, loading: true }
    },
    [create.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [create.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetCreate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = groupSlice
export default reducer
