import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  loading: false,
  data: {
    distribution: {},
    module: {}
  },
  error: null
}

export const getOneDistribution = createAsyncThunk('distribution/getOne', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOneDistribution/${id}`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})
export const getOneModule = createAsyncThunk('module/getOne', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOneModule/${id}`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})
export const distributionSlice = createSlice({
  name: 'distribution',
  initialState,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        data: {
          distribution: action.payload,
          module: {}
        }
      }
    },
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getOneDistribution.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getOneDistribution.fulfilled]: (state, action) => {
      return {
        error: null,
        data: {
          distribution: action.payload.distribution || {},
          module: action.payload.module || {}
        },
        loading: false
      }
    },
    [getOneDistribution.rejected]: (state, action) => {
      return { data: { distribution: {}, module: {} }, error: action.payload, loading: false }
    },
    [getOneModule.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getOneModule.fulfilled]: (state, action) => {
      return { error: null, data: { ...state.data, module: action.payload }, loading: false }
    },
    [getOneModule.rejected]: (state, action) => {
      return { data: { distribution: {}, module: {} }, error: action.payload, loading: false }
    }
  }
})

const { reducer } = distributionSlice
const { update, reset } = distributionSlice.actions
export { update, reset }
export default reducer
