import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllDeviceGroups } from './getAll'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const deleteDeviceGroup = createAsyncThunk('group/delete', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/delete/${id}`, 'DELETE')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllDeviceGroups())
  return res.data
})

export const resetDelete = createAsyncThunk('deviceGroup/reset', async () => {
  return true
})

const deleteDeviceGroupSlice = createSlice({
  name: 'deleteGroup',
  initialState,
  extraReducers: {
    [deleteDeviceGroup.pending]: (state) => {
      return { ...state, loading: true }
    },
    [deleteDeviceGroup.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [deleteDeviceGroup.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetDelete.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = deleteDeviceGroupSlice
export default reducer
