import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { capitalizeFirstLetter } from '../../helpers/string'
import { useEffect, useState, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { reset } from '../../store/requestInfo/requestInfo'

const PushNotification = () => {
  const dispatch = useDispatch()
  const requestInfo = useSelector((state) => state.requestInfo)
  const [requestInfoList, setRequestInfoList] = useState([])
  const timersRef = useRef({})
  const { t } = useTranslation()

  useEffect(() => {
    if (requestInfo && requestInfo.type) {
      const newRequestInfo = { ...requestInfo, id: uuidv4() }
      setRequestInfoList((prevList) => {
        const newList = [newRequestInfo, ...prevList]
        timersRef.current[newRequestInfo.id] = setTimeout(() => {
          handleClose(newRequestInfo.id)
        }, 5000)

        return newList
      })
      dispatch(reset())
    }
  }, [requestInfo])

  useEffect(() => {
    return () => {
      Object.values(timersRef.current).forEach(clearTimeout)
    }
  }, [])

  const handleClose = (id) => {
    setRequestInfoList((prevList) => prevList.filter((item) => item.id !== id))
    clearTimeout(timersRef.current[id])
    delete timersRef.current[id]
  }

  return (
    <ul className="fixed right-0 z-50 space-y-1 p-2" id="pushNotification">
      {requestInfoList.map((info) => (
        <li
          key={info.id}
          className="rounded-lg border border-gray-200 bg-white animate-slide-in-right shadow-md w-80 h-fit flex"
        >
          <div
            className={`${
              info.type === 'success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
            }  rounded-l-lg px-1.5 flex justify-center  items-center`}
          >
            {info.type === 'success' ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}
          </div>
          <div className="p-1 pl-3 flex-1">
            <p className="font-semibold">{t(info.type.toUpperCase())}</p>
            <div className="flex items-center text-sm text-gray-500">
              <p>{t(info.message)}</p>
            </div>
          </div>
          <div className="flex items-center">
            <button className="text-gray-500 p-2" onClick={() => handleClose(info.id)}>
              <CloseIcon />
            </button>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default PushNotification
