// Use CommonJS require below so we can dynamically import during build-time.
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import logger from 'redux-logger'

import uploadManagerCertificateReducer from './certificate/uploadManagerCertificate.js'
import uploadCertificateReducer from './certificate/uploadCertificate.js'
import updateDefaultDeviceConfig from './defaultDevicesConfigs/update'
import defaultDevicesConfigs from './defaultDevicesConfigs/getOne'
import changePasswordReducer from './currentUser/changePassword'
import createDeviceGroupReducer from './deviceGroups/create'
import deleteDeviceGroupReducer from './deviceGroups/delete'
import forgetPassword from './currentUser/forgetPassword.js'
import updateDeviceGroupReducer from './deviceGroups/update'
import acceptDeviceReducer from './devices/acceptDevice.js'
import toBeValidatedReducer from './devices/toBeValidated'
import downloadCsrReducer from './certificate/downloadCsr'
import updateUserReducer from './currentUser/updateUser'
import updateDistribution from './distributions/update'
import informationsReducer from './informations/getAll'
import deviceGroupReducer from './deviceGroups/getAll'
import createCompanyReducer from './compagnies/create'
import certificatesReducer from './certificate/getAll'
import softwareTypes from './distributions/getTypes'
import createFirmware from './distributions/create'
import requestInfo from './requestInfo/requestInfo'
import deleteFirmware from './distributions/delete'
import countLogsReducer from './logs/countLogs.js'
import companiesReducer from './compagnies/getAll'
import distributions from './distributions/getAll'
import distribution from './distributions/getOne'
import dashboardReducer from './currentUser/init'
import createGroupReducer from './groups/create'
import updateSettings from './settings/update'
import devicesReducer from './devices/getAll'
import settings from './settings/getSetting'
import deviceReducer from './devices/getOne'
import userReducer from './currentUser/auth'
import changeTagReducer from './tags/change'
import createTagReducer from './tags/create'
import deleteTagReducer from './tags/delete'
import deleteUpdate from './updates/delete'
import groupsReducer from './groups/getAll'
import createUpdate from './updates/create'
import createWidget from './widgets/create'
import deleteWidget from './widgets/delete'
import deviceLogs from './logs/deviceLogs'
import usersReducer from './users/getAll'
import updateWidget from './widgets/edit'
import updateGroup from './groups/update'
import oneWidget from './widgets/getOne'
import linkReducer from './devices/link'
import tagsReducer from './tags/getAll'
import updateUser from './users/update'
import createUser from './users/create'
import appLogs from './logs/globalLogs'
import widgets from './widgets/getAll'
import updates from './updates/getAll'
import ManagerCertificat from './certificate/getCertificatInfoManager.js'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}
const rootReducer = combineReducers({
  uploadManagerCertificate: uploadManagerCertificateReducer,
  createDeviceGroup: createDeviceGroupReducer,
  uploadCertificate: uploadCertificateReducer,
  deleteDeviceGroup: deleteDeviceGroupReducer,
  updateDeviceGroup: updateDeviceGroupReducer,
  getAllCertificates: certificatesReducer,
  updatePassword: changePasswordReducer,
  updateCurrentUser: updateUserReducer,
  toBeValidated: toBeValidatedReducer,
  createCompany: createCompanyReducer,
  acceptDevice: acceptDeviceReducer,
  informations: informationsReducer,
  getAllCompanies: companiesReducer,
  createGroup: createGroupReducer,
  downloadCsr: downloadCsrReducer,
  deviceGroup: deviceGroupReducer,
  getAllDevice: devicesReducer,
  countLogs: countLogsReducer,
  changeTag: changeTagReducer,
  createTag: createTagReducer,
  dashboard: dashboardReducer,
  deleteTag: deleteTagReducer,
  updateDefaultDeviceConfig,
  currentUser: userReducer,
  linkDevice: linkReducer,
  defaultDevicesConfigs,
  groups: groupsReducer,
  device: deviceReducer,
  users: usersReducer,
  updateDistribution,
  tags: tagsReducer,
  deleteFirmware,
  forgetPassword,
  createFirmware,
  updateSettings,
  softwareTypes,
  deleteUpdate,
  createWidget,
  updateWidget,
  deleteWidget,
  createUpdate,
  distributions,
  distribution,
  updateGroup,
  requestInfo,
  updateUser,
  createUser,
  oneWidget,
  deviceLogs,
  settings,
  ManagerCertificat,
  widgets,
  updates,
  appLogs
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV === 'development'
      ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
      : getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.REACT_APP_ENV === 'development'
})
export default store
