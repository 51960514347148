import { NavLink } from 'react-router-dom'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatDateTime } from '../../helpers/date'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const AlertItem = ({ data, onDelete, onResolve }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showConfirmation, setConfirmation] = useState(false)
  const { t } = useTranslation()
  const resolved = !!data.resolved_at
  return (
    <div
      className={`relative rounded-md p-2 border  shadow-sm text-xs hover:shadow-xl ${
        resolved ? 'bg-gray-100 dark:bg-slate-700' : 'bg-white dark:bg-slate-800'
      }`}
    >
      <div
        onClick={() => setShowDetails((prev) => !prev)}
        className="flex text-sm w-full space-x-2 items-center"
      >
        <AlertIcon type={data.alert_type} />
        <div className="flex flex-col w-full">
          <div className="flex space-x-1">
            <NavLink
              className={
                'text-blue-600 dark:text-blue-400 dark:hover:text-blue-600 hover:text-blue-400 underline'
              }
              to={`/device/${data.hardwares.device_hardware_id}`}
            >
              {data.hardwares.hostname}
            </NavLink>
            {data.alert_type === 'alert_400' && <p>{t('ALERT_400')}</p>}
            {data.alert_type === 'alert_100' && <p>{t('ALERT_100')}</p>}
            {data.alert_type === 'alert_200' && (
              <p>
                {t('ALERT_200', {
                  trigger: data.alert_body.alert_threshold,
                  value: Math.trunc(data.alert_body.current_value)
                })}
              </p>
            )}
            {data.alert_type === 'alert_300' && (
              <p>
                {t('ALERT_300', {
                  trigger: data.alert_body.alert_threshold,
                  value: Math.trunc(data.alert_body.current_value)
                })}
              </p>
            )}
          </div>
          <div className="flex justify-end text-xs text-gray-400 w-full">
            <p>{formatDateTime(data.created_at)}</p>
          </div>
        </div>
      </div>
      {showDetails && (
        <div className="w-full flex justify-between mt-2 items-center">
          <div>
            {t('STATUS')} :{' '}
            {resolved
              ? `${t('RESOLVED_BY')} ${data.resolved_by}, ${formatDateTime(data.updated_at)} `
              : `${t('NOT_RESOLVED')}`}
          </div>
          <div className="flex space-x-1">
            {!resolved && (
              <button
                onClick={() => onResolve(data.device_alert_id)}
                title="Resolved"
                className={`rounded-md flex justify-center h-6 p-1 border hover:bg-green-500 hover:text-white`}
              >
                <CheckIcon fontSize={'inherit'} />
              </button>
            )}
            <button
              onClick={() => setConfirmation(true)}
              title={resolved ? 'Delete' : 'You must resolved before deleting it'}
              disabled={!resolved}
              className={`${
                !resolved ? 'opacity-60 cursor-not-allowed' : ''
              } rounded-md flex justify-center h-6 p-1 border  hover:bg-red-500 hover:text-white`}
            >
              <DeleteIcon fontSize={'inherit'} />
            </button>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="w-full h-full absolute z-10 bg-blue-500 inset-0 rounded-md opacity-90 flex flex-col justify-center items-center space-y-2">
          <div className="text-md text-white font-bold">{t('DELETE_ALERT_CONFIRM')}</div>
          <div className="flex space-x-2">
            <button
              onClick={() => setConfirmation(false)}
              className="bg-transparent hover:bg-green-500 text-white font-semibold hover:text-white py-0.5 px-1 border border-white hover:border-transparent rounded"
            >
              {t('NO')}
            </button>
            <button
              onClick={() => onDelete(data.device_alert_id)}
              className="bg-transparent hover:bg-red-500 text-white font-semibold hover:text-white py-0.5 px-1 border border-white hover:border-transparent rounded shadow-xl"
            >
              {t('YES')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AlertItem

const AlertIcon = ({ type }) => {
  if (type === 'alert_200' || type === 'alert_300') {
    return (
      <div className="flex rounded-full bg-orange-50 p-1 text-orange-500">
        <WarningAmberIcon />
      </div>
    )
  }
  return (
    <div className="flex rounded-full bg-red-50 dark:bg-red-900/50 p-1 text-red-500 ">
      <ErrorOutlineIcon />
    </div>
  )
}
