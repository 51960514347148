exports.LogTags = [
  { label: 'Authentification', key: 'auth' },
  { label: 'User', key: 'user' },
  { label: 'Group', key: 'group' },
  { label: 'Organization', key: 'organization' },
  { label: 'Device', key: 'device' },
  { label: 'Database', key: 'db' },
  { label: 'Tag', key: 'tag' },
  { label: 'Information', key: 'info' },
  { label: 'Hardware', key: 'hardware' },
  { label: 'Configuration', key: 'config' },
  { label: 'Setting', key: 'setting' },
  { label: 'Widget', key: 'widget' }
]

exports.LogLevel = [
  { label: 'Info', key: 'info' },
  { label: 'Warning', key: 'warning' },
  { label: 'Error', key: 'error' },
  { label: 'Emerg', key: 'emerg' }
]

exports.ROLES = {
  DEVICE_MANAGER: 'device_manager',
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  USER: 'user'
}

exports.Constant = {
  1001: '1001'
}

exports.AlERT_MSG = {
  alert_200: 'has failed to restart system test',
  alert_1200: 'at less than [VALUE] Mb/s'
}

exports.RATP_STATION = [
  'Versailles-Chantiers',
  'Satory',
  'Saint-Quentin Est',
  'CEA Saint-Aubin',
  'Orsay-Gif',
  'Palaiseau',
  'Massy-Palaiseau',
  'Massy-Opera',
  'Antonypole',
  `Aéroport d'Orly`
]

exports.DEVICE_STATUS = {
  status_200: 'Connected',
  status_500: 'Failed',
  status_404: 'Not Connected'
}
