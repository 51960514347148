import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getAllUpdate } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const deleteDeviceUpdate = createAsyncThunk('update/deleteRollout', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/deleteRollout/${id}`, 'DELETE')
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getAllUpdate({ limit: 10, offset: 0 }))
  return res.data
})

export const resetDelete = createAsyncThunk('update/delete/reset', async () => {
  return true
})

const deleteDeviceUpdateSlice = createSlice({
  name: 'deleteUpdate',
  initialState,
  extraReducers: {
    [deleteDeviceUpdate.pending]: (state) => {
      return { ...state, loading: true }
    },
    [deleteDeviceUpdate.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [deleteDeviceUpdate.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetDelete.fulfilled]: () => {
      return initialState
    }
  }
})
const { reducer } = deleteDeviceUpdateSlice
export default reducer
