import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/setting`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const getOne = createAsyncThunk('setting/getOne', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOne`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getOne.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getOne.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [getOne.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = settingSlice
const reset = settingSlice.actions.reset
export { reset }
export default reducer
