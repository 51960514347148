import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { resetPassword, reset } from '../store/currentUser/forgetPassword'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import Logo from '../assets/img/logo2.png'

const ResetPAssword = () => {
  const { token } = useParams()
  const location = useLocation()
  const isForgetPassword = location.pathname.includes('/resetPassword/')
  const forgetPassword = useSelector((state) => state.forgetPassword)
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const handleSubmit = () => {
    if (!password || !confirmPassword) {
      setError('You must fill all the fields')
    } else if (password !== confirmPassword) {
      setError('Password and confirm password not matched')
    } else if (password.length < 8 || password.length > 30) {
      setError('Password must be 8-30 characters')
    } else {
      setError('')
      dispatch(resetPassword({ newPassword: password, token }))
    }
  }
  useEffect(() => {
    dispatch(reset())
  }, [])
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {forgetPassword.loading && (
        <div className="absolute w-full  h-screen z-50 ">
          <Spinner version={1} />
        </div>
      )}
      <div className="max-w-md w-full space-y-8 border p-8 rounded-md bg-white shadow-md">
        <div>
          <img className="mx-auto h-32 w-auto" src={Logo} alt="Workflow" />
        </div>
        {(error || forgetPassword.error) && (
          <StateInfo description={error || forgetPassword.error} type="error" />
        )}
        {forgetPassword.data.message ? (
          <StateInfo
            title={forgetPassword.data.message}
            type="success"
            description={'Your password has been updated.'}
          />
        ) : (
          <>
            <h4 className="mt-2 text-start text-md font-extrabold text-gray-900">
              {isForgetPassword ? 'Reset password' : 'Create your password'}
            </h4>
            <div>
              <div className="rounded-md shadow-sm space-y-6">
                <div>
                  <div className="mt-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Password
                    </label>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Password"
                    />
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700  mb-2"
                    >
                      Confirmed Password
                    </label>
                    <input
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      id="Confirmed password"
                      name="Confirmed password"
                      type="password"
                      autoComplete="Confirmed-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Confirmed Password"
                    />
                  </div>

                  <div>
                    <button
                      onClick={() => handleSubmit()}
                      className="mt-6 shadow-md group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-400 focus:outline-none"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3" />
                      {isForgetPassword ? 'Reset Password' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ResetPAssword
