import { bindActionCreators } from 'redux'
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'

import Spinner from '../components/spinner/Spinner'
import { getCurrentUser, signin } from '../store/currentUser/auth'

import Logo from '../assets/img/logo2.png'
import '../assets/css/loginStyle.css'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import SetLanguages from '../components/languages/SetLanguages'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)
  const [validUsername, setValidUsername] = useState(true)
  const [rememberMe, setRememberMe] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const { t } = useTranslation()
  const user = useSelector((state) => state.currentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    // did mount function
    if (localStorage.getItem('credentials') !== null) {
      const user1 = JSON.parse(localStorage.getItem('credentials'))
      if (user1 && user1?.username && user1?.password) {
        const bytes = CryptoJS.AES.decrypt(
          user1?.password,
          process.env.REACT_APP_HASH_SALT.replaceAll('/', '$')
        )
        setUsername(user1.username)
        setRememberMe(true)
        setPassword(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)))
      }
      localStorage.removeItem('credentials')
    }
  }, [])

  useEffect(() => {
    const handleUserKeyPressEntry = (event) => {
      const { key } = event
      if (key === 'Enter') {
        submitLogin()
      }
    }
    window.addEventListener('keydown', handleUserKeyPressEntry)
    return () => window.removeEventListener('keydown', handleUserKeyPressEntry)
  }, [username, password])

  const submitLogin = async () => {
    let validForm = true
    if (!validPasswordFunc()) {
      validForm = false
    }
    if (!validUsernameFunc()) {
      validForm = false
    }

    if (validForm) {
      if (rememberMe) {
        localStorage.setItem(
          'credentials',
          JSON.stringify({
            username: username,
            password: CryptoJS.AES.encrypt(
              JSON.stringify(password),
              process.env.REACT_APP_HASH_SALT.replaceAll('/', '$')
            ).toString()
          })
        )
      }
      dispatch(signin({ email: username, password: password }))
    }
  }
  const validPasswordFunc = () => {
    if (password.length >= 4) {
      setValidPassword(true)
      return true
    } else {
      setValidPassword(false)
      return false
    }
  }
  const validUsernameFunc = () => {
    if (username.length > 4) {
      setValidUsername(true)
      return true
    } else {
      setValidUsername(false)
      return false
    }
  }

  return (
    <>
      {user.loading && (
        <div className="absolute w-full  h-screen z-50 ">
          <Spinner version={1} />
        </div>
      )}

      <div className="h-screen">
        <div className="flex h-full items-center">
          <div className="lg:w-1/2 xl:w-2/5 2xl:px-20 xl:px-8 px-1  w-full">
            <div className="abolute top-0 w-10">
              <SetLanguages position={'absolute'} />
            </div>
            <div className="flex justify-center -mt-4">
              <img
                className="h-36 w-auto"
                src={Logo}
                alt="Oledcomm Logo"
                width="9rem"
                height="9rem"
              />
            </div>

            <div className="flex w-full text-3xl justify-center">
              <p style={{ fontFamily: 'proxima-nova, sans-serif' }}>LiFi Manager</p>
            </div>
            <div className="w-32 h-2 m-auto font-extrabold text-transparent bg-gradient-to-r from-red-400 via-purple-600 to-cyan-500" />
            {user.error && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
                role="alert"
              >
                <span className="block sm:inline">{user.error}</span>
              </div>
            )}
            <div className="mt-8 mx-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                      {t('USER_NAME')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        value={username}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        onChange={(e) => {
                          setUsername(e.target.value)
                          validUsernameFunc()
                        }}
                      />
                      {!validUsername && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {!username ? t('USERNAME_REQUIRED') : t('INVALID_USERNAME')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      {t('PASSWORD')}
                    </label>
                    <div className="mt-1">
                      <div className="relative block">
                        <input
                          id="password"
                          name="password"
                          type={passwordVisible ? 'text' : 'password'}
                          value={password}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          onChange={(e) => {
                            setPassword(e.target.value)
                            validPasswordFunc()
                          }}
                        />
                        <span
                          onClick={() => {
                            setPasswordVisible(!passwordVisible)
                          }}
                          className="absolute inset-y-0 right-0 flex items-center mr-3"
                        >
                          {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                      </div>
                      {!validPassword && (
                        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                          {!password ? t('PASSWORD_EMPTY') : t('INVALID_PASSWORD')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        onChange={() => setRememberMe(!rememberMe)}
                        checked={rememberMe}
                        id="remember_me"
                        name="remember_me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                        {t('REMEMBER_ME')}
                      </label>
                    </div>
                    <div className="text-sm text-blue-600 hover:text-blue-400">
                      <a href="/forgetPassword" className="font-medium ">
                        {t('FORGET_PASSWORD')}
                      </a>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => submitLogin()}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {t('LOGIN')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-3/5 lg:w-1/2 h-full hidden lg:block">
            <div className="h-full w-full bg-gradient-to-tl from-cyan-500 via-purple-600 to-red-400">
              <div className="area">
                <ul className="circles">
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login
