import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { useTranslation } from 'react-i18next'
import DnsIcon from '@mui/icons-material/Dns'
import NavLink from './NavLink'

const SubSideBar = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col relative h-screen bg-gray-500/10 dark:bg-slate-700/90 border-r border-gray-200 dark:border-slate-700">
      <div className=" h-full flex flex-col flex-grow  pt-5 pb-4 overflow-y-auto">
        <nav className=" flex-1 flex flex-col divide-y overflow-y-auto" aria-label="Sidebar">
          <div className="space-y-4 lg:w-24 w-15">
            <NavLink
              path="/settings/admin"
              name="Admin"
              label={t('ADMIN')}
              icon={<AdminPanelSettingsIcon className="mx-4" />}
            />
            {/*    <NavLink
              path="/settings/device"
              name="Device"
              label={t('DEVICES')}
              icon={<ArchitectureIcon className="mx-4" />}
            />
  */}
            <NavLink
              path="/settings/server"
              name="Server"
              label={t('SERVER')}
              icon={<DnsIcon className="mx-4" />}
            />
            <NavLink
              path="/settings/updates"
              name="Updates"
              label={t('UPDATES')}
              icon={<QueryBuilderIcon className="mx-4" />}
            />
          </div>
        </nav>
      </div>
    </div>
  )
}
export default SubSideBar
