import { useState, useEffect } from 'react'

const CheckBox = ({ choices, disposition, label, onChange, values, error }) => {
  const [val, setVal] = useState(new Map())
  useEffect(() => {
    const items = [...val.entries()].filter(({ 1: v }) => v === true).map(([k]) => k)
    onChange(items)
  }, [val])
  useEffect(() => {
    if (values && values.length) {
      const items = new Map()
      values.map((el) => {
        items.set(el, true)
      })
      setVal(items)
    }
  }, [])
  const handleChange = (item, isChecked) => {
    setVal((map) => new Map(map.set(item, !isChecked)))
  }

  return (
    <fieldset className="mt-6">
      <div className="inline-flex text-center">
        <label
          htmlFor="about"
          className="block lg:text-sm text-lg leading-5 font-medium text-primary"
        >
          {label}
        </label>
      </div>
      <div className={disposition === '2' ? 'flex' : ''}>
        {choices.map((choice, num) => {
          return (
            <div
              key={num}
              onClick={() => handleChange(choice.value, val.get(choice.value) || false)}
              className="mt-4"
            >
              <div className={`flex items-start ${disposition === '2' ? 'mr-2' : ''}`}>
                <div className="flex items-center h-5">
                  <input
                    key={`checkbox-${num}`}
                    checked={val.get(choice.value)}
                    value={choice.value}
                    name={choice.label}
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </div>
                <div className="ml-3 text-sm leading-5">
                  <label
                    htmlFor="candidates"
                    className="block text-sm leading-5 font-medium text-primary"
                  >
                    {choice.label}
                  </label>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {error && <span className="w-full text-sm text-red-500">{error}</span>}
    </fieldset>
  )
}
export default CheckBox
