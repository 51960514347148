import FilterInput from '../../../../common/fields/Filter'
import CachedIcon from '@mui/icons-material/Cached'
import { useState } from 'react'

const Filter = ({ isLoading, onSearch, onRefresh, onAdd, onSetting, value, rows }) => {
  const [levels, setLevels] = useState(0)
  const [values, setValues] = useState([])

  const handleSearch = () => {
    const query = values.map((el) => el.value)
    onSearch(query)
  }
  const handleChange = (items) => {
    setValues(items)
    setLevels(items.length)
  }
  const getItems = () => {
    let list = []
    if (!levels && rows[levels]) {
      list = rows[levels]
    } else if (
      levels &&
      values[levels - 1] &&
      rows[levels] &&
      rows[levels][values[levels - 1].value]
    ) {
      list = rows[levels][values[levels - 1].value]
    } else {
      return list
    }
    const items = list.map((el) => ({
      label: el.name,
      value: el.device_tag_id,
      color: el.color
    }))

    return items
  }
  return (
    <div className="w-1/2 flex items-center px-2">
      <div className={`w-full flex ${!onSearch ? 'justify-end' : ''}`}>
        {onSearch && (
          <FilterInput
            value={values}
            rows={getItems()}
            onChange={handleChange}
            onSearch={handleSearch}
            isLoading={isLoading}
          />
        )}

        <div className="relative flex space-x-2 ml-2">
          {onAdd && (
            <button
              aria-label="refresh devices"
              onClick={onAdd}
              className="p-2 px-3 border rounded-lg flex items-center justify-center shadow-sm text-gray-500 bg-white hover:bg-blue-400 hover:text-white"
            >
              +
            </button>
          )}
          <button
            aria-label="refresh devices"
            onClick={onRefresh}
            className="p-2 px-3 border rounded-lg flex items-center justify-center shadow-sm text-gray-500 bg-white"
          >
            <CachedIcon fontSize="inherit" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Filter
