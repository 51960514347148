import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import Switch from '../../common/switch/Switch'
import Input from '../../common/fields/Input'
import { useTranslation } from 'react-i18next'

const DeviceAlerting = ({ data, handleChange, error, changes }) => {
  const { t } = useTranslation()
  return (
    <div className="pl-2 pt-2 dark:text-gray-200">
      <div>
        <h4>{t('DEBIT_ALERT')} :</h4>
        <div className=" grid grid-col-1 gap-4 p-5">
          <div className="">
            <Switch
              value={data?.bond0_bytes_threshold}
              onChange={(val) => handleChange('configs.alerting.bond0_bytes_threshold', val)}
              title={` ${t('TOGGLE_DEBIT_ALERT')} ${changes['configs.alerting'] ? '●' : ''}`} // for now
              description={t('TOGGLE_DEBIT_ALERT_DESCRIPTION')}
            />
          </div>
          <div className="">
            <div className="grid grid-cols-5 gap-2">
              <div className="col-span-3 pb-6">
                <label className="block text-sm font-medium dark:text-white">
                  {t('SELECT_UP_DEBIT_RANGE')}
                </label>
                <input
                  onChange={(e) => {
                    handleChange('configs.alerting.bond0_tx_bytes_threshold', e.target.value)
                  }}
                  max="1000"
                  type="range"
                  value={
                    data?.bond0_tx_bytes_threshold == 0
                      ? 0
                      : data?.bond0_tx_bytes_threshold > 0
                      ? data?.bond0_tx_bytes_threshold
                      : 500
                  }
                  className="mr-5 rounded-lg w-full "
                  disabled={!data?.bond0_bytes_threshold}
                />
              </div>
              <div className="col-span-1">
                <Input
                  value={
                    data?.bond0_tx_bytes_threshold == 0
                      ? 0
                      : data?.bond0_tx_bytes_threshold > 0
                      ? data?.bond0_tx_bytes_threshold
                      : 500
                  }
                  readOnly={!data?.bond0_bytes_threshold}
                  placeholder="0"
                  onChange={(val) => {
                    val > 1000 && (val = 1000)
                    val <= 0 && (val = 0)
                    !val && (val = 0)
                    handleChange('configs.alerting.bond0_tx_bytes_threshold', `${val}`)
                  }}
                />
              </div>
              <div className="ml-2 mt-3 text-md">
                Mb/s
                <FileUploadIcon className="text-green-400" fontSize="inherit" />
              </div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="col-span-3 pb-6">
                <label className="block text-sm font-medium dark:text-white">
                  {t('SELECT_DOWN_DEBIT_RANGE')}
                </label>
                <input
                  onChange={(e) =>
                    handleChange('configs.alerting.bond0_rx_bytes_threshold', e.target.value)
                  }
                  max="1000"
                  type="range"
                  value={
                    data?.bond0_rx_bytes_threshold == 0
                      ? 0
                      : data?.bond0_rx_bytes_threshold > 0
                      ? data?.bond0_rx_bytes_threshold
                      : 500
                  }
                  className="mr-5 rounded-lg w-full "
                  disabled={!data?.bond0_bytes_threshold}
                />
              </div>
              <div className="col-span-1">
                <Input
                  value={
                    data?.bond0_rx_bytes_threshold == 0
                      ? 0
                      : data?.bond0_rx_bytes_threshold > 0
                      ? data?.bond0_rx_bytes_threshold
                      : 500
                  }
                  placeholder="0"
                  readOnly={!data?.bond0_bytes_threshold}
                  onChange={(val) => {
                    val > 1000 && (val = 1000)
                    val <= 0 && (val = 0)
                    !val && (val = 0)
                    handleChange('configs.alerting.bond0_rx_bytes_threshold', `${val}`)
                  }}
                />
              </div>
              <div className="ml-2 mt-3 text-md">
                Mb/s
                <FileDownloadIcon className="text-purple-400" fontSize="inherit" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h4>{t('PACKET_ERROR_ALERT')} :</h4>
        <div className=" grid md:grid-cols-3 grid-col-1 gap-4 grid-cols-2 p-5">
          <div className="col-span-3">
            <Switch
              disabled={true}
              value={data?.bond0_errors_threshold}
              onChange={(val) => handleChange('bond0_errors_threshold', val)}
              title={t('TOGGLE_PACKET_ERROR_ALERT')}
              description={t('TOGGLE_PACKET_ERROR_ALERT_DESCRIPTION')}
            />
          </div>
          <div className="col-span-3 grid grid-col-1">
            <div className="grid grid-cols-5 gap-2">
              <div className="col-span-3 pb-6">
                <label className="block text-sm font-medium dark:text-white">
                  {t('SELECT_PACKET_ERROR_RANGE')}
                </label>
                <input
                  onChange={(e) => handleChange('bond0_tx_errors_threshold', e.target.value)}
                  max="100"
                  type="range"
                  value={
                    data?.bond0_tx_errors_threshold == 0
                      ? 0
                      : data?.bond0_tx_errors_threshold > 0
                      ? data?.bond0_tx_errors_threshold
                      : 50
                  }
                  className="mr-5 rounded-lg w-full "
                  disabled={!data?.bond0_errors_threshold}
                />
              </div>
              <div className="col-span-1">
                <Input
                  value={
                    data?.bond0_tx_errors_threshold == 0
                      ? 0
                      : data?.bond0_tx_errors_threshold > 0
                      ? data?.bond0_tx_errors_threshold
                      : 50
                  }
                  readOnly={!data?.bond0_errors_threshold}
                  placeholder="0"
                  onChange={(val) => {
                    val > 100 && (val = 100)
                    val <= 0 && (val = 0)
                    !val && (val = 0)
                    handleChange('bond0_tx_errors_threshold', `${val}`)
                  }}
                />
              </div>
              <div className="ml-3 mt-3">%</div>
            </div>
            <p className="text-sm text-gray-400">
              {t('ERROR_ALERT_RES')} {data?.bond0_tx_errors_threshold}%
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeviceAlerting
