const GlobalAdminStats = ({ number, title, description, icon }) => {
  return (
    <>
      <div className="border border-gray-200 shadow-lg rounded-lg w-full flex relative overflow-hidden">
        <div className="w-full p-3">
          <div className="w-full text-gray-400 dark:text-white text-lg">{title}</div>
          <div className="w-full text-2xl font-bold text-gray-400 dark:text-white mt-5">
            {number}
          </div>
          <div className="w-full mt-5 text-gray-200">{description}</div>
          <div className=" absolute -top-10 -right-8 text-gray-200">{icon}</div>
        </div>
      </div>
    </>
  )
}

export default GlobalAdminStats
