const Switch = ({ title, description, onChange, value, textLeft, classLabel, disabled }) => {
  return (
    <div>
      {textLeft && (
        <label
          className={`lg:text-sm text-lg leading-5 ${
            disabled !== true ? 'text-gray-400' : 'text-gray-400'
          } font-medium dark:text-white mr-2`}
        >
          {title}
        </label>
      )}
      <div
        className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        onClick={() => {
          if (!disabled) onChange(!value)
        }}
      >
        <input
          aria-label="toggle switch"
          readOnly
          checked={value || false}
          type="checkbox"
          disabled={disabled}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        />
        <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer" />
      </div>
      {!textLeft && (
        <label
          className={
            ' lg:text-sm text-lg leading-5 ' +
            `${disabled ? 'text-gray-400' : 'text-primary'}` +
            ' dark:text-white ' +
            `${classLabel ? classLabel : 'font-medium'}`
          }
        >
          {title}
        </label>
      )}
      <p className={'text-sm ' + `${disabled ? 'text-gray-300' : 'text-gray-500'}`}>
        {description}
      </p>
    </div>
  )
}
export default Switch
