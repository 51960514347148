const moment = require('moment')

exports.hoursFormat = (date) => {
  const newDate = new Date(date)

  // Extract hours, minutes, and seconds from the date
  const hours = String(newDate.getHours()).padStart(2, '0')
  const minutes = String(newDate.getMinutes()).padStart(2, '0')
  const seconds = String(newDate.getSeconds()).padStart(2, '0')

  // Return the formatted string
  return `${hours}:${minutes}:${seconds}`
}

exports.formatDate = (date) => {
  let day = ('0' + date.getDate()).slice(-2) // typeof = number
  let month = ('0' + (date.getMonth() + 1)).slice(-2) // typeof = number
  let year = date.getFullYear() // typeof = numbers
  return `${year}/${month}/${day}`
}
exports.dateToTimestamp = (date) => date.getTime() * 1000000

exports.fullDateFormat = (date) => {
  const newDate = new Date(date)
  return new Intl.DateTimeFormat('fr-fr', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(newDate)
}

exports.startEndDates = (i) => {
  const now = new Date()
  let startDate
  switch (i) {
    case '1d':
      startDate = new Date(now.getTime() - 24 * 3600000)
      break
    case '2d':
      startDate = new Date(now.getTime() - 24 * 2 * 3600000)
      break
    case '3d':
      startDate = new Date(now.getTime() - 24 * 3 * 3600000)
      break
    case '7d':
      startDate = new Date(now.getTime() - 24 * 7 * 3600000)
      break
  }

  return { startDate, endDate: now }
}

exports.formatDateTime = (date) => {
  const newDate = moment(date)
  if (moment().diff(newDate, 'hours') <= 4) {
    return newDate.fromNow()
  } else if (moment().diff(newDate, 'hours') <= 24) {
    return `${newDate.calendar().split(' ')[0]} ${newDate.format('hh:mm A')}`
  } else if (moment().diff(newDate, 'days') <= 7) {
    return newDate.format('ddd hh:mm A')
  }
  return newDate.format('DD/MM/YYYY hh:mm A')
}
