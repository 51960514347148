import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/user`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const forgetPassword = createAsyncThunk('password/forget', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/forgetPassword`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

export const resetPassword = createAsyncThunk('password/reset', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/resetPassword`, 'PUT', data, {
    'x-access-token': data.token
  })
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const userSlice = createSlice({
  name: 'forgetPassword',
  initialState,
  reducers: {
    resetState: (state) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.data = action.payload
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.data = action.payload
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})
const { reducer } = userSlice
const reset = userSlice.actions.resetState
export { reset }
export default reducer
