import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getAllGroups } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const update = createAsyncThunk('groups/update', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update/${data.id}`, 'PUT', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllGroups())
  return res.data
})

export const resetUpdate = createAsyncThunk('groups/reset', async () => {
  return true
})

const groupSlice = createSlice({
  name: 'updateGroup',
  initialState,
  extraReducers: {
    [update.pending]: (state) => {
      return { ...state, loading: true }
    },
    [update.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [update.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetUpdate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = groupSlice
export default reducer
