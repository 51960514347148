import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useEffect, useState, Fragment } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'

import { capitalizeFirstLetter } from '../../helpers/string'
import Spinner from '../spinner/Spinner'

const Tree = ({ data, isLoading, onAdd, onEdit, onDelete }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState({})
  useEffect(() => {
    if (!isLoading && data[0] && data[0].length && !Object.keys(show).length) {
      setShow({ [data[0][data[0].length - 1].device_tag_id]: true })
    }
  }, [data])
  return (
    <div className="px-8 pb-8">
      <div
        className="mt-2 p-2 flex items-center rounded-lg border border-gray-200 border-l-blue-500 border-l-4 "
        role="alert"
      >
        <InfoOutlinedIcon className="text-blue-500" fontSize="large" />
        <p className="py-2 text-gray-500">{t('CREATE_TAG')}</p>
      </div>
      {data.loading ? (
        <Spinner version={1} isContainer />
      ) : (
        <ul className="tree relative">
          {data && data[0]
            ? data[0].map((e, i) => (
                <Fragment key={i}>
                  <li className="group">
                    <button
                      onClick={() => {
                        setShow((prev) => ({
                          ...prev,
                          [e.device_tag_id]: !prev[[e.device_tag_id]]
                        }))
                      }}
                      className=" absolute -ml-10 mt-2 border rounded-full h-8 w-8 items-center justify-center flex shadow-sm hover:bg-gray-300"
                    >
                      {show[e.device_tag_id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </button>
                    <div className="flex border w-60 rounded-md space-x-2 relative shadow-sm bg-white">
                      <span className={`w-2 rounded-l-md ${e.color}`} />
                      <span>{capitalizeFirstLetter(e.name)}</span>
                      <div className="absolute hidden group-hover:flex items-center justify-center right-0  px-1 h-full space-x-1">
                        <button
                          onClick={() => onAdd(e.device_tag_id)}
                          className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-blue-500 hover:text-white"
                        >
                          <AddCircleOutlineIcon fontSize="inherit" />
                        </button>
                        <button
                          onClick={() => onEdit(e)}
                          className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-blue-500 hover:text-white "
                        >
                          <EditIcon fontSize="inherit" />
                        </button>
                        <button
                          onClick={() => onDelete(e.device_tag_id)}
                          className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-red-500 hover:text-white"
                        >
                          <DeleteIcon fontSize="inherit" />
                        </button>
                      </div>
                    </div>
                  </li>
                  {data[1] && data[1][e.device_tag_id] && (
                    <ul className={`${show[e.device_tag_id] ? 'block' : 'hidden'}`}>
                      {data[1][e.device_tag_id].map((ch1, j) => (
                        <Fragment key={j}>
                          <li className="group">
                            <div className="flex border w-60 rounded-md space-x-2 relative shadow-sm">
                              <span className={`w-2 rounded-l-md ${ch1.color}`} />
                              <span>{capitalizeFirstLetter(ch1.name)}</span>
                              <div className="absolute hidden group-hover:flex items-center justify-center right-0  px-1 h-full space-x-1 bg-white">
                                <button
                                  onClick={() => onAdd(ch1.device_tag_id)}
                                  className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-blue-500 hover:text-white"
                                >
                                  <AddCircleOutlineIcon fontSize="inherit" />
                                </button>
                                <button
                                  onClick={() => onEdit(ch1)}
                                  className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-blue-500 hover:text-white "
                                >
                                  <EditIcon fontSize="inherit" />
                                </button>
                                <button
                                  onClick={() => onDelete(ch1.device_tag_id)}
                                  className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-red-500 hover:text-white"
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </button>
                              </div>
                            </div>
                          </li>
                          {data[2] && data[2][ch1.device_tag_id] && (
                            <ul>
                              {data[2][ch1.device_tag_id].map((ch2, k) => (
                                <li key={k} className="group">
                                  <div className="flex border w-60 rounded-md space-x-2 relative shadow-sm">
                                    <span className={`w-2 rounded-l-md ${ch2.color}`} />
                                    <span>{capitalizeFirstLetter(ch2.name)}</span>
                                    <div className="absolute hidden group-hover:flex items-center justify-center right-0  px-1 h-full space-x-1 bg-white">
                                      <button
                                        onClick={() => onEdit(ch2)}
                                        className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-blue-500 hover:text-white "
                                      >
                                        <EditIcon fontSize="inherit" />
                                      </button>
                                      <button
                                        onClick={() => onDelete(ch2.device_tag_id)}
                                        className="h-8 w-8 border rounded-md flex items-center justify-center hover:bg-red-500 hover:text-white"
                                      >
                                        <DeleteIcon fontSize="inherit" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Fragment>
                      ))}
                    </ul>
                  )}
                </Fragment>
              ))
            : null}
          <li>
            <div
              onClick={() => onAdd()}
              className="flex border w-60 rounded-md space-x-2 relative shadow-sm items-center justify-center cursor-pointer"
            >
              <div>
                <AddCircleOutlineIcon />
              </div>
            </div>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Tree
