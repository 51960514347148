import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Explain from '../Tooltip/Explain'
import { useDispatch, useSelector } from 'react-redux'
import { downloadCsr } from '../../store/certificate/downloadCsr'
import DownloadIcon from '@mui/icons-material/Download'

const DownloadCsr = ({ app, id }) => {
  const { t } = useTranslation()
  const [selectedFormat, setSelectedFormat] = useState('ECC')
  const [dotxConfirm, setDotxConfirm] = useState(false)
  const csrState = useSelector((state) => state.downloadCsr)
  const dispatch = useDispatch()

  const handleSubmit = (event) => {
    dispatch(
      downloadCsr({
        format: selectedFormat,
        id: id,
        app: app
      })
    ).then((res) => {
      if (res.payload.status === 'success') {
        const file = new Blob([res.payload.data], { type: 'application/x-pem-file' })
        const element = document.createElement('a')
        element.href = URL.createObjectURL(file)
        element.download = 'csr_' + id + '_' + app + '.pem'
        document.body.appendChild(element)
        element.click()
      }
    })
    event.preventDefault()
  }
  return (
    <div className="border p-5 space-y-3">
      <fieldset defaultValue="RSA">
        <legend className="p-2 flex space-x-2">
          <p>{t('SELECT_FORMAT')}</p>
          <Explain
            description={
              app === 'dot1x' ? t('DOWNLOAD_DOT1X_CSR_DESC') : t('DOWNLOAD_TLS_CSR_DESC')
            }
          />
        </legend>
        <hr />
        <div className="p-2 mx-11 flex justify-between">
          <div
            className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
            onClick={() => setSelectedFormat('RSA')}
          >
            <input
              checked={selectedFormat === 'RSA'}
              type="radio"
              id={`RSA-${id}-${app}`}
              name={`format-${id}-${app}`}
              value="RSA"
              onChange={() => setSelectedFormat('RSA')}
            />
            <label htmlFor={`RSA-${id}-${app}`} className="cursor-pointer">
              RSA
            </label>
          </div>
          <div
            className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
            onClick={() => setSelectedFormat('ECC')}
          >
            <input
              checked={selectedFormat === 'ECC'}
              type="radio"
              id={`ECC-${id}-${app}`}
              name={`format-${id}-${app}`}
              value="ECC"
              onChange={() => setSelectedFormat('ECC')}
            />
            <label htmlFor={`ECC-${id}-${app}`} className="cursor-pointer">
              ECC
            </label>
          </div>
        </div>
      </fieldset>
      <div className="mt-4">
        {dotxConfirm && (
          <p className="text-red-500 text-sm pb-1.5">
            {app === 'dot1x' ? t('DOWNLOAD_DOT1X_CSR_CONFIRM') : t('DOWNLOAD_TLS_CSR_CONFIRM')}
          </p>
        )}
        <button
          type="button"
          disabled={csrState.loading}
          onClick={(e) => {
            if (dotxConfirm) {
              handleSubmit(e)
              setDotxConfirm(false)
            } else {
              setDotxConfirm(true)
            }
          }}
          className={` w-full flex items-center justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white  ${
            csrState.loading ? ' cursor-not-allowed bg-blue-400' : 'bg-blue-500 hover:bg-blue-400'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 space-x-2`}
        >
          <p>{t('DOWNLOAD_CSR')}</p>
          {csrState.loading ? (
            <div className="relative flex items-center justify-center">
              <div className=" animate-spin flex items-center justify-center rounded-full p-3 bg-gradient-to-tr from-blue-500 to-white" />
              <div className="absolute p-2 bg-blue-400 rounded-full" />
            </div>
          ) : (
            <DownloadIcon />
          )}
        </button>
      </div>
    </div>
  )
}

export default DownloadCsr
