import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import Action from '../actions/row/Actions'

const WidgetRow = ({ cols, row, onDelete, onEdit }) => {
  return (
    <tr className="border-b text-o dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 " />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {capitalizeFirstLetter(row[col.key])}
            </td>
          )
        } else if (col.label === 'Actions') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <div className="flex items-stretch">
                <Action
                  row={row}
                  onDelete={(row) => onDelete(row.widget_item_id)}
                  onEdit={onEdit}
                />
              </div>
            </td>
          )
        } else if (col.label === 'Created_At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.created_at)}
            </td>
          )
        } else if (col.label === 'Updated_At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.updated_at)}
            </td>
          )
        }
      })}
    </tr>
  )
}

export default WidgetRow
