import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import DeleteIcon from '@mui/icons-material/Delete'

const Row = ({ cols, row, onDelete }) => {
  return (
    <tr className="border-b text-o dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 " />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {capitalizeFirstLetter(row[col.key])}
            </td>
          )
        } else if (col.label === 'Actions') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <div className="flex items-stretch">
                <button
                  onClick={() => onDelete(row.id)}
                  className="rounded-full flex items-center p-2 text-gray-700 hover:bg-red-100 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                >
                  <DeleteIcon />
                </button>
              </div>
            </td>
          )
        } else if (col.label === 'Created At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.created_at)}
            </td>
          )
        } else if (col.label === 'Updated At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.updated_at)}
            </td>
          )
        }
      })}
    </tr>
  )
}

export default Row
