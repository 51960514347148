import Switch from '../../common/switch/Switch'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'

const DeviceNetwork = ({ data, error, handleChange, changes }) => {
  const { t } = useTranslation()
  console.log(changes)
  return (
    <div className=" ">
      <div className="p-4 text-gray-600 flex items-center justify-between dark:text-gray-200">
        <form className="w-full flex flex-col space-y-4">
          <div className="flex space-x-4">
            <Switch
              title={`${t('DHCP')} ${changes['configs.network.dhcp'] ? '●' : ''}`}
              value={data.dhcp}
              description={t('DHCP_DESCRIPTION')}
              onChange={(v) => handleChange('configs.network.dhcp', v)}
            />
          </div>
          {!data.dhcp && (
            <>
              <div className="flex h-full my-2">
                <Input
                  label={`${t('STATIC_IP')} ${changes['configs.network.ip_address'] ? '●' : ''}`}
                  placeholder={t('STATIC_IP')}
                  value={data.ip_address}
                  onChange={(v) => handleChange('configs.network.ip_address', v)}
                  error={error.network.ip_address}
                />
              </div>
              <div className="flex h-full my-2">
                <Input
                  label={`${t('NETMASK')} ${changes['configs.network.netmask'] ? '●' : ''}`}
                  placeholder={t('NETMASK')}
                  value={data.netmask}
                  onChange={(v) => handleChange('configs.network.netmask', v)}
                  error={error.network.netmask}
                />
              </div>
              <div className="flex h-full my-2">
                <Input
                  label={`${t('GATEWAY')} ${changes['configs.network.gateway'] ? '●' : ''}`}
                  placeholder={t('GATEWAY')}
                  value={data.gateway}
                  onChange={(v) => handleChange('configs.network.gateway', v)}
                  error={error.network.gateway}
                />
              </div>
            </>
          )}
          <div className="">
            <div className="flex h-full my-2">
              <Input
                label={`${t('PRIMARY_DNS')} ${changes['configs.network.dns1'] ? '●' : ''}`}
                placeholder={t('PRIMARY_DNS')}
                value={data.dns1}
                onChange={(v) => handleChange('configs.network.dns1', v)}
                error={error.dns1}
              />
            </div>
            <div className="flex h-full my-2">
              <Input
                label={`${t('SECONDARY_DNS')} ${changes['configs.network.dns2'] ? '●' : ''}`}
                placeholder={t('SECONDARY_DNS')}
                value={data.dns2}
                onChange={(v) => handleChange('configs.network.dns2', v)}
                error={error.dns2}
              />
            </div>
            <div className="flex h-full my-2">
              <Input
                label={`${t('HOST_NAME')} ${changes['configs.network.hostname'] ? '●' : ''}`}
                placeholder={t('HOST_NAME')}
                value={data.hostname}
                onChange={(v) => handleChange('configs.network.hostname', v)}
              />
            </div>
            <div className="flex h-full my-2">
              <Input
                label={`${t('NTP')} ${changes['configs.network.ntp'] ? '●' : ''}`}
                placeholder={t('NTP')}
                value={data.ntp}
                onChange={(v) => handleChange('configs.network.ntp', v)}
                error={error.ntp}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default DeviceNetwork
