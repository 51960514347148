import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import { getAllDevices } from '../devices/getAll'
import { update } from '../devices/getOne'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/information`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const updateInformation = createAsyncThunk('informations/update', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update/${data.id}`, 'PUT', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }

  thunkAPI.dispatch(success('INFO_SENT_SUCCESS'))
  const user = thunkAPI.getState().currentUser
  thunkAPI.dispatch(update({ data: res.data, user }))
  thunkAPI.dispatch(getAllDevices())
  return res.data
})

const informationSlice = createSlice({
  name: 'information',
  initialState,
  extraReducers: {
    [updateInformation.pending]: (state) => {
      return { ...state, loading: true }
    },
    [updateInformation.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [updateInformation.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    }
  }
})
const { reducer } = informationSlice
export default reducer
