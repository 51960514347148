import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { deleteCompanies } from '../../store/compagnies/delete'
import { useTranslation } from 'react-i18next'
import GlobalModal from '../modals/Layout'
import { useDispatch } from 'react-redux'

const DeleteCompany = ({ id, name, setShowModalDelete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <GlobalModal
      buttons={[
        {
          title: t('CANCEL'),
          action: () => {
            setShowModalDelete(false)
          },
          type: 'secondary'
        },
        {
          title: t('CONFIRM'),
          action: () => {
            dispatch(deleteCompanies(id))
            setShowModalDelete(false)
          },
          type: 'primary'
        }
      ]}
      title={`${t('DELETE')} ${t('COMPANY')}`}
    >
      <div className="grid grid-cols-1 mt-3">
        <div className="mx-auto rounded-full bg-red-100 p-4 dark:bg-red-900/50 my-3">
          <DeleteForeverIcon className="text-red-400 text-7xl" fontSize="" />
        </div>
        <div className="mx-auto text-xl">
          <b>{t('ARE_U_SURE')}</b>
        </div>
        <div className="mx-auto text-md my-3">{t('DELETE_COMPANY_DESC', { company: name })}</div>
      </div>
    </GlobalModal>
  )
}

export default DeleteCompany
