import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { resetStore } from '../common/resetStore'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/user`
const initialState = {
  isSwitch: false,
  loading: false,
  data: {},
  error: null
}

export const signin = createAsyncThunk('user/signin', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/signin`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getOne`, 'Get', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

export const switchOganization = createAsyncThunk('user/switch', async (orgId, thunkAPI) => {
  const res = await axios(`${basedUrl}/switch/${orgId}`, 'Put')
  if (res.status === 'error') {
    if (res.code === 401) {
      return thunkAPI.dispatch(logout())
    } else {
      return thunkAPI.rejectWithValue(res.message)
    }
  }
  await thunkAPI.dispatch(resetStore())
  return res.data
})

export const logout = createAsyncThunk('user/logout', async (data, thunkAPI) => {
  thunkAPI.fulfillWithValue()
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true
        state.data = {}
      })
      .addCase(signin.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.token)
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(signin.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true
        state.data = {}
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.token)
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(logout.fulfilled, (state, action) => {
        // reset user data
        state = initialState
        localStorage.removeItem('token')
        localStorage.removeItem('persist:root')
        window.location.reload()
      })
      .addCase(switchOganization.pending, (state) => {
        state.loading = true
        state.isSwitch = true
        state.data = {}
      })
      .addCase(switchOganization.fulfilled, (state, action) => {
        localStorage.setItem('token', action.payload.token)
        state.loading = false
        state.data = action.payload
        state.isSwitch = false
        state.error = null
      })
      .addCase(switchOganization.rejected, (state, action) => {
        state.loading = false
        state.isSwitch = false
        state.error = action.payload
      })
  }
})
const { reducer } = userSlice
export default reducer
