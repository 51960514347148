import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import { getAllDeviceGroups, combinetGroup } from '../store/deviceGroups/getAll'
import DeleteConfiramation from '../components/modals/DeleteConfirmation'
import DeviceGroupModal from '../components/modals/DeviceGroupModal'
import { deleteDeviceGroup } from '../store/deviceGroups/delete'
import Filter from '../components/table/actions/header/Filter'
import DeviceModal from '../components/modals/DeviceModal'
import { capitalizeFirstLetter } from '../helpers/string'
import { deleteDevice } from '../store/devices/deleteOne'
import { getAllDevices } from '../store/devices/getAll'
import { getAllGroups } from '../store/groups/getAll'
import StateInfo from '../common/stateInfo/StateInfo'
import { getAllTags } from '../store/tags/getAll'
import Table from '../components/table/Table'
import { t } from 'i18next'

const limit = 10

const Devices = () => {
  const deviceGroup = useSelector((state) => state.deviceGroup)
  const devices = useSelector((state) => state.getAllDevice)
  const userGroup = useSelector((state) => state.groups)
  const tags = useSelector((state) => state.tags)

  const [groupToBeUpdated, setGroupToBeUpdated] = useState(null)
  const [showModalDevice, setShowModalDevice] = useState(false)
  const [showModalGroup, setShowModalGroup] = useState(false)
  const [itemToBeDeleted, setItemToBeDeleted] = useState()
  const [deviceInfoModal, setDeviceInfoModal] = useState()
  const [groupsChoice, setGroupsChoices] = useState({})
  const [sliceList, setSliceList] = useState([])
  const [filter, setFilter] = useState([])
  const [offset, setOffset] = useState(1)
  const [query, setQuery] = useState([])
  const [total, setTotal] = useState(0)
  const [view, setView] = useState(0)

  const currentDevices = useRef()
  const currentUserGroup = useRef()
  const prevCombinet = useRef()

  const dispatch = useDispatch()

  const handlePagination = (page) => {
    if (query.length && filter.length) {
      setSliceList(filter.slice(limit * (page - 1), limit * page))
    } else {
      if (view === 0) {
        setSliceList(devices.data.rows.slice(limit * (page - 1), limit * page))
      } else {
        setSliceList(deviceGroup.data.rows.slice(limit * (page - 1), limit * page))
      }
    }
    setOffset(page)
  }
  useEffect(() => {
    if (!devices.loading && !devices.data.count) {
      dispatch(getAllDevices())
    }
    if (!devices.loading && devices.data.count) {
      handleFirstPage(0)
    }
    if (!tags.loading && !tags.data.length) {
      dispatch(getAllTags())
    }
  }, [])

  useEffect(() => {
    if (currentUserGroup.current && !userGroup.loading) {
      dispatch(combinetGroup())
      groupParcer()
    }
    currentUserGroup.current = deviceGroup.loading
  }, [userGroup])
  useEffect(() => {
    // update total page and pagination after retrive device group

    if (currentDevices.current && !deviceGroup.loading) {
      dispatch(combinetGroup())
      handleFirstPage(1)
    }

    if (prevCombinet.current === false && deviceGroup.isCombineted) {
      setSliceList(filter.slice(limit * (offset - 1), limit * offset))
    }
    currentDevices.current = deviceGroup.loading
    prevCombinet.current = deviceGroup.isCombineted
  }, [deviceGroup])
  useEffect(() => {
    // update total page and pagination after retrive devices
    if (currentDevices.current && !devices.loading) {
      handleFirstPage(0)
    }
    if (devices.data && devices.data.count) {
      setSliceList(devices.data.rows.slice(limit * (offset - 1), limit * offset))
    }
    if (devices.data && devices.data.count === 0) {
      setSliceList([])
    }

    currentDevices.current = devices.loading
  }, [devices])

  const handleRefresh = (page) => {
    if (page === 0) {
      dispatch(getAllDevices())
    } else {
      dispatch(getAllDeviceGroups())
    }
  }
  const groupParcer = () => {
    const list = {}
    for (const group of userGroup.data.rows) {
      list[group.group_id] = `${capitalizeFirstLetter(group.name)}`
    }
    setGroupsChoices(list)
  }
  const handleSearch = (ids) => {
    let data = devices.data.rows
    if (ids && ids.length) {
      if (query && filter.length && ids.length > query.length) {
        data = filter
      }
      const res = data.filter((el) => {
        if (
          el.informations &&
          el.informations[0] &&
          el.informations[0].tags &&
          el.informations[0].tags.length >= ids.length
        ) {
          const found = el.informations[0].tags.filter((item) => ids.includes(item.device_tag_id))
          if (found && found.length === ids.length) return el
        }
      })
      setFilter(res)
      setSliceList(res.slice(0, limit))
      setTotal(res.length)
    } else {
      setFilter([])
      setSliceList(devices.data.rows.slice(0, limit))
      setTotal(devices.data.count)
    }
    setOffset(1)
    setQuery(ids)
  }

  const handleFirstPage = (page) => {
    // Go to first page after change view (group/devices)
    if (page === 0) {
      if (devices.data.count) setSliceList(devices.data.rows.slice(0, limit))
      setTotal(devices.data.count)
      setOffset(1)
    } else {
      if (deviceGroup.data.count) setSliceList(deviceGroup.data.rows.slice(0, limit))
      setTotal(deviceGroup.data.count)
      setOffset(1)
    }
  }
  const handleDelete = () => {
    if (itemToBeDeleted.type === 'device') {
      dispatch(deleteDevice(itemToBeDeleted.id))
    } else {
      dispatch(deleteDeviceGroup(itemToBeDeleted.id))
    }
    setItemToBeDeleted()
  }

  const handleChangeView = () => {
    // update view (page) and redirect to first page
    if (view === 0) {
      if (!deviceGroup.loading && !deviceGroup.data.count) {
        dispatch(getAllDeviceGroups())
        dispatch(getAllGroups())
      } else {
        handleFirstPage(1)
      }
    } else {
      // to devices
      handleFirstPage(0)
    }
    setView(view ? 0 : 1)
  }
  const updateGroup = (row) => {
    setGroupToBeUpdated({
      id: row.device_group_id,
      name: row.name,
      read: row.read,
      edit: row.edit
    })
    setShowModalGroup(true)
  }
  return (
    <>
      {itemToBeDeleted?.id && (
        <DeleteConfiramation
          action={itemToBeDeleted.name}
          handleClose={() => setItemToBeDeleted({})}
          handleConfirm={handleDelete}
        />
      )}
      {devices.error ? (
        devices.error.code === 403 ? (
          <div className="h-full flex items-center justify-center">
            <StateInfo type="warning" description={devices.error.message} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <StateInfo
              type="error"
              description={
                typeof devices.error === 'object' ? devices.error.message : devices.error
              }
            />
          </div>
        )
      ) : (
        <div className="h-full p-4">
          <div className="flex flex-col border border-gray-200 rounded-lg">
            <div className="h-full inline-block w-full">
              <div className="flex justify-between bg-gray-100 border-b">
                <ul className="flex justify-start">
                  <li
                    className={`p-3 px-12 hover:bg-blue-50 cursor-pointer ${
                      view === 0 && 'border-b-4 border-blue-500 text-blue-600'
                    }`}
                    onClick={() => handleChangeView()}
                  >
                    <div className="flex items-center space-x-4">
                      <span>{t('DEVICES')}</span>
                    </div>
                  </li>
                  <li
                    className={`p-3 px-12 hover:bg-blue-50 cursor-pointer ${
                      view === 1 && 'border-b-4 border-blue-500 text-blue-600'
                    }`}
                    onClick={() => handleChangeView()}
                  >
                    <div className="flex items-center">
                      <span>{t('GROUPS')}</span>
                    </div>
                  </li>
                </ul>
                <Filter
                  value={true}
                  onRefresh={() => handleRefresh(0)}
                  rows={tags.data}
                  onSearch={handleSearch}
                  onAdd={view === 1 && (() => setShowModalGroup(true))}
                  onSetting={() => handleChangeView()}
                />
              </div>
              {view === 0 ? (
                <Table
                  data={sliceList}
                  isLoading={devices.loading}
                  total={total}
                  currentPage={offset}
                  type="devices"
                  onEdit={(row) => {
                    setDeviceInfoModal(row)
                    setShowModalDevice(true)
                  }}
                  onDelete={(id, name, type) => setItemToBeDeleted({ id, name: name, type: type })}
                  handlePagination={handlePagination}
                />
              ) : (
                <Table
                  data={deviceGroup.data.rows}
                  isLoading={deviceGroup.loading || userGroup.loading}
                  total={total}
                  currentPage={offset}
                  type="deviceGroup"
                  onEdit={updateGroup}
                  onDelete={(id, name, type) => setItemToBeDeleted({ id, name: name, type: type })}
                  handlePagination={handlePagination}
                />
              )}
            </div>
          </div>

          {showModalGroup && (
            <DeviceGroupModal
              groups={groupsChoice}
              row={groupToBeUpdated}
              handleClose={() => {
                setShowModalGroup(false)
                setGroupToBeUpdated(null)
              }}
            />
          )}
          {showModalDevice && (
            <DeviceModal
              data={deviceInfoModal}
              handleClose={() => {
                setShowModalDevice(false)
              }}
            />
          )}
        </div>
      )}
    </>
  )
}
export default Devices
