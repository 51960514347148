import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getCurrentUser } from './auth'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/user`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const updateUser = createAsyncThunk('user/update', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update`, 'PUT', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getCurrentUser())
  return res.data
})

const userSlice = createSlice({
  name: 'updateUser',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})
const { reducer } = userSlice
export default reducer
