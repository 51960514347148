import { createDeviceGroup, resetCreate } from '../../store/deviceGroups/create'
import { updateDeviceGroup, resetUpdate } from '../../store/deviceGroups/update'
import MultiSelect from '../../common/fields/MutliSelect'
import Success from '../../common/stateInfo/StateInfo'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'
import GlobalModal from './Layout'

const DeviceGroupModal = ({ handleClose, row, groups }) => {
  const { t } = useTranslation()
  const createDeviceGroupState = useSelector((state) => state.createDeviceGroup)
  const updateDeviceGroupState = useSelector((state) => state.updateDeviceGroup)
  const [alertType, setAlertType] = useState(null)
  const [error, setError] = useState({})
  const [name, setName] = useState(row ? row.name : '')
  const [readList, setReadList] = useState(row ? row?.read?.map((i) => i.group_id) : [])
  const [editList, setEditList] = useState(row ? row?.edit?.map((i) => i.group_id) : [])
  const [showAlert, setShowAlert] = useState(false)

  const dispatch = useDispatch()
  const prevCreateGroup = useRef()
  const prevUpdateGroup = useRef()
  useEffect(() => {
    if (prevCreateGroup.current && !createDeviceGroupState.loading) {
      if (createDeviceGroupState.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      dispatch(resetCreate())
      setShowAlert(true)
    }
    prevCreateGroup.current = createDeviceGroupState.loading
  }, [createDeviceGroupState])

  useEffect(() => {
    if (prevUpdateGroup.current && !updateDeviceGroupState.loading && updateDeviceGroupState.data) {
      if (updateDeviceGroupState.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      dispatch(resetUpdate())
      setShowAlert(true)
    }
    prevUpdateGroup.current = updateDeviceGroupState.loading
  }, [updateDeviceGroupState])

  const handleSubmit = () => {
    const errorList = {}
    if (!name) {
      errorList.name = t('GROUP_NAME_REQUIRED')
    }
    if (!Object.keys(errorList).length) {
      if (row) {
        // update
        dispatch(updateDeviceGroup({ read: readList, edit: editList, name, id: row.id }))
      } else {
        dispatch(createDeviceGroup({ read: readList, edit: editList, name }))
      }
    }

    setError(errorList)
  }
  return (
    <GlobalModal
      title={!row ? `${t('CREATE')} ${t('DEVICE_GROUP')} ` : t('EDIT_GROUP')}
      size="small"
      buttons={[
        !showAlert
          ? {
              title: t('CREATE'),
              action: () => {
                handleSubmit()
              }
            }
          : {},
        {
          type: 'secondary',
          title: t('CLOSE'),
          action: handleClose
        }
      ]}
    >
      {showAlert ? (
        <Success type={alertType} />
      ) : (
        <div className="relative p-6 flex-auto">
          <form className="w-full">
            <div className="mb-2 w-full">
              <Input
                label={`${t('NAME')} ${t('DEVICE_GROUP')}`}
                value={name}
                onChange={setName}
                placeholder="name"
                error={error.name}
              />
            </div>

            <div className="mb-2 w-full">
              <MultiSelect
                label={`${t('READ_PERMISSION')}`}
                choices={groups}
                values={readList}
                onChange={setReadList}
                error={error.groups}
              />
            </div>
            <div className="mb-2 w-full">
              <MultiSelect
                label={`${t('EDIT_PERMISSION')}`}
                choices={groups}
                values={editList}
                onChange={setEditList}
                error={error.groups}
              />
            </div>
          </form>
        </div>
      )}
    </GlobalModal>
  )
}

export default DeviceGroupModal
