import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getAllWidgets } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/widgetItem`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const createWidget = createAsyncThunk('widget/create', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/create`, 'POST', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getAllWidgets())
  return res.data
})

export const resetCreate = createAsyncThunk('tags/reset', async () => {
  return true
})

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [createWidget.pending]: (state) => {
      return { ...state, loading: true }
    },
    [createWidget.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [createWidget.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    },
    [resetCreate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})

const { reducer } = widgetSlice
export default reducer
