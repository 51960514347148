const FillBar = ({ value, max }) => {
  const fillPercentage = (value / max) * 100
  let colorClass = ''

  switch (true) {
    case fillPercentage <= 25:
      colorClass = 'bg-green-500'
      break
    case fillPercentage <= 50:
      colorClass = 'bg-blue-500'
      break
    case fillPercentage <= 75:
      colorClass = 'bg-orange-500'
      break
    default:
      colorClass = 'bg-red-500'
  }
  return (
    <div className="relative w-full h-5 bg-gray-200 rounded-full">
      <div
        className={`absolute left-0 top-0 h-full rounded-full ${colorClass}`}
        style={{ width: `${fillPercentage}%` }}
      />
    </div>
  )
}
export default FillBar
