import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Avatar from 'react-avatar'
import { useState } from 'react'

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonIcon from '@mui/icons-material/Person'

import { updateUser as update } from '../store/currentUser/updateUser'
import { changePassword } from '../store/currentUser/changePassword'
import { capitalizeFirstLetter } from '../helpers/string'
import Spinner from '../components/spinner/Spinner'
import Cover from '../assets/img/logo-oledcomm.png'
import { isValidEmail } from '../helpers/form'
import Button from '../common/buttons/Button'
import Input from '../common/fields/Input'

const Profil = () => {
  const { t } = useTranslation()
  const dispath = useDispatch()
  const user = useSelector((state) => state.currentUser)
  const updateUser = useSelector((state) => state.updateCurrentUser)
  const updatePassword = useSelector((state) => state.updatePassword)
  const [firstName, setFirstName] = useState(user.data.first_name)
  const [lastName, setLastName] = useState(user.data.last_name)
  const [email, setEmail] = useState(user.data.email)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPassword, setCurrentPasword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [error, setError] = useState({})
  const handleUpdateUser = () => {
    const newError = {}
    if (!firstName) {
      newError.firstName = t('NAME_ERROR')
    }
    if (!lastName) {
      newError.lastName = t('NAME_ERROR')
    }
    if (!email || !isValidEmail(email)) {
      newError.email = t('EMAIL_ERROR')
    }
    setError(newError)
    if (!Object.keys(newError).length) {
      dispath(update({ first_name: firstName, last_name: lastName, email }))
    }
  }
  const iconRole = (role) => {
    switch (role) {
      case 'user':
        return <PersonIcon />
      case 'admin':
        return <AdminPanelSettingsIcon />
    }
  }

  const handleUpdatePassword = () => {
    const newError = {}
    if (!currentPassword) {
      newError.currentPassword = t('PASSWORD_EMPTY')
    }
    if (!newPassword) {
      newError.newPassword = t('PASSWORD_EMPTY')
    }
    if (newPassword !== confirmPassword) {
      newError.confirmPassword = t('PASSWORD_NOT_MATCH')
    }
    if (!confirmPassword) {
      newError.confirmPassword = t('PASSWORD_EMPTY')
    }
    setError(newError)
    if (!Object.keys(newError).length) {
      dispath(changePassword({ currentPassword, newPassword }))
    }
  }
  return (
    <div className="flex flex-col flex-shrink-0 h-full bg-white dark:bg-slate-900 relative">
      {(updatePassword.loading || updateUser.loading) && (
        <div className="absolute w-full h-full z-50 top-0 left-0">
          <Spinner version={1} isContainer />
        </div>
      )}
      <div className={`flex flex-col  border-r border bg-white overflow-hidden`}>
        <div className="flex items-center flex-shrink-0 px-6 pb-2" />
        <div className="font-sans leading-tight -mt-3 h-full bg-grey-lighter">
          <div className="w-full mx-auto bg-gray-100  overflow-hidden shadow-sm">
            <div className="h-46 w-full bg-white flex items-center justify-center dark:bg-slate-800">
              <img className="bg-cover w-1/3 h-auto rounded-b-md" src={Cover} alt="cover" />
            </div>
            <div className="border-b px-4 dark:bg-slate-700">
              <div className="text-start object-start flex-col items-start flex mb-4">
                {/* Static parent */}
                <div className="flex w-full">
                  <div className="relative">
                    <div className=" rounded-full border-4 -mt-8 mr-4 shadow-sm">
                      <Avatar
                        name={`${user.data.first_name} ${user.data.last_name}`}
                        round
                        className="h-32 w-32"
                      />
                    </div>
                  </div>
                  <div className="py-2 text-gray-900 dark:text-white">
                    <h3 className="font-bold text-2xl mb-1">
                      {`${capitalizeFirstLetter(user.data.first_name)} ${capitalizeFirstLetter(
                        user.data.last_name
                      )}`}
                    </h3>
                    <div className="inline-flex sm:flex items-center mt-5">
                      {user.data.superiorRole ? (
                        <div className="flex justify-center">
                          <div>{iconRole(user.data.superiorRole)}</div>
                          <p>
                            {t('ROLE')} : {capitalizeFirstLetter(user.data.superiorRole)}
                          </p>
                        </div>
                      ) : (
                        'No role'
                      )}
                    </div>
                  </div>
                  <div className="flex flex-1 float-right item-end justify-end">
                    <div className="flex h-full items-center text-center justify-center px-8 space-x-3 dark:text-white">
                      <MailOutlineIcon />
                      {user.data.email ? (
                        <p>{user.data.email}</p>
                      ) : (
                        <div className="animate-pulse relative flex space-x-3 min-w-0 break-words bg-white rounded shadow-lg dark:bg-slate-700">
                          <div className="w-40 h-2 rounded-full bg-gray-300" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full flex-1 space-y-4 overflow-y-auto m-4 no-scrollbar">
        <div className="overflow-hidden border rounded-md shadow-md pb-4 dark:bg-slate-800 ">
          <div className="py-1">
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="md:p-4 p-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      {t('PERSONAL_INFORMATION')}
                    </h3>
                    <p className="mt-1 text-sm text-gray-400">{t('PERSONAL_INFORMATION_DESC')}</p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow overflow-hidden sm:rounded-md dark:border-l dark:border-b dark:border-gray-400">
                    <div className="px-4 py-5 bg-white sm:p-6 dark:bg-slate-800">
                      <div className="grid grid-cols-2 gap-4">
                        <Input
                          label={t('FIRST_NAME')}
                          value={firstName}
                          onChange={setFirstName}
                          placeholder={t('FIRST_NAME')}
                          type="text"
                          id="first_name"
                          error={error.firstName}
                        />
                        <Input
                          label={t('LAST_NAME')}
                          value={lastName}
                          onChange={setLastName}
                          placeholder={t('LAST_NAME')}
                          type="text"
                          id="last_name"
                          error={error.lastName}
                        />
                        <Input
                          label={t('EMAIL_ADDRESS')}
                          value={email}
                          onChange={setEmail}
                          placeholder={t('EMAIL_ADDRESS')}
                          type="text"
                          id="email"
                          error={error.email}
                        />
                      </div>
                    </div>
                    <div className="px-4 py-3 dark:bg-slate-700 bg-gray-50 text-right sm:px-6">
                      <Button title={t('SAVE')} onClick={handleUpdateUser} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-1">
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="md:p-4 p-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      {t('EDIT')} {t('PASSWORD')}
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      {t('PASSWORD_DESC')}
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="shadow overflow-hidden sm:rounded-md dark:border-l dark:border-b dark:border-gray-400">
                    <div className="px-4 py-5 bg-white sm:p-6 dark:bg-slate-800">
                      <div className="grid grid-cols-1 gap-4 w-1/2">
                        <Input
                          label={t('CURRENT_PASSWORD')}
                          value={currentPassword}
                          onChange={setCurrentPasword}
                          placeholder={t('CURRENT_PASSWORD')}
                          type="password"
                          id="current_password"
                          error={error.currentPassword}
                        />
                        <Input
                          label={`${t('NEW')} ${t('PASSWORD')}`}
                          value={newPassword}
                          onChange={setNewPassword}
                          placeholder={`${t('NEW')} ${t('PASSWORD')}`}
                          type="password"
                          id="new_password"
                          error={error.newPassword}
                        />
                        <Input
                          label={`${t('CONFIRM')} ${t('NEW')} ${t('PASSWORD')}`}
                          value={confirmPassword}
                          onChange={setConfirmPassword}
                          placeholder={`${t('CONFIRM')} ${t('NEW')} ${t('PASSWORD')}`}
                          type="password"
                          id="confirm_password"
                          error={error.confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="px-4 py-3 dark:bg-slate-700 bg-gray-50 text-right sm:px-6">
                      <Button title={t('SAVE')} onClick={handleUpdatePassword} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Profil
