import NotificationsIcon from '@mui/icons-material/Notifications'

import useOnClickOutside from '../../utils/useOnClickOutside'
import PouceLogo from '../../assets/img/20664.png'
import Close from '../../common/buttons/Close'
import { useState, useRef } from 'react'
import AlertItem from './AlertItem'
import { t } from 'i18next'

function Notification({ alerts, onDelete, onResolve, hasALertNotResolved }) {
  const ref = useRef()
  const [showMenu, setShowMenu] = useState(false)
  useOnClickOutside(ref, () => {
    setShowMenu(false)
  })

  return (
    <div className="w-auto text-slate-700 dark:text-gray-200 relative ">
      <div onClick={() => setShowMenu(!showMenu)} className="relative cursor-pointer">
        <NotificationsIcon
          className={` ${
            showMenu ? 'dark:text-white text-gray-400' : ''
          }  dark:hover:text-white b-1 `}
        />
        {hasALertNotResolved && (
          <div className="absolute top-0 left-4 h-2 w-2 mr-4 ">
            <span className="absolute z-1 h-full w-full bg-red-500 rounded-full animate-ping opacity-75" />
            <span className="absolute h-2 z-2 w-2 bg-red-500 rounded-full" />
          </div>
        )}
      </div>
      {showMenu && (
        <div className={`fixed inset-0 z-20 overflow-hidden`}>
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-600 opacity-50" />
          </div>
          <div className={`absolute inset-0 z-0 overflow-hidden `}>
            <section className="absolute inset-y-0 pl-16 max-w-full right-0 flex ">
              <div ref={ref} className="w-screen max-w-md  border">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white dark:bg-slate-800 ">
                  <div className="flex-1 h-0">
                    <header className="space-y-1 h-15 inherit w-full top-0  py-4 px-4 bg-gray-200 dark:bg-slate-700 sm:px-6">
                      <div className="flex items-center justify-between space-x-3">
                        <h2 className="text-lg leading-7 font-medium">
                          {t('ALERTS')} ({alerts.length})
                        </h2>
                        <div className="h-7 flex items-center">
                          <Close handleClose={() => setShowMenu(false)} color="red" />
                        </div>
                      </div>
                    </header>
                    <div className="flex-1 mt-2 h-full bg-primary flex flex-col justify-between">
                      <div className=" divide-y overflow-auto h-full divide-gray-200 sm:px-2 no-scrollbar">
                        <div className="flex flex-col space-y-2">
                          {alerts.length ? (
                            alerts.map((e) => (
                              <AlertItem
                                data={e}
                                key={e.device_alert_id}
                                onDelete={onDelete}
                                onResolve={onResolve}
                              />
                            ))
                          ) : (
                            <div className="h-full w-full flex flex-col items-center justify-center mt-5 space-y-6">
                              <div className="text-md">{t('NO_ALERT_FOUND')} </div>
                              <img className="h-10 w-auto" src={PouceLogo} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  )
}

export default Notification
