import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import DangerousIcon from '@mui/icons-material/Dangerous'
import PublicIcon from '@mui/icons-material/Public'
import Close from '../../common/buttons/Close'
import React, { useEffect, useRef } from 'react'
import useOnClickOutside from '../../utils/useOnClickOutside'
import { useTranslation } from 'react-i18next'

const LogsDetails = ({ handleClose, data }) => {
  const { t } = useTranslation()
  useEffect(() => {
    const el = document.getElementById('logs-details')
    if (data) {
      setTimeout(() => el?.classList.replace('scale-x-0', 'scale-x-1'), 50)
    } else {
      setTimeout(() => el?.classList.replace('scale-x-1', 'scale-x-0'), 50)
    }
  }, [data])

  const ref = useRef({})

  useOnClickOutside(ref, (e) => {
    const el = document.getElementById('logs-details')
    setTimeout(() => el?.classList.replace('scale-x-1', 'scale-x-0'), 50)
    setTimeout(() => handleClose(), 300)
  })

  return (
    <div className="fixed z-50  inset-0 overflow-y-auto cursor-default">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
        &#8203;
        <div
          id="logs-details"
          className="sm:align-middle max-w-full md:max-w-2xl w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 transform ease-out duration-300 scale-x-0"
          ref={ref}
        >
          <div className="bg-white p-4">
            <div className="flex justify-between">
              <div className="flex items-center">
                {data.labels.level == 'info' || data.labels.level == 'notice' ? (
                  <div className="mx-auto flex-shrink-0 flex items-center  justify-center h-12 w-12 rounded-full bg-blue-100/50 sm:mx-0 sm:h-10 sm:w-10">
                    <InfoOutlinedIcon sx={{ fontSize: 30 }} color="primary" />
                  </div>
                ) : data.labels.level == 'error' ||
                  data.labels.level == 'crit' ||
                  data.labels.level == 'warning' ? (
                  <div className="mx-auto flex-shrink-0 flex items-center  justify-center h-12 w-12 rounded-full bg-orange-100/50 sm:mx-0 sm:h-10 sm:w-10">
                    <ErrorOutlineIcon sx={{ fontSize: 30 }} color="warning" />
                  </div>
                ) : data.labels.level == 'emerg' || data.labels.level == 'alert' ? (
                  <div className="mx-auto flex-shrink-0 flex items-center  justify-center h-12 w-12 rounded-full bg-red-100/50 sm:mx-0 sm:h-10 sm:w-10">
                    <DangerousIcon sx={{ fontSize: 30 }} color="error" />
                  </div>
                ) : (
                  ''
                )}
                <h3 className="text-lg leading-6 font-medium text-primary ml-3" id="modal-headline">
                  {t(data.labels.level?.toUpperCase())}
                </h3>
              </div>
              <div>
                <Close handleClose={handleClose} />
              </div>
            </div>
            {data.labels?.timestamp ? (
              <label className="pl-2 text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                {new Date(data.labels.timestamp).toLocaleString('fr-fr')}
              </label>
            ) : (
              <label className="pl-2 text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                {data.date}
              </label>
            )}
          </div>

          <div className="text-center sm:mt-0 p-6 sm:text-left w-full">
            <div className="w-full">
              <p className="text-sm leading-5 text-gray-500 text-wrap break-words">{data?.msg}</p>
            </div>
            <div className="w-full bottom-0 flex justify-center -ml-4 pt-6 space-x-4">
              {data.labels?.ipAddress && (
                <div className="border px-1 rounded-md bg-gray-600 text-white flex space-x-2 justify-center items-center">
                  <PublicIcon fontSize="inherit" />
                  <label className="text-start justify-start flex">{data.labels.ipAddress}</label>
                </div>
              )}
              {data.labels?.tag && (
                <div className="border px-1 rounded-md bg-gray-600 text-white flex space-x-2 justify-center items-center">
                  <LocalOfferIcon fontSize="inherit" />
                  <label className="text-start justify-start flex">
                    {t(data.labels.tag.toUpperCase())}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogsDetails
