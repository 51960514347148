import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import StreamIcon from '@mui/icons-material/Stream'

import GlobalAdminStats from '../components/superAdmin/GlobalAdminStats'
import { getAllCompanies } from '../store/compagnies/getAll'
import Companies from '../components/superAdmin/Companies'

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const SuperAdmin = () => {
  const { t } = useTranslation()
  const companies = useSelector((state) => state.getAllCompanies)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllCompanies())
  }, [])

  return (
    <div className="h-full overflow-auto no-scrollbar">
      <div className="w-full p-5 ">
        <div className="grid grid-cols-4 gap-4 ">
          <GlobalAdminStats
            title={t('TOTAL_COMPANIES')}
            number={companies.data.data?.length}
            description={t('TOTAL_DEVICES_MANAGER')}
            icon={<BusinessCenterIcon sx={{ fontSize: 150 }} />}
          />
          {/* <GlobalAdminStats
            title={t('TOTAL_DEVICES')}
            number={companies.data.data?.length}
            description={t('TOTAL_DEVICES_MANAGER')}
            icon={<LightbulbOutlinedIcon sx={{ fontSize: 150 }} />}
          /> */}
          {/* <GlobalAdminStats
            title={t('TOTAL_COMPANIES')}
            number={companies.data.data?.length}
            description={t('TOTAL_COMPANIES_MANAGER')}
            icon={<StreamIcon sx={{ fontSize: 150 }} />}
          />
          <div className="border border-gray-200 shadow-lg rounded-lg w-full flex relative overflow-hidden">
            <div className="w-full p-3">
              <div className="w-full text-gray-400 text-lg dark:text-white">{t('WARNING')}</div>
              <div className="w-full mt-5 text-gray-300">{t('SUPER_ADMIN_WARNING')}</div>
              <div className="absolute -top-10 -right-8 text-orange-200">
                <ReportGmailerrorredIcon sx={{ fontSize: 150 }} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex mx-5 border border-gray-200 rounded-lg dark:bg-slate-800 overflow-hidden dark:text-gray-200">
        <Companies data={companies} />
      </div>
    </div>
  )
}
export default SuperAdmin
