import { useParams, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { subscribeToUploadProgress, subscribeToDownloadProgress } from '../utils/socket'
import { getOneDistribution, getOneModule } from '../store/distributions/getOne'
import { createDistribution, uploadFile } from '../store/distributions/create'
import { FileConvertSize, IsValidVersion } from '../helpers/string'
import { updateDistribution } from '../store/distributions/update'
import { getAllTypes } from '../store/distributions/getTypes'
import ProgressBar from '../components/spinner/ProgressBar'
import deleteFile from '../store/distributions/deleteFile'
import FileInput from '../components/firmware/FileInput'
import download from '../store/distributions/download'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import Input from '../common/fields/Input'
import { useTranslation } from 'react-i18next'

export default function FirmwareDetails() {
  const { t } = useTranslation()
  const { id } = useParams()
  const updateFirmware = useSelector((state) => state.updateDistribution)
  const createFirmware = useSelector((state) => state.createFirmware)
  const distribution = useSelector((state) => state.distribution)
  const [downloadProgress, setDownloadProgress] = useState(false)
  const types = useSelector((state) => state.softwareTypes)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const prevTypes = useRef()
  const prevData = useRef()
  const [state, setState] = useState({
    error: {},
    typesChoices: [],
    name: '',
    file: null,
    version: '',
    type: '',
    size: '',
    description: '',
    fileName: '',
    sha1: '',
    md5: ''
  })
  useEffect(() => {
    if (id) {
      if (!distribution.data.distribution.id || distribution.data.distribution.id != id) {
        dispatch(getOneDistribution(id))
      } else if (
        distribution.data.distribution.id &&
        distribution.data.distribution.modules[0]?.id
      ) {
        dispatch(getOneModule(distribution.data.distribution.modules[0].id))
      }
      if (distribution.data.distribution.id) {
        setState((prev) => ({
          ...prev,
          name: distribution.data.distribution.name,
          version: distribution.data.distribution.version,
          description: distribution.data.distribution.description,
          type: distribution.data.distribution.type,
          size: null,
          md5: null,
          sha1: null,
          fileName: null
        }))
      }
    }
    if (!types.loading && !types.data.length) {
      dispatch(getAllTypes())
    } else {
      setState((prev) => ({
        ...prev,
        typesChoices: types.data.map((e) => e.name)
      }))
    }
  }, [])
  useEffect(() => {
    if (prevData.current && !distribution.loading) {
      if (distribution.data.distribution.id && !distribution.data.module.id) {
        setState((prev) => ({
          ...prev,
          name: distribution.data.distribution.name,
          version: distribution.data.distribution.version,
          description: distribution.data.distribution.description,
          type: distribution.data.distribution.type,
          size: null,
          md5: null,
          sha1: null,
          fileName: null
        }))
      } else if (distribution.data.distribution.id && distribution.data.module.id) {
        setState((prev) => ({
          ...prev,
          name: distribution.data.distribution.name,
          version: distribution.data.distribution.version,
          description: distribution.data.distribution.description,
          type: distribution.data.distribution.type,
          size: FileConvertSize(distribution.data.module.size),
          md5: distribution.data.module.hashes.md5 || 'N/A',
          sha1: distribution.data.module.hashes.sha1 || 'N/A',
          fileName: distribution.data.module.providedFilename
        }))
      }
    }
    prevData.current = distribution.loading
  }, [distribution.loading])
  useEffect(() => {
    if (prevTypes.current && !types.loading && !types.error) {
      setState((prev) => ({
        ...prev,
        typesChoices: types.data.map((e) => e.name)
      }))
    }
    prevTypes.current = types.loading
  }, [types.loading])

  const checkField = () => {
    const errors = {}
    if (!state.name) {
      errors.name = t('NAME_ERROR')
    }
    if (!state.type) {
      errors.type = t('TYPE_REQUIRED')
    }
    if (!state.version) {
      errors.version = t('VERSION_REQUIRED')
    } else if (!IsValidVersion(state.version)) {
      errors.version = t('VERSION_ERROR')
    }
    if (!state.description) {
      errors.description = t('DESCRIPTION_REQUIRED')
    }
    if (!state.fileName) {
      errors.file = t('FILE_REQUIRED')
    }
    return errors
  }
  const downloadFile = () => {
    subscribeToDownloadProgress(distribution.data.module?.id, (msg) => {
      setProgress(msg)
      setDownloadProgress(true)
    })
    download(
      distribution.data.distribution.modules[0]?.id,
      distribution.data.module?.id,
      state.fileName
    ).then(() => {
      setDownloadProgress(false)
      setProgress(0)
    })
  }
  const onDelete = async () => {
    setLoading(true)
    await deleteFile(
      distribution.data.distribution.modules[0]?.id,
      distribution.data.module?.id
    ).then((res) => {
      if (res) {
        dispatch(getOneDistribution(id))
      }
    })
    setLoading(false)
  }
  const create = () => {
    const newError = checkField()
    if (Object.keys(newError).length) {
      setState((prev) => ({ ...prev, error: newError }))
    } else {
      setState((prev) => ({ ...prev, error: {} }))
      let data = new FormData()
      data.append('file', state.file, state.fileName)
      data.append('name', state.name)
      data.append('description', state.description)
      data.append('version', state.version)
      data.append('type', state.type)
      subscribeToUploadProgress(state.fileName, (msg) => {
        setProgress(msg)
      })
      dispatch(createDistribution(data))
    }
  }
  const update = () => {
    const newError = checkField()
    if (Object.keys(newError).length) {
      setState((prev) => ({ ...prev, error: newError }))
    } else {
      setState((prev) => ({ ...prev, error: {} }))
      dispatch(
        updateDistribution({
          distributionId: distribution.data.distribution.id,
          moduleId: distribution.data.distribution?.modules[0]?.id,
          data: {
            version: state.version,
            name: state.name,
            description: state.description
          }
        })
      )
    }
  }
  const handleFile = (file) => {
    setState((prev) => ({
      ...prev,
      error: { ...prev.error, file: null },
      fileName: file.name,
      file: file,
      size: FileConvertSize(file.size),
      md5: null,
      sha1: null
    }))
    if (id && distribution.data.distribution?.modules[0]?.id) {
      let data = new FormData()
      data.append('file', file, file.name)
      subscribeToUploadProgress(file.name, (msg) => {
        setProgress(msg)
      })
      dispatch(
        uploadFile({
          data,
          moduleId: distribution.data.distribution?.modules[0]?.id,
          distributionId: id
        })
      )
    }
  }

  return (
    <div className=" space-y-8 overflow-auto h-full p-8 no-scrollbar">
      {(createFirmware.loading || downloadProgress) && (
        <ProgressBar
          isDownload={downloadProgress}
          progress={
            !downloadProgress && createFirmware.uploadProgress < 100
              ? createFirmware.uploadProgress
              : progress
          }
          step={!downloadProgress && createFirmware.uploadProgress < 100 ? 1 : 2}
        />
      )}
      {(distribution.loading || updateFirmware.loading || loading) && (
        <div className="absolute w-full h-full z-50 top-0 left-0">
          <Spinner />
        </div>
      )}
      {createFirmware.error || distribution.error ? (
        <StateInfo type="error" description={createFirmware.error || distribution.error} />
      ) : (
        <div className="border rounded-md shadow-md p-8">
          <div className="space-y-12">
            <div className=" border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t('FIRMWARE_DETAILS')}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                {t('FIRMWARE_DETAILS_DESCRIPTION')}
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <Input
                    label={t('NAME')}
                    value={state.name}
                    onChange={(val) =>
                      setState((prev) => ({
                        ...prev,
                        name: val,
                        error: { ...prev.error, name: null }
                      }))
                    }
                    placeholder={t('NAME')}
                    error={state.error.name}
                  />
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="type"
                    className="block lg:text-sm text-lg leading-5 font-medium text-primary dark:text-white"
                  >
                    {t('TYPE')}
                  </label>
                  <div className="relative block">
                    <select
                      name="type"
                      id="type"
                      disabled={!!id}
                      placeholder="Type"
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          type: e.target.value,
                          error: { ...prev.error, type: null }
                        }))
                      }
                      value={state.type}
                      className={`${
                        state.error.type
                          ? 'border-red-600'
                          : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                      } ${
                        id && 'opacity-60'
                      } mt-1  dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none h-10`}
                    >
                      <option value="" />
                      {state.typesChoices.slice(3).map((e, i) => (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                    {state.error.type && (
                      <span className="w-full text-sm text-red-500">{state.error.type}</span>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <Input
                    label={t('VERSION')}
                    value={state.version}
                    onChange={(val) =>
                      setState((prev) => ({
                        ...prev,
                        version: val,
                        error: { ...prev.error, version: null }
                      }))
                    }
                    placeholder={t('VERSION')}
                    error={state.error.version}
                  />
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t('ABOUT')}
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      rows={4}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          description: e.target.value,
                          error: { ...prev.error, description: null }
                        }))
                      }
                      value={state.description}
                      className={`${
                        state.error.description
                          ? 'border-red-600'
                          : 'dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'
                      } mt-1  dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
                    />
                  </div>
                  {state.error.description ? (
                    <span className="w-full text-sm text-red-500">{state.error.description}</span>
                  ) : (
                    <p className="mt-3 text-sm leading-6 text-gray-600">{t('ABOUT_FIRMWARE')}</p>
                  )}
                </div>

                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Firmware
                  </label>
                  <FileInput
                    fileName={state.fileName}
                    md5={state.md5}
                    sha1={state.sha1}
                    size={state.size}
                    error={state.error.file}
                    onDownload={downloadFile}
                    onDelete={onDelete}
                    fileUrl={distribution.data.module?._links?.self?.href}
                    handleFile={handleFile}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              onClick={() => navigate('/firmwares')}
              type="button"
              className="rounded-md px-3 py-2 text-sm font-semibold text-red-500 shadow-sm border border-red-500"
            >
              {t('CLOSE')}
            </button>
            <button
              onClick={() => (id ? update() : create())}
              type="submit"
              className="rounded-md border border-blue-600 bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {id ? t('UPDATE') : t('CREATE')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
