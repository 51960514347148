import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createCompany } from '../../store/compagnies/create'
import { capitalizeFirstLetter } from '../../helpers/string'
import { getAllWidgets } from '../../store/widgets/getAll'
//import CheckBox from '../../common/fields/CheckBox'
import GlobalModal from '../modals/Layout'
import CheckBox from '../../common/fields/CheckBox'

const AddCompany = ({ setShowModalAdd }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [dataCompany, setDataCompany] = useState({ items: [], admin: {} })
  const [choices, setChoices] = useState([])
  const prevData = useRef()
  const widgets = useSelector((state) => state.widgets)
  const [step, setStep] = useState(1)
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!widgets.data.length && !widgets.loading) {
      dispatch(getAllWidgets())
    } else {
      createChoices()
    }
  }, [])
  useEffect(() => {
    if (prevData.current && !widgets.loading && !choices.length) {
      createChoices()
    }
    if (dataCompany.items) dataCompany.items = [1, 2, 3, 4]
    prevData.current = widgets.loading
  }, [widgets.loading])
  const createChoices = () => {
    const list = []
    for (const item of widgets.data) {
      list.push({ value: item.widget_item_id, label: capitalizeFirstLetter(item.name) })
    }
    setChoices(list)
  }
  const forwardStep = () => {
    if (step === 1) {
      if (
        dataCompany.name &&
        Portable_Valide(dataCompany.phone) &&
        Mail_Valide(dataCompany.email)
      ) {
        setError(false)
        setStep(step + 1)
      } else {
        setError(true)
      }
    } else if (step === 2) {
      if (
        Mail_Valide(dataCompany.admin.email) &&
        dataCompany.admin.first_name &&
        dataCompany.admin.last_name
      ) {
        setError(false)
        setStep(step + 1)
      } else {
        setError(true)
      }
    } else {
      setShowModalAdd(false)
      dispatch(createCompany(dataCompany))
    }
  }
  function Portable_Valide(PortableTest) {
    var regex = new RegExp(/^\+?[0-9\s-]+$/)

    if (regex.test(PortableTest)) {
      return true
    } else {
      return false
    }
  }
  function Mail_Valide(EmailTest) {
    var reg = new RegExp(
      '^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$',
      'i'
    )
    if (reg.test(EmailTest)) {
      return true
    } else {
      return false
    }
  }
  return (
    <GlobalModal
      buttons={[
        {
          title: t('CANCEL'),
          action: () => {
            setShowModalAdd(false)
          },
          type: 'secondary'
        },
        {
          title: t('NEXT'),
          action: () => {
            forwardStep()
          },
          type: 'primary'
        }
      ]}
      title={`${t('CREATE')} ${t('COMPANY')}`}
    >
      <div className="px-10 h-96">
        <div className="py-5 relative mx-24">
          <ul
            className={`w-full h-2 ${
              step >= 4 ? 'bg-emerald-500' : 'bg-slate-300'
            } flex justify-between items-center rounded-full transition duration-1000 ease-in-out relative`}
          >
            {step === 2 && <div className="absolute w-1/2 bg-emerald-500 h-full" />}
            {step === 3 && <div className="absolute w-full bg-emerald-500 h-full" />}
            <li
              className={`w-10 ${
                step >= 1 ? 'bg-emerald-500' : 'bg-slate-300'
              } h-10 rounded-full flex items-center justify-center text-white transition duration-300 ease-in-out z-10`}
            >
              1
            </li>
            <li
              className={`w-10 ${
                step >= 2 ? 'bg-emerald-500' : 'bg-slate-300'
              } h-10 rounded-full flex items-center justify-center text-white transition duration-300 ease-in-out right-0 z-10`}
            >
              2
            </li>
            <li
              className={`w-10 ${
                step >= 3 ? 'bg-emerald-500' : 'bg-slate-300'
              } h-10 rounded-full flex items-center justify-center text-white transition duration-300 ease-in-out right-0 z-10`}
            >
              3
            </li>
          </ul>
        </div>
        {step === 1 ? (
          <div>
            <div className="flex items-center w-full justify-center text-lg">
              {t('COMPANY_SETTING')}
            </div>
            <div className="flex justify-center h-12">
              {error && (
                <div className="w-1/2 flex items-center p-3 justify-center border border-red-400 bg-red-50 rounded-lg text-red-500">
                  {t('ERROR_FORM')}
                </div>
              )}
            </div>
            <div className="py-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_NAME')}
              </label>
              <input
                value={dataCompany.name}
                onChange={(e) => {
                  setDataCompany((prev) => ({ ...prev, name: e.target.value }))
                }}
                placeholder="name"
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            <div className="py-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_PHONE')}
              </label>
              <input
                value={dataCompany.phone}
                onChange={(e) => setDataCompany((prev) => ({ ...prev, phone: e.target.value }))}
                placeholder="Phone"
                type="text"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            <div className="py-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_EMAIL')}
              </label>
              <input
                value={dataCompany.email}
                onChange={(e) => setDataCompany((prev) => ({ ...prev, email: e.target.value }))}
                placeholder="Email"
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
          </div>
        ) : step === 2 ? (
          <div>
            <div className="flex items-center w-full justify-center text-lg">
              {t('ADMIN_SETTING')}
            </div>
            <div className="flex justify-center h-12">
              {error && (
                <div className="w-1/2 flex items-center p-3 justify-center border border-red-400 bg-red-50 rounded-lg text-red-500">
                  {t('ERROR_FORM')}
                </div>
              )}
            </div>
            <div className="flex w-full justify-between space-x-5 pt-5">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                  {t('FIRST_NAME')}
                </label>
                <input
                  value={dataCompany.admin.first_name}
                  onChange={(e) =>
                    setDataCompany((prev) => ({
                      ...prev,
                      admin: { ...prev.admin, first_name: e.target.value }
                    }))
                  }
                  placeholder="First Name"
                  type="text"
                  className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
                />
              </div>
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 dark:text-white">
                  {t('LAST_NAME')}
                </label>
                <input
                  value={dataCompany.admin.last_name}
                  onChange={(e) =>
                    setDataCompany((prev) => ({
                      ...prev,
                      admin: { ...prev.admin, last_name: e.target.value }
                    }))
                  }
                  placeholder="Last Name"
                  type="text"
                  className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
                />
              </div>
            </div>
            <div className="py-5">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('EMAIL')}
              </label>
              <input
                value={dataCompany.admin.email ? dataCompany.admin.email : ''}
                onChange={(e) =>
                  setDataCompany((prev) => ({
                    ...prev,
                    admin: { ...prev.admin, email: e.target.value }
                  }))
                }
                placeholder="Admin email"
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center w-full justify-center text-lg">
              {t('WIDGET_SETTING')}
            </div>
            {widgets.data ? (
              <div className="py-2">
                Features coming soon...
                {/*
                  <CheckBox
                    choices={choices}
                    label="Widgets"
                    values={dataCompany.items}
                    onChange={(val) =>
                      setDataCompany((prev) => ({
                        ...prev,
                        items: val
                      }))
                    }
                    disposition="1"
                  />
                */}
              </div>
            ) : (
              <div className="flex justify-center items-center h-full w-full">
                <h1> You have no widget in database</h1>
              </div>
            )}
          </>
        )}
      </div>
    </GlobalModal>
  )
}

export default AddCompany
