import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getAllGroups } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const deleteGroup = createAsyncThunk('groups/delete', async (id, thunkAPI) => {
  const res = await axios(`${basedUrl}/delete/${id}`, 'DELETE')
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getAllGroups())
  return res.data
})

export const resetDelete = createAsyncThunk('groups/delete/reset', async () => {
  return true
})

const groupSlice = createSlice({
  name: 'deleteGroup',
  initialState,
  extraReducers: {
    [deleteGroup.pending]: (state) => {
      return { ...state, loading: true }
    },
    [deleteGroup.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [deleteGroup.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetDelete.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = groupSlice
export default reducer
