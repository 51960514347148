import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import ExtensionIcon from '@mui/icons-material/Extension'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import HistoryIcon from '@mui/icons-material/History'
import LogoutIcon from '@mui/icons-material/Logout'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { logout } from '../../store/currentUser/auth'
import { ROLES } from '../../constant'
import NavLink from './NavLink'
import SetLanguages from '../languages/SetLanguages'

const SideBar = ({ burgerToogle, user }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  //const flag = (i18n.language || window.localStorage.i18nextLng).includes('fr') ? 'fr' : 'en'
  //select default language fr , or select language from i18.language

  return (
    <div
      id="oledcomm-side-bar"
      className={`${
        burgerToogle[0] ? 'flex' : 'hidden'
      } lg:flex w-24 mt-15 flex-col relative h-screen bg-white border-r border-gray-200 dark:bg-slate-700 dark:border-slate-700`}
    >
      <div className=" h-full flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
        <nav className=" flex-1 flex flex-col divide-y overflow-y-auto h-full" aria-label="Sidebar">
          <div>
            <NavLink
              path="/dashboard"
              name="Dashboard"
              label={t('DASHBOARD')}
              icon={<DashboardIcon className="mx-4" />}
              active={true}
              primary={true}
            />
            {!user.data.isSuperAdmin || (user.data.isSuperAdmin && user.data.supervision_mode) ? (
              <>
                <NavLink
                  path="/devices"
                  name="Devices"
                  label={t('DEVICES')}
                  icon={<LightbulbOutlinedIcon className="mx-4" />}
                  primary={true}
                />
                <NavLink
                  path="/tags"
                  name="Tags"
                  label={t('TAGS')}
                  icon={<BookmarkBorderIcon className="mx-4" />}
                  primary={true}
                />
                <NavLink
                  path="/topologicalmap"
                  name="Topologicalmap"
                  label={t('TOPOLOGICAL_MAP')}
                  icon={<LanOutlinedIcon className="mx-4" />}
                  primary={true}
                />
                <NavLink
                  path="/logs"
                  name="Logs"
                  label={t('LOGS')}
                  icon={<HistoryIcon className="mx-4" />}
                  primary={true}
                />
                {(user.data.superiorRole === ROLES.ADMIN ||
                  (user.data.isSuperAdmin && user.data.supervision_mode)) && (
                  <div>
                    <NavLink
                      path="/settings/admin"
                      name="Setting"
                      label={t('SETTING')}
                      icon={<SettingsIcon className="mx-4" />}
                      primary={true}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <NavLink
                  path="/widget"
                  name="Widget"
                  label={t('WIDGETS')}
                  icon={<ExtensionIcon className="mx-4" />}
                  primary={true}
                  disable={true}
                />
                <NavLink
                  path="/firmwares"
                  name="firmwares"
                  label={t('FIRMWARE')}
                  icon={<BrowserUpdatedIcon className="mx-4" />}
                  primary={true}
                />
              </>
            )}
          </div>
          <div className="flex flex-col">
            <SetLanguages position={'absolute -right-32 z-50'} />
            <div
              onClick={() => dispatch(logout())}
              className="hover:bg-gray-100 dark:hover:bg-slate-600  rounded-2xl mx-1 px-3 py-4 mt-2 text-gray-700 dark:text-gray-200 cursor-pointer"
            >
              <div className="flex justify-center ">
                <LogoutIcon />
              </div>
              <div className="text-xs text-center text-slate-700  dark:text-gray-200">
                {t('LOGOUT')}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}
export default SideBar
