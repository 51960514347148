import StateInfo from '../../common/stateInfo/StateInfo'
import Hr from '../../common/stateInfo/HRBadge'
import Details from './LogDetails'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const LogsView = ({ logs, loading, autoRefresh = false }) => {
  const { t } = useTranslation()
  const [logDetails, setLogDetails] = useState(null)

  const isLoading = !autoRefresh && (loading || logs.loading)

  return (
    <div className="h-full overflow-auto bg-slate-800">
      {logDetails && <Details handleClose={() => setLogDetails(null)} data={logDetails} />}
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <label className="text-white text-lg">{t('LOADING')}...</label>
        </div>
      ) : logs.data.length ? (
        logs.data.slice(0, 500).map((el, index) => (
          <div key={index} className="flex w-full dark:hover:bg-slate-700 hover:bg-gray-200 group">
            <div className="py-1 h-full lg:w-8 w-4 border-gray-400 text-center justify-center lg:text-sm text-xs text-gray-400">
              {index + 1}
            </div>
            <Hr className="mx-2" type={el.labels.level} />
            <div onClick={() => setLogDetails(el)} className="w-0 flex-1 flex py-1 ">
              {el?.date ? (
                <label className="pr-2 lg:pr-4 lg:text-sm text-xs text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                  {new Date(el.date).toLocaleString('fr-fr')}
                </label>
              ) : (
                <label className="pr-2 lg:pr-4 lg:text-sm text-xs text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                  {el.date}
                </label>
              )}
              <div className="w-0 flex-1 flex lg:text-sm text-xs justify-between dark:text-white ">
                <p className="text-ellipsis text overflow-hidden whitespace-nowrap text-gray-300 group-hover:text-gray-700 dark:group-hover:text-gray-200">
                  {el.msg}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : logs.error ? (
        <div className="h-full ">
          <StateInfo type="error" description={logs.error} />
        </div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <StateInfo type="warning" description={t('NO_DATA')} />
        </div>
      )}
    </div>
  )
}

export default LogsView
