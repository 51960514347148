/**
 * Returns the appropriate URL based on the service and environment.
 * @param {string} service - The service name ('customer', 'auditing', 'core', 'device').
 * @returns {string} The URL corresponding to the service in the current environment.
 * @throws {Error} If an unexpected service or environment is provided.
 */
export const getCleanUrl = (service) => {
  if (process.env.REACT_APP_ENV === 'development') {
    // Development environment: return URLs from environment variables
    switch (service) {
      case 'customer':
        return process.env.REACT_APP_CUSTOMER_URL
      case 'auditing':
        return process.env.REACT_APP_AUDITING_URL
      case 'core':
        return process.env.REACT_APP_CORE_URL
      case 'device':
        return process.env.REACT_APP_DEVICES_URL
      default:
        console.log('Unexpected service:', service)
    }
  } else if (process.env.REACT_APP_ENV === 'production') {
    // Production environment: return clean URL based on current window location
    let currentUrl = window.location.href
    let url = new URL(currentUrl)
    let cleanUrl = url.protocol + '//' + url.hostname
    return cleanUrl
  } else {
    // Unexpected environment
    console.log('Unexpected environment:', process.env.REACT_APP_ENV)
  }
}
