import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import { useTranslation } from 'react-i18next'
import GlobalModal from './Layout'
import { useState } from 'react'

const LinkModal = ({ handleClose, link }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        setCopied(true)
        // changing back to default state after 2 seconds.
        setTimeout(() => {
          setCopied(false)
        }, 2000)
      },
      (err) => {
        console.log('failed to copy', err.mesage)
      }
    )
  }
  const btnStyle = copied ? 'bg-gray-100' : ''
  return (
    <GlobalModal
      buttons={[{ title: t('CLOSE'), action: handleClose, type: 'secondary' }]}
      title={t('LINK')}
    >
      <div className="p-5">
        <div className="w-full flex justify-end  ">
          <div className="text-center">
            <button
              onClick={copyToClipboard}
              className={btnStyle + ' text-sm border rounded p-2 transition'}
            >
              {copied ? (
                <p className="flex items-center">
                  {t('COPIED')}
                  <AssignmentTurnedInOutlinedIcon fontSize="sm" />
                </p>
              ) : (
                <p className="flex items-center">
                  {t('COPY_DESC')}
                  <ContentPasteOutlinedIcon fontSize="sm" />
                </p>
              )}
            </button>
          </div>
        </div>
        <div className="p-5 w-full break-words border rounded-l-lg">{link}</div>
      </div>
    </GlobalModal>
  )
}

export default LinkModal
