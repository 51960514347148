import { useTranslation } from 'react-i18next'
import Offline from '../../assets/img/offline.png'

const OutService = () => {
  const { t } = useTranslation()
  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="">
              <h1 className="my-2 text-gray-800 font-bold text-2xl">{t('OFFLINE_ERR')}</h1>
              <p className="my-2 text-gray-800">{t('TRY_AGAIN')}</p>
              <button
                onClick={() => window.location.reload()}
                className="sm:w-full lg:w-auto my-2 border rounded-lg py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
              >
                {t('RETRY')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={Offline} />
      </div>
    </div>
  )
}

export default OutService
