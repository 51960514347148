import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/user`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllUsers = createAsyncThunk('users/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll?orderBy=created_at&orderType=1`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const groupSlice = createSlice({
  name: 'getAllUsers',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    }
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllUsers.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [getAllUsers.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    }
  }
})
const { reducer } = groupSlice
const reset = groupSlice.actions.reset
export { reset }
export default reducer
