import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'
import DownloadIcon from '@mui/icons-material/Download'
import { getAllDevices } from '../store/devices/getAll'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached'
import MemoryIcon from '@mui/icons-material/Memory'
import UploadIcon from '@mui/icons-material/Upload'
import Antenne from '../assets/img/antene.png'
import Manager from '../assets/img/logo2.png'
import Graph from 'react-vis-network-graph'
import { useEffect, useState } from 'react'
import Ep from '../assets/img/ep.png'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import {
  initiateSocket,
  subscribeToAllMetrics,
  disconnectSocket,
  sendMessage
} from '../utils/socketAuditing'
import useScreenSize from '../utils/useScreenSize'
import Button from '../common/buttons/Button'
import Explain from '../common/Tooltip/Explain'

const LOCAL_STORAGE_KEY = 'graphNodePositions'
const Topologic = () => {
  const { t } = useTranslation()
  const devices = useSelector((state) => state.getAllDevice)
  const dispatch = useDispatch()
  const [graph, setGraph] = useState({ nodes: [], edges: [] })
  const [metrics, setMetrics] = useState([])
  const [allNodesDOMCoords, setAllNodesDOMCoords] = useState({})
  const [metricsLoading, setMetricsLoading] = useState(true)
  const [show, setShow] = useState({})
  const { height, width } = useScreenSize()

  const [graphToUse, setGraphToUse] = useState({ nodes: [], edges: [] })
  const [showEp, setShowEp] = useState([])
  const [network, setNetwork] = useState(null)
  const [scale, setScale] = useState(1)

  const triggelAllShow = (bool) => {
    setShow((prevState) => {
      let newState = Object.assign({}, prevState)
      for (var prop in newState) {
        newState[prop] = bool
      }
      return newState
    })
  }

  useEffect(() => {
    initiateSocket()

    subscribeToAllMetrics((data) => {
      if (metricsLoading) setMetricsLoading(false)
      setMetrics(data.data)
    })
    sendMessage('join/allMetrics')

    return () => {
      disconnectSocket()
    }
  }, [])
  useEffect(() => {
    if (!devices.loading && !devices.data.count) {
      dispatch(getAllDevices())
    }
  }, [dispatch, devices.loading, devices.data.count])

  useEffect(() => {
    if (!devices.loading && devices.data.count) {
      const savedPositions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
      const rightAlignX = 500
      const leftAlignX = -500
      const verticalSpacing = 200

      const nodes = [
        {
          id: 'manager',
          title: 'Manager',
          shape: 'image',
          name: 'Manager',
          image: Manager,
          x: savedPositions.manager?.x || 0,
          y: savedPositions.manager?.y || 0
        }
      ]

      const showList = {}
      const edges = []

      const numDevices = devices?.data?.count

      const numAbove = Math.floor(numDevices / 2)

      devices?.data?.rows?.forEach((device, index) => {
        let yPosition = 0
        let xPosition = 0
        if (index % 2 === 0) {
          xPosition = leftAlignX
          if (index < numAbove) {
            yPosition = verticalSpacing * index
          } else {
            yPosition = verticalSpacing * (index - numAbove + 1) * -1
          }
        } else {
          xPosition = rightAlignX
          if (index < numAbove) {
            yPosition = verticalSpacing * (index + 1)
          } else {
            yPosition = verticalSpacing * (index - numAbove) * -1
          }
        }
        nodes.push({
          id: device.device_uuid,
          hardware_id: device.device_hardware_id,
          name:
            device?.informations?.[0]?.alias ||
            device?.informations?.[0]?.configs?.network?.hostname,
          title: `<b>${device.device_uuid}</b><hr />`,
          shape: 'image',
          image: Antenne,
          x: savedPositions[device.device_uuid]?.x || xPosition,
          y: savedPositions[device.device_uuid]?.y || yPosition,
          show: show
        })
        edges.push({
          from: 'manager',
          to: device.device_uuid
        })
        showList[device.device_uuid] = false
      })
      setShow(showList)
      setGraph({ nodes, edges })
      setGraphToUse({ nodes, edges })
    }
  }, [devices])

  useEffect(() => {
    if (!metricsLoading) {
      const savedPositions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
      // const savedPositions = {}
      let prevGraph = graph

      const newNodes = [...prevGraph.nodes]
      const newEdges = [...prevGraph.edges]

      const existingEpNodes = new Set(
        newNodes.filter((node) => node.id.includes('-ep-')).map((node) => node.id)
      )

      if (metrics.length > 0) {
        // ;[{ device_uuid: '5c7eca71-a363-94b5-f4dc-82bc31dfddf9' }]
        metrics.forEach((metric) => {
          const ep_list = metric?.values?.ep_list
          if (!ep_list) return
          // const ep_list = [
          //   [1, 2, 3, 4, 5],
          //   [2, 2, 3, 4, 5]
          //   [4, 2, 3, 4, 5],
          //   [5, 2, 3, 4, 5],
          //   [6, 2, 3, 4, 5],
          //   [8, 2, 3, 4, 5],
          //   [7, 2, 3, 4, 5],
          //   [9, 2, 3, 4, 5],
          //   [10, 2, 3, 4, 5],
          //   [11, 2, 3, 4, 5],
          //   [12, 2, 3, 4, 5]
          // ]
          const showList = show
          const alignX = newNodes.filter((node) => node.id === metric.device_uuid)?.[0]?.x
          const verticalSpacing = newNodes.filter((node) => node.id === metric.device_uuid)?.[0]?.y

          ep_list.forEach((ep, index) => {
            const epId = `${metric.device_uuid}-ep-${ep[0]}`

            if (!newNodes.find((node) => node.id === epId)) {
              let yPosition
              let xPosition

              if (alignX < 0) {
                xPosition = alignX - ep_list.length * 100
              } else {
                xPosition = alignX + ep_list.length * 100
              }
              if (index % 2 === 0) {
                yPosition = verticalSpacing - (200 * index) / 2
              } else {
                yPosition = verticalSpacing + (200 * index) / 2
              }

              newNodes.push({
                id: epId,
                shape: 'image',
                image: Ep,
                epMac: ep[0],
                hostMac: ep[1],
                vlanId: ep[2],
                authMode: ep[3],
                hostAuth: ep[4],
                // x: xPosition,
                // y: yPosition
                x: savedPositions[epId]?.x || xPosition,
                y: savedPositions[epId]?.y || yPosition
                // x: savedPositions[epId]?.x || 700,
                // y: savedPositions[epId]?.y || 200 * (index + 1)
              })
              newEdges.push({
                from: metric.device_uuid,
                to: epId
              })
              showList[epId] = false
            } else {
              let nodeindex = newNodes.findIndex((node) => node.id === epId)
              newNodes[nodeindex].epMac = ep[0]
              newNodes[nodeindex].hostMac = ep[1]
              newNodes[nodeindex].vlanId = ep[2]
              newNodes[nodeindex].authMode = ep[3]
              newNodes[nodeindex].hostAuth = ep[4]
            }
            existingEpNodes.delete(epId)
          })

          setShow(showList)
        })
      }

      const updatedNodes = newNodes.filter((node) => !existingEpNodes.has(node.id))
      const updatedEdges = newEdges.filter((edge) => !existingEpNodes.has(edge.to))

      setGraph({
        nodes: updatedNodes,
        edges: updatedEdges
      })

      if (showEp.length === 0) {
        setGraphToUse({
          nodes: updatedNodes,
          edges: updatedEdges
        })
      } else {
        let nodes = updatedNodes
        let edges = updatedEdges
        showEp.forEach((ep) => {
          const epId = `${ep}-ep-`
          nodes = nodes?.filter((node) => !node?.id?.includes(epId))
          edges = edges?.filter((edge) => !edge?.from?.includes(epId))
        })
        setGraphToUse({ nodes: nodes, edges: edges })
      }
    }
  }, [metricsLoading, metrics, showEp])

  const handleNodeMove = (event) => {
    if (event.nodes.length === 0) return

    const newPositions = event.nodes.reduce((acc, nodeId) => {
      acc[nodeId] = {
        x: event.pointer.canvas.x,
        y: event.pointer.canvas.y
      }
      return acc
    }, {})
    const savedPositions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}

    const updatedPositions = {
      ...savedPositions,
      ...newPositions
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedPositions))
  }

  const options = {
    layout: {
      hierarchical: false
    },
    interaction: {
      hover: true,
      zoomView: true
    },
    nodes: {
      borderWidth: 2
    },
    edges: {
      color: '#4287f5'
    },
    height: height + 'px',
    width: width + 'px',
    physics: {
      enabled: false
    }
  }

  return (
    <div className="h-full w-full relative overflow-clip">
      <Graph
        graph={graphToUse}
        options={options}
        getNetwork={(network) => {
          setNetwork(network)
          network.on('afterDrawing', () => {
            let allNodesDOMCoords = {}
            Object.keys(network.body.nodes).forEach((nodeId) => {
              let node = network.body.nodes[nodeId]
              let canvasCoords = { x: node.x, y: node.y }
              let domCoords = network.canvasToDOM(canvasCoords)
              allNodesDOMCoords[nodeId] = domCoords
            })
            setAllNodesDOMCoords(allNodesDOMCoords)
          })

          network.on('dragEnd', (event) => {
            if (event.nodes.length == 0) {
              network.canvas.body.container.style.cursor = 'grab'
            } else {
              network.canvas.body.container.style.cursor = 'pointer'
            }
            handleNodeMove(event)
          })
          network.on('doubleClick', (properties) => {
            var uuid = properties.nodes[0]
            // if (uuid && network.body.data.nodes.get(uuid).hardware_id) {
            //   window.location.href = `/device/${network.body.data.nodes.get(uuid).hardware_id}`
            // }

            setShowEp((prev) => {
              let newState = [...prev]
              if (prev?.includes(uuid)) {
                newState = newState.filter((ep) => ep !== uuid)
              } else {
                newState.push(uuid)
              }
              return newState
            })
          })

          network.on('hold', (properties) => {
            var uuid = properties.nodes[0]
            if (uuid && network.body.data.nodes.get(uuid).hardware_id) {
              window.location.href = `/device/${network.body.data.nodes.get(uuid).hardware_id}`
            }
          })

          network.on('hoverNode', (properties) => {
            network.canvas.body.container.style.cursor = 'pointer'
          })

          network.on('blurNode', (properties) => {
            network.canvas.body.container.style.cursor = 'grab'
          })
          network.on('dragStart', (properties) => {
            network.canvas.body.container.style.cursor = 'move'
          })
        }}
      />
      {Object.keys(allNodesDOMCoords).map((nodeId) => {
        const { x, y } = allNodesDOMCoords[nodeId]
        const metric = metrics?.find((metric) => metric.device_uuid === nodeId)
        const device = devices?.data?.rows?.find((device) => device.device_uuid === nodeId)
        const ep = graph.nodes.find((node) => node.id === nodeId)
        return nodeId == 'manager' ? (
          <div
            key={nodeId}
            style={{
              position: 'absolute',
              left: `${x}px`,
              top: `${y + 50}px`,
              transform: `translate(-50%, -50%)`
            }}
            className="bg-blue-50 rounded-md font-medium p-1"
          >
            {graph.nodes.find((node) => node.id === nodeId)?.name}
          </div>
        ) : nodeId.includes('ep') ? (
          <div
            key={nodeId}
            style={{
              position: 'absolute',
              left: `${x - 125}px`,
              top: `${y + 20}px`,
              width: '270px'
            }}
            className="bg-blue-50 rounded-md p-1"
            onClick={() => {
              setShow((prev) => ({ ...prev, [nodeId]: !show[nodeId] }))
            }}
          >
            <div className="flex items-center space-x-2 justify-between">
              <div className="flex items-center">
                <OnlinePredictionIcon className="text-purple-500" />
                Mac:
              </div>
              <p>{ep?.epMac || 'NA'}</p>
            </div>
            {show[nodeId] && (
              <>
                <div className="flex items-center space-x-2 justify-between">
                  <div className="flex items-center">
                    <LaptopMacIcon className="text-blue-500" />
                    {t('HOST_MAC')}:
                  </div>
                  <p>{ep?.hostMac || 'NA'}</p>
                </div>

                <div className="flex items-center space-x-2 justify-between">
                  <div className="flex items-center">
                    <GppGoodOutlinedIcon className="text-green-700" />
                    {t('AUTH_MODE')}:
                  </div>
                  <p>
                    {ep?.authMode === '0'
                      ? 'No auth'
                      : ep?.authMode === '1'
                      ? 'PSK'
                      : ep?.authMode === '2'
                      ? 'EAP'
                      : 'NA'}
                  </p>
                </div>
                <div className="flex items-center space-x-2 justify-between">
                  <div className="flex items-center">
                    <LanOutlinedIcon className="text-blue-500" />
                    {t('VLAN_ID')}:
                  </div>
                  <p>{ep?.vlanId || 'NA'}</p>
                </div>
                <div className="flex items-center space-x-2 justify-between">
                  <div className="flex items-center">
                    <AdminPanelSettingsOutlinedIcon className="text-green-700" />
                    {t('HOST_AUTH')}:
                  </div>
                  <p>{ep?.hostAuth === '0' ? 'No auth' : ep?.hostAuth === '1' ? '802.1X' : 'NA'}</p>
                </div>
              </>
            )}
            {show[nodeId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        ) : (
          <div
            key={nodeId}
            style={{
              position: 'absolute',
              left: `${x - 100}px`,
              top: `${y + 30}px`,
              width: '220px'
            }}
            className="bg-blue-50 rounded-md p-1"
            onClick={() => {
              setShow((prev) => ({ ...prev, [nodeId]: !show[nodeId] }))
            }}
          >
            <div>
              <div className="flex space-x-4 justify-between">
                <p className="font-medium">
                  {device?.informations?.[0]?.alias ||
                    device?.informations?.[0]?.configs.network.hostname ||
                    'N/A'}
                </p>
                <div className="flex items-center">
                  {!metric ? (
                    <div
                      title="Modem non accessible ou hors tension"
                      className="bg-red-500 p-2 rounded-full"
                    />
                  ) : metric.values.tx_mbps > 0 || metric.values.rx_mbps > 0 ? (
                    <div
                      title="Modem connecté au réseau et en LiFi"
                      className="bg-green-600 p-2 rounded-full"
                    />
                  ) : (
                    <div
                      title="Modem connecté au réseau mais non connecté en LiFi"
                      className="bg-orange-400 p-2 rounded-full"
                    />
                  )}
                </div>
              </div>
            </div>
            {metricsLoading ? (
              <div className="flex items-center justify-center animate-spin">
                <CachedIcon />
              </div>
            ) : metric ? (
              <div>
                {show[nodeId] && (
                  <>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <MemoryIcon className="text-blue-500" />
                        CPU:
                      </div>
                      <p>{metric.values.cpu}%</p>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <DeveloperBoardIcon className="text-green-700" />
                        RAM:
                      </div>
                      <p>{metric.values.ram}%</p>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <DeviceThermostatIcon className="text-red-500" />
                        {t('TEMPERATURE')}:
                      </div>
                      <p>{metric.values.temperature}°C</p>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <FolderOutlinedIcon className="text-orange-600" />
                        {t('STORAGE')}:
                      </div>
                      <p>{metric.values.storage}%</p>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <DownloadIcon className="text-purple-500" />
                        {t('DOWNLOAD_SPEED')}:
                      </div>
                      <p>
                        {Math.round(metric.values.rx_mbps)} <span className="text-xs">Mb/s</span>
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center">
                        <UploadIcon className="text-blue-500" />
                        {t('UPLOAD')}:
                      </div>
                      <p>
                        {Math.round(metric.values.tx_mbps)} <span className="text-xs">Mb/s</span>
                      </p>
                    </div>
                  </>
                )}
                {show[nodeId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      })}

      <div className="absolute bottom-12 left-12 flex flex-col space-y-2">
        <Button title={t('OPEN_EVERYTHING')} onClick={() => triggelAllShow(true)} />
        <Button title={t('CLOSE_EVERYTHING')} onClick={() => triggelAllShow(false)} />
        <Button
          title={t('CENTER')}
          onClick={() => {
            var options = {
              scale: 0.8,
              animation: {
                duration: 1000,
                easingFunction: 'easeInOutQuad'
              },
              position: {
                x: 0,
                y: 0
              }
            }
            network.moveTo(options)
          }}
        />
      </div>
      <div className="absolute top-12 left-12 text-gray-700 text-4xl">
        <Explain width={'w-128'} description={t('DOUBLE_CLICK')} description2={t('HOLD_CLICK')} />
      </div>
    </div>
  )
}

export default Topologic
