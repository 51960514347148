import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'

import { updateTag as update, resetUpdate } from '../../store/tags/change'
import { createTag as create, resetCreate } from '../../store/tags/create'
import ColorPicker from '../../common/fields/ColorPicker'
import Success from '../../common/stateInfo/StateInfo'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'
import GlobalModal from './Layout'

const TagModal = ({ handleClose, row, parentId }) => {
  const { t } = useTranslation()
  const createTag = useSelector((state) => state.createTag)
  const updateTag = useSelector((state) => state.changeTag)
  const [alertType, setAlertType] = useState(null)
  const [error, setError] = useState(null)
  const [name, setName] = useState(row ? row.name : '')
  const [color, setColor] = useState(row ? row.color : 'bg-red-900')
  const [showAlert, setShowAlert] = useState(false)
  const dispatch = useDispatch()
  const prevCreateTag = useRef()
  const prevUpdateTag = useRef()
  useEffect(() => {
    if (prevCreateTag.current && !createTag.loading) {
      if (createTag.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetCreate())
      if (!createTag.error) {
        setTimeout(() => {
          handleClose()
        }, 500)
      }
    }
    prevCreateTag.current = createTag.loading
  }, [createTag])

  useEffect(() => {
    if (prevUpdateTag.current && !updateTag.loading && updateTag.data) {
      if (updateTag.error) {
        setAlertType('error')
      } else {
        setAlertType('success')
      }
      setShowAlert(true)
      dispatch(resetUpdate())
      if (!createTag.error) {
        setTimeout(() => {
          handleClose()
        }, 700)
      }
    }
    prevUpdateTag.current = updateTag.loading
  }, [updateTag])

  const handleSubmit = () => {
    if (!name) {
      setError(t('TAG_NAME_ERROR'))
    } else {
      setError(null)
      if (row) {
        // update
        dispatch(update({ parent_ids: row.parent_ids, name, color, id: row.device_tag_id }))
      } else {
        dispatch(create({ parent_ids: parentId ? [parentId] : null, name, color }))
      }
    }
  }

  return (
    <GlobalModal
      title={!row ? `${t('CREATE')} ${t('TAG')}` : `${t('EDIT')} ${t('TAG')}`}
      size="small"
      buttons={[
        !showAlert
          ? {
              title: row ? t('EDIT') : t('CREATE'),
              action: () => handleSubmit()
            }
          : {},
        {
          type: 'secondary',
          title: t('CLOSE'),
          action: handleClose
        }
      ]}
    >
      {showAlert ? (
        <Success type={alertType} />
      ) : (
        <div className="relative p-6 flex-auto">
          <form className="w-full" onSubmit={(e) => e.preventDefault()}>
            <div className="mb-2 w-full">
              <Input
                label={`${t('NAME')} ${t('TAG')}`}
                value={name}
                onChange={setName}
                placeholder="name"
                error={error}
              />
            </div>
            <div className="mb-2 w-full">
              <ColorPicker value={color} onChange={setColor} />
            </div>
          </form>
        </div>
      )}
    </GlobalModal>
  )
}

export default TagModal
