import io from 'socket.io-client'
import { getCleanUrl } from './urlManip'

let socket
let timerId

export const initiateSocket = () => {
  socket = io(getCleanUrl('auditing'), {
    path: '/api/audit/socket.io',
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 10,
    auth: {
      token: localStorage.getItem('token')
    }
  })
}

export const subscribeToDeviceMetrics = (uuid, cb) => {
  if (!socket) return true
  socket.on(`device/metrics/${uuid}`, (msg) => {
    return cb(msg)
  })
}
export const subscribeToAllMetrics = (cb) => {
  if (!socket) return true
  socket.on(`device/allMetrics`, (msg) => {
    return cb(msg)
  })
}

export const sendMessage = (room, message) => {
  if (!socket || !socket.connected) {
    timerId = setTimeout(() => sendMessage(room, message), 500)
    return true
  }
  clearTimeout(timerId)
  socket.emit(room, message)
}

export const disconnectSocket = () => {
  if (socket) socket.disconnect()
}
