import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllDeviceGroups } from './getAll'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const createDeviceGroup = createAsyncThunk('group/create', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/create`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllDeviceGroups())
  return res.data
})

export const resetCreate = createAsyncThunk('deviceGroup/reset', async () => {
  return true
})

const deviceGroupSlice = createSlice({
  name: 'createDeviceGroup',
  initialState,
  extraReducers: {
    [createDeviceGroup.pending]: (state) => {
      return { ...state, loading: true }
    },
    [createDeviceGroup.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [createDeviceGroup.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetCreate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = deviceGroupSlice
export default reducer
