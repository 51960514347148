import { useTranslation } from 'react-i18next'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import '../../assets/css/Animation.css'

const StateInfo = (props) => {
  const { t } = useTranslation()
  return (
    <div className="">
      {props.type === 'success' ? (
        <div className="grid grid-cols-1 mt-3 text-green-700">
          <div className="success-animation mb-3 mx-auto">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle
                className="checkmark__circle stroke-green-700"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check stroke-green-700"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
          <div className="mx-auto text-xl">
            <b>{props.title || t('SUCCESS')}</b>
          </div>
          <div className="mx-auto text-md my-3">
            {props.description || t('OPERATION_SUCCESSFUL')}
          </div>
        </div>
      ) : props.type === 'error' ? (
        <div className="grid grid-cols-1 mt-3 text-red-700">
          <div className="error-animation mb-3 mx-auto">
            <svg className="checkmark " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle
                className="checkmark__circle stroke-red-700"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check stroke-red-700"
                fill="none"
                d="M 13,13 l 26,26 M 40,13 l -26,26"
              />
            </svg>
          </div>
          <div className="mx-auto text-xl">
            <b>{props.title || t('ERROR')}</b>
          </div>
          <div className="mx-auto text-md my-3">{props.description || t('OOPS')}</div>
        </div>
      ) : props.type === 'warning' ? (
        <div className="grid grid-cols-1 mt-3 text-orange-400">
          <div className="error-animation mb-3 mx-auto ">
            <div slot="avatar">
              <svg className="checkmark " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle
                  className="checkmark__circle stroke-orange-400"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check stroke-orange-400"
                  fill="none"
                  d="M 26,10 l 0,23 "
                />
                <circle
                  className="checkmark__circle stroke-orange-400"
                  cx="26"
                  cy="40"
                  r="2"
                  fill="none"
                />
              </svg>
            </div>
          </div>
          <div className="mx-auto text-xl">
            <b>{props.title || t('WARNING')}</b>
          </div>
          <div className="mx-auto text-md my-3">{props.description || t('NO_DATA')}</div>
        </div>
      ) : (
        <div className="grid grid-cols-1 mt-3 text-red-700">
          <div className="error-animation mb-3 mx-auto ">
            <div className="mx-auto rounded-full bg-red-100 p-4 dark:bg-red-900/50 my-3">
              <DeleteForeverIcon className="text-red-400 text-7xl" fontSize="" />
            </div>
          </div>
          <div className="mx-auto text-xl">
            <b>{props.title || t('WARNING')}</b>
          </div>
          <div className="mx-auto text-md my-3">{props.description || t('NO_DATA')}</div>
        </div>
      )}
    </div>
  )
}

export default StateInfo
