import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import { toBeValidated } from '../../store/devices/toBeValidated'
import SettingsIcon from '@mui/icons-material/Settings'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import GlobalModal from './Layout'

const DeviceGroupModal = ({ handleClose, data }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toBeValidatedState = useSelector((state) => state.toBeValidated)
  const handleSubmit = () => {
    dispatch(
      toBeValidated({
        device_uuid: data.device_uuid,
        action: data.to_be_validated
      })
    ).then((res) => {
      if (res.payload) {
        handleClose()
      }
    })
  }

  return (
    <GlobalModal
      size="small"
      buttons={[
        {
          type: 'secondary',
          title: t('CANCEL'),
          action: handleClose
        },
        {
          title: t('CONFIRM'),
          action: () => {
            handleSubmit()
          }
        }
      ]}
    >
      <div className="p-2 space-y-4">
        <div className="flex items-center space-x-4">
          <div className="p-2 bg-orange-100 rounded-full">
            <ReportGmailerrorredIcon color="warning" fontSize="large" />
          </div>
          <div>
            <p className="text-lg font-medium">
              {data.to_be_validated === '1004' && t('ACCEPT_NEW_DEVICE')}
            </p>
          </div>
        </div>
        {toBeValidatedState.loading ? (
          <div className="p-4">
            <div className="w-full h-full flex justify-between relative items-center">
              <div className="relative space-y-1">
                <div className="w-20 h-4 bg-gray-300 rounded-md" />
                <div className="w-20 h-4 bg-gray-300 rounded-md" />
                <div className="w-20 h-4 bg-gray-300 rounded-md" />
                <SettingsIcon
                  className="absolute text-gray-400 top-6 -left-6 animate-spin "
                  style={{ fontSize: 55 }}
                />
              </div>
              <div className="w-24 h-8 rounded-full bg-slate-400 border-gray-200 border-t-[12px]" />
              <div className="absolute w-full bg-gray-200 h-2 top-6 -z-20" />
              <div
                id="data-hor-1"
                className="absolute h-2 w-6 top-6 bg-green-600 -z-10 rounded-xl "
              />
              <div
                id="data-hor-2"
                className="absolute h-2 w-6 top-6 bg-green-600 -z-10 rounded-xl"
              />
              <div
                id="data-hor-3"
                className="absolute h-2 w-6 top-6 bg-green-600 -z-10 rounded-xl"
              />
            </div>
          </div>
        ) : (
          <div className="px-4">
            <p className="text-sm leading-5 text-gray-500">
              {t('DEVICE')} {data.device_uuid} {t('SENT_INFORM')}
              {data.to_be_validated === '1001' && t('REQUEST_INFORM_1001')}
              {data.to_be_validated === '1002' && t('REQUEST_INFORM_1002')}
              {data.to_be_validated === '1003' && t('REQUEST_INFORM_1003')}
              {data.to_be_validated === '1004' && t('REQUEST_INFORM_1004')}
            </p>
          </div>
        )}
      </div>
    </GlobalModal>
  )
}

export default DeviceGroupModal
