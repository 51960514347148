import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getAllTags } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/tag`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const createTag = createAsyncThunk('tag/create', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/create`, 'POST', data)
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllTags())
  return res.data
})

export const resetCreate = createAsyncThunk('tags/reset', async () => {
  return true
})

const tagSlice = createSlice({
  name: 'createTag',
  initialState,
  extraReducers: {
    [createTag.pending]: (state) => {
      return { ...state, loading: true }
    },
    [createTag.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [createTag.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetCreate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = tagSlice
export default reducer
