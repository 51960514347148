import getDeviceDebits from '../../store/devices/deviceDebits'
import { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import '../../assets/css/Animation.css'

const DeviceDebitsChart = ({ uuid }) => {
  const [debits, setDebits] = useState([])
  useEffect(() => {
    fetchDeviceDebits(uuid)
  }, [])
  const fetchDeviceDebits = async (id) => {
    const res = await getDeviceDebits(id)
    setDebits(res)
  }
  const labels = []
  const rx = []
  const tx = []
  debits.length &&
    debits.map((el) => {
      const startDate = new Date((el.date - 86400) * 1000)
      labels.push(`${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`)
      rx.push(el.rx)
      tx.push(el.tx)
    })
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
  const data = {
    labels,
    datasets: [
      {
        label: 'Download (Mb/s)',
        data: rx,
        backgroundColor: 'rgba(140, 20, 252, 0.7)'
      },
      {
        label: 'Upload (Mb/s)',
        data: tx,
        backgroundColor: 'rgba(53, 162, 235, 0.7)'
      }
    ]
  }
  return (
    <>
      {debits.length ? (
        <Bar options={options} className="h-full w-full" data={data} />
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <div className=" relative h-60 ">
            <div className="absolute w-20 h-10 bg-white z-20 rounded-b-lg" />
            <div className="w-20 h-6 rounded-full bg-slate-400 border-gray-200 border-t-8 absolute z-20 top-5" />
            <div className="mx-auto w-full h-0 border-solid border-b-yellow-100/50 border-b-[225px] border-x-transparent border-x-[25px] border-t-0 absolute z-4" />
            <div className="flex space-x-4 h-full">
              <div id="data-3" className="w-2 h-8 rounded-full bg-cyan-200 z-10" />
              <div id="data-1" className="w-2 h-8 rounded-full bg-cyan-200 z-10" />
              <div id="data-2" className="w-2 h-8 rounded-full bg-cyan-200 z-10" />
              <div id="data-4" className="w-2 h-8 rounded-full bg-cyan-200 z-10" />
            </div>
            <div className="absolute w-20 z-5 rounded-b-lg bottom-0 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="80px"
                viewBox="0 0 576 512"
                className=""
              >
                <path
                  fill="#e5e7eb"
                  d="M88.7 223.8L0 375.8V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224H544c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default DeviceDebitsChart
