import DistributionRow from './rows/DistributionRow'
import NoData from '../../common/stateInfo/NoData'
import DeviceGroupRow from './rows/DeviceGroupRow'
import Schema from '../../data/tableSchema.json'
import { useTranslation } from 'react-i18next'
import Spinner from '../spinner/Spinner'
import DeviceRow from './rows/DeviceRow'
import WidgetRow from './rows/WidgetRow'
import UpdateRow from './rows/UpdateRow'
import ConfigRow from './rows/ConfigRow'
import GroupRow from './rows/GroupRow'
import UserRow from './rows/UserRow'
import TagRow from './rows/TagRow'
import Header from './Header'
import Footer from './Footer'

const Table = ({
  type,
  data,
  currentPage,
  handlePagination,
  isLoading,
  total,
  headerActions,
  onDelete,
  onLink,
  onEdit
}) => {
  //   const [status, setStatus] = useState({})
  //   useEffect(() => {
  //     if (type === 'devices') {
  //       subscribeToDeviceStatus((data) => {
  //         setStatus(data)
  //       })
  //       sendMessage('join', () => null)
  //     }
  //     return () => sendMessage('leave', () => null)
  //   }, [])
  const { t } = useTranslation()
  const rowView = (row, index) => {
    switch (type) {
      case 'devices':
        return (
          <DeviceRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )
      case 'groups':
        return (
          <GroupRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )
      case 'users':
        return (
          <UserRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onDelete={onDelete}
            onEdit={onEdit}
            onLink={onLink}
          />
        )
      case 'deviceGroup':
        return (
          <DeviceGroupRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )

      case 'updates':
        return <UpdateRow key={index} row={row} cols={Schema[type].cols} onDelete={onDelete} />
      case 'widgets':
        return (
          <WidgetRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )
      case 'tags':
        return <TagRow key={index} row={row} cols={Schema[type].cols} onDelete={onDelete} />
      case 'config':
        return (
          <ConfigRow
            key={index}
            row={row}
            cols={Schema[type].cols}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )
      case 'distributions':
        return <DistributionRow key={index} row={row} cols={Schema[type].cols} />
      default:
        return null
    }
  }

  return (
    <div className="w-full">
      {isLoading && (
        <div className="absolute w-full h-full z-50">
          <Spinner version={1} isContainer />
        </div>
      )}
      <Header title={Schema[type].title} headerActions={headerActions} />
      {data && data.length ? (
        <>
          <div className="overflow-auto md:block">
            <table className="w-full">
              <thead className="border-b bg-gray-100 text-gray-900">
                <tr className="">
                  {Object.keys(Schema[type].cols).map((el, index) => (
                    <th key={index} scope="col" className="text-sm font-medium p-5 text-left">
                      {t(Schema[type].cols[el].label.toUpperCase())}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="">{data.map((row, index) => rowView(row, index))}</tbody>
            </table>
          </div>
          <Footer
            perPage={data.length}
            currentPage={currentPage}
            total={total}
            handlePageChange={handlePagination}
          />
        </>
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default Table
