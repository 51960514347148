import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { updateOne } from './getAll'
import { update } from './getOne'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/provisioning`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const acceptDevice = createAsyncThunk('device/acceptDevice', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/acceptDevice`, 'POST', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  const user = thunkAPI.getState().currentUser
  thunkAPI.dispatch(update({ data: res.data, user }))
  thunkAPI.dispatch(updateOne({ data: res.data, user }))
  return res.data
})

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  extraReducers: {
    [acceptDevice.pending]: (state) => {
      return { ...state, loading: true }
    },
    [acceptDevice.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [acceptDevice.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})
const { reducer } = deviceSlice
export default reducer
