import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from '../../utils/useOnClickOutside'
import { setDefaultLocale, registerLocale } from 'react-datepicker'
import { es, fr, de } from 'date-fns/locale'

const SetLanguages = ({ position }) => {
  const { t, i18n } = useTranslation()
  const languages = ['en', 'fr', 'es', 'de']
  const ref = useRef()
  const ref1 = useRef()
  const flag = languages.includes(i18n.language) ? i18n.language : 'fr'

  const [showLang, setShowLang] = useState(false)
  useOnClickOutside(ref, (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setShowLang(false)
    }
  })

  registerLocale('es', es)
  registerLocale('de', de)
  registerLocale('fr', fr)

  return (
    <>
      <div
        onClick={() => {
          console.log(showLang)
          setShowLang(!showLang)
        }}
        ref={ref1}
        className="hover:bg-gray-100 dark:hover:bg-slate-600 rounded-2xl mx-1 px-3 py-4 mt-2 text-gray-700 flex justify-center  dark:text-gray-200 cursor-pointer"
      >
        <button
          id="multiLevelDropdownButton"
          className={`text-white bg-${flag}-flag p-4 bg-cover rounded-full text-sm  border shadow-md `}
          type="button"
          aria-label={t('CHANGE_LANGUAGE')}
        />
      </div>
      {showLang && (
        <div className={position} ref={ref}>
          <div className="bg-white w-32 h-32 border border-gray-200 p-2 rounded-r-lg">
            <div className="grid grid-cols-2 gap-2 h-full grid-rows-2">
              <div
                onClick={() => {
                  i18n.changeLanguage('fr')
                  setDefaultLocale('fr')
                  setShowLang(false)
                }}
                className={`text-white bg-fr-flag bg-cover m-1 rounded-full border-4 hover:shadow-lg border-gray-300 cursor-pointer`}
              />
              <div
                onClick={() => {
                  i18n.changeLanguage('en')
                  setDefaultLocale('en')
                  setShowLang(false)
                }}
                className={`text-white bg-en-flag bg-cover m-1 rounded-full border-4 border-gray-300 hover:shadow-lg cursor-pointer`}
              />
              <div
                onClick={() => {
                  i18n.changeLanguage('es')
                  setDefaultLocale('es')
                  setShowLang(false)
                }}
                className={`text-white bg-es-flag bg-cover m-1 rounded-full border-4 border-gray-300 hover:shadow-lg cursor-pointer`}
              />
              <div
                onClick={() => {
                  i18n.changeLanguage('de')
                  setDefaultLocale('de')
                  setShowLang(false)
                }}
                className={`text-white bg-de-flag bg-cover m-1 rounded-full border-4 border-gray-300 hover:shadow-lg cursor-pointer`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SetLanguages
