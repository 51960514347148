import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import axios from '../common/customAxios'
import { getAllWidgets } from './getAll'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('core')}/api/core/widgetItem`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const editWidget = createAsyncThunk('widget/edit', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update/${data.id}`, 'PUT', data)
  if (res.status === 'error') {
    thunkAPI.dispatch(error(res.message))
    return thunkAPI.rejectWithValue(res.message)
  }
  thunkAPI.dispatch(success())
  thunkAPI.dispatch(getAllWidgets())
  return res.data
})

export const resetUpdate = createAsyncThunk('tags/reset', async () => {
  return true
})

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [editWidget.pending]: (state) => {
      return { ...state, loading: true }
    },
    [editWidget.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [editWidget.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    },
    [resetUpdate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})

const { reducer } = widgetSlice
export default reducer
