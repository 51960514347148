import MultiSelect from '../../common/fields/MutliSelect'
import { FileConvertSize } from '../../helpers/string'
import DownloadIcon from '@mui/icons-material/Download'
import Button from '../../common/buttons/Button'
import Filter from '../../common/fields/Filter'
import { useTranslation } from 'react-i18next'
import Input from '../../common/fields/Input'
import { useState } from 'react'

const DeviceInfo = ({ data, handleChange, groupsChoice, tags, exportConfig, error, changes }) => {
  const { t } = useTranslation()
  const [configFile, setConfigFile] = useState({ file: null, fileName: '', size: null })
  const [uploadError, setUploadError] = useState('')

  const handleFile = (file, set) => {
    if (file) {
      set({
        fileName: file.name,
        file: file,
        size: file.size
      })

      handleUpload(file)
    }
  }
  const handleUpload = (file) => {
    if (!file) {
      setUploadError(t('CERTIFICATE_FILE_REQUIRED'))
    }
    if (file) {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = (evt) => {
        const fileContent = JSON.parse(evt.target.result)
        const { network, security, tags, alias } = fileContent
        Object.entries(network).forEach(([key, value]) => {
          if (!value) return
          handleChange(key, value)
        })
        Object.entries(security.lpa).forEach(([key, value]) => {
          handleChange(key, value)
        })
        handleChange('tags', tags)
        handleChange('alias', alias)
      }
    }
  }
  return (
    <div className="flex flex-col space-y-4 p-4">
      <Input
        label={`${t('ALIAS')} ${changes['alias'] ? '●' : ''}`}
        placeholder={t('ALIAS')}
        value={data.alias}
        onChange={(v) => handleChange('alias', v)}
        error={error.alias}
      />
      <MultiSelect
        label={t('GROUPS')}
        onChange={(v) => handleChange('groups', v)}
        choices={groupsChoice}
        values={data.groups || []}
      />
      <Filter
        label={t('TAGS')}
        editable
        value={data.tags}
        onChange={(v) => handleChange('tags', v)}
        rows={tags}
        style={'add'}
      />
      <div>
        <p className="text-sm font-medium py-1">{t('SELECT_CONFIG_FILE')} </p>
        <div className="flex flex-row items-center">
          <input
            type="file"
            id="ca"
            onChange={(e) => {
              handleFile(e.target.files[0], setConfigFile)
              setUploadError('')
            }}
            hidden
          />
          <label
            htmlFor="ca"
            className="block text-sm py-2 px-4 whitespace-nowrap rounded-l-md border-0 font-semibold bg-blue-50 text-blue-700 hover:bg-blue-100 cursor-pointer"
          >
            {t('CHOOSE_FILE')}
          </label>
          <label
            className={`text-sm p-2 text-slate-500 border ${
              uploadError ? 'border-red-600' : 'border-gray-200'
            } rounded-r-md h-9 w-full flex justify-between`}
          >
            <p className="truncate w-40"> {configFile.fileName}</p>

            {configFile.size && (
              <div className="bg-green-600 px-2 text-white rounded-full py-0.5 text-xs flex items-center justify-center truncate">
                {FileConvertSize(configFile.size)}
              </div>
            )}
          </label>
        </div>
        {uploadError && <p className="text-sm text-red-500">{uploadError}</p>}
      </div>
      <Button
        font="md"
        onClick={exportConfig}
        title={
          <p>
            {t('EXPORT_CONFIG')} <DownloadIcon />
          </p>
        }
      />
    </div>
  )
}
export default DeviceInfo
