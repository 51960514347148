import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllDeviceGroups } from './getAll'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/group`

const initialState = {
  loading: false,
  data: [],
  error: null
}

export const updateDeviceGroup = createAsyncThunk('group/update', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/update/${data.id}`, 'PUT', data)

  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  thunkAPI.dispatch(getAllDeviceGroups())
  return res.data
})

export const resetUpdate = createAsyncThunk('DeviceGroup/reset', async () => {
  return true
})

const DeviceGroupSlice = createSlice({
  name: 'updateDeviceGroup',
  initialState,
  extraReducers: {
    [updateDeviceGroup.pending]: (state) => {
      return { ...state, loading: true }
    },
    [updateDeviceGroup.fulfilled]: (state, action) => {
      return { ...state, data: action.payload, loading: false }
    },
    [updateDeviceGroup.rejected]: (state, action) => {
      return { ...state, error: action.payload, loading: false }
    },
    [resetUpdate.fulfilled]: (state, action) => {
      return initialState
    }
  }
})
const { reducer } = DeviceGroupSlice
export default reducer
