import clsx from 'clsx'

const classes = {
  root: 'w-2 h-full border-y border-slate-900',
  info: 'bg-blue-600',
  notice: 'bg-blue-300',
  debug: 'bg-sky-600',
  warning: 'bg-yellow-200',
  error: 'bg-amber-500',
  crit: 'bg-orange-500',
  alert: 'bg-red-500',
  emerg: 'bg-red-900'
}

const Hr = ({ type, className }) => {
  return (
    <div className={className}>
      <hr
        className={clsx({
          [classes.root]: true,
          [classes[type]]: !!classes[type]
        })}
      />
    </div>
  )
}

export default Hr
