import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import FillBar from '../../common/widgets/FillBar'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DevicesStats = ({ metrics }) => {
  const { t } = useTranslation()
  const [sortedMetrics, setSortedMetrics] = useState([])
  const [sortBy, setSortBy] = useState('logsError')

  const handleSort = () => {
    const sort = [...metrics].sort((a, b) => {
      if (a.values[sortBy] > b.values[sortBy]) return -1
      if (a.values[sortBy] < b.values[sortBy]) return 1
      return 0
    })
    setSortedMetrics(sort)
  }
  useEffect(() => {
    handleSort()
  }, [metrics, sortBy])
  return (
    <div className="h-full border border-gray-200 bg-white rounded-lg dark:bg-slate-800 overflow-hidden shadow-sm">
      <div className="container mx-auto">
        <div className="grid grid-cols-6 border-b border-gray-200">
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'device_uuid' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('device_uuid')}
          >
            {t('DEVICE_UUID')}
            {sortBy === 'device_uuid' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'cpu' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('cpu')}
          >
            Cpu
            {sortBy === 'cpu' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'ram' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('ram')}
          >
            Ram
            {sortBy === 'ram' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'temperature' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('temperature')}
          >
            {t('TEMPERATURE')}
            {sortBy === 'temperature' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'storage' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('storage')}
          >
            {t('STORAGE')}
            {sortBy === 'storage' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
          <button
            className={`col-span-1 flex pb-1 hover:bg-gray-50 p-2 items-center justify-between ${
              sortBy === 'logsError' && 'bg-gray-50'
            }`}
            onClick={() => setSortBy('logsError')}
          >
            {t('ERROR_LOGS')}
            {sortBy === 'logsError' && <KeyboardArrowDownIcon className="text-gray-400" />}
          </button>
        </div>

        <ul>
          {sortedMetrics?.map((el, i) => (
            <li key={i} className="grid grid-cols-6 p-2 border-b border-gray-200 hover:bg-gray-50">
              <div className="col-span-1 px-2 border-r border-gray-200 truncate">
                <p>{el.device_uuid}</p>
              </div>
              <div className="col-span-1 px-2 flex items-center space-x-2 border-r border-gray-200">
                <FillBar value={el.values.cpu} max={100} />
                <p>{el.values.cpu}%</p>
              </div>
              <div className="col-span-1 px-2 flex items-center space-x-2 border-r border-gray-200">
                <FillBar value={el.values.ram} max={100} />
                <p>{el.values.ram}%</p>
              </div>
              <div className="col-span-1 px-2 flex items-center space-x-2 border-r border-gray-200">
                <FillBar value={el.values.temperature} max={100} />
                <p>{el.values.temperature}°C</p>
              </div>
              <div className="col-span-1 px-2 flex items-center space-x-2 border-r border-gray-200">
                <FillBar value={el.values.storage} max={100} />
                <p>{el.values.storage}%</p>
              </div>
              <div className="col-span-1 px-2 flex items-center space-x-2 justify-center">
                <p>{el.values.logsError}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DevicesStats
