import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import { getAllDistributions } from './getAll'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  loading: false,
  data: null,
  error: null
}

export const deleteDistribution = createAsyncThunk(
  'distribution/delete',
  async ({ distributionId, moduleId }, thunkAPI) => {
    const res = await axios(
      `${basedUrl}/deleteDistrubtion/${distributionId + (moduleId ? `/${moduleId}` : '')}`,
      'DELETE'
    )
    if (res.status === 'error') {
      thunkAPI.dispatch(error(res.message))
      return thunkAPI.rejectWithValue(res.message)
    }
    thunkAPI.dispatch(success())
    thunkAPI.dispatch(getAllDistributions())
    return res.data
  }
)

const distributionSlice = createSlice({
  name: 'widget',
  initialState,
  extraReducers: {
    [deleteDistribution.pending]: (state) => {
      return { ...state, loading: true }
    },
    [deleteDistribution.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [deleteDistribution.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = distributionSlice
export default reducer
