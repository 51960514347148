import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { success, error } from '../requestInfo/requestInfo'
import { getAllDistributions } from './getAll'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/update`
const initialState = {
  data: {},
  loading: false,
  error: null
}
export const updateDistribution = createAsyncThunk(
  'firmware/update',
  async ({ data, distributionId, moduleId }, thunkAPI) => {
    const res = await axios(
      `${basedUrl}/firmware/${distributionId}${moduleId ? `/${moduleId}` : ''}`,
      'PUT',
      data
    )
    if (res.status === 'error') {
      thunkAPI.dispatch(error(res.message))
      return thunkAPI.rejectWithValue(res.message)
    }
    thunkAPI.dispatch(success())
    thunkAPI.dispatch(getAllDistributions())
    return res.data
  }
)

const distributionSlice = createSlice({
  name: 'firmware/update',
  initialState,
  extraReducers: {
    [updateDistribution.pending]: (state) => {
      return { ...state, loading: true }
    },
    [updateDistribution.fulfilled]: (state, action) => {
      return { error: null, data: action.payload, loading: false }
    },
    [updateDistribution.rejected]: (state, action) => {
      return { data: {}, error: action.payload, loading: false }
    }
  }
})

const { reducer } = distributionSlice
export default reducer
