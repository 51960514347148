import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('customer')}/api/customer/user`

const initialState = {
  loading: false,
  data: {},
  error: null
}

export const init = createAsyncThunk('init/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/init`, 'Get')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const userSlice = createSlice({
  name: 'init',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(init.pending, (state) => {
        state.loading = true
      })
      .addCase(init.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(init.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})
const { reducer } = userSlice
export default reducer
