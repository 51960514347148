import React from 'react'

const Spinner = (props) => {
  return (
    <>
      {props.version === 1 ? (
        <div className={`w-full h-full flex items-center justify-center`}>
          {props.opacity && (
            <div className="fixed inset-0 z-10">
              <div className="absolute inset-0 bg-gray-600 opacity-50" />
            </div>
          )}
          <svg
            className={`z-50`}
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
          >
            <path
              fill="#4299E1"
              d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.5s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      ) : (
        <div className="flex h-full items-center w-full justify-center ">
          <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="15 35 70 30">
            <g transform="rotate(0 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.9166666666666666s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(30 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.8333333333333334s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(60 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.75s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(90 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.6666666666666666s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(120 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.5833333333333334s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(150 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.5s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(180 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.4166666666666667s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(210 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.3333333333333333s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(240 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.25s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(270 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.16666666666666666s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(300 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="-0.08333333333333333s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
            <g transform="rotate(330 50 50)">
              <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#0051a2">
                <animate
                  attributeName="opacity"
                  values="1;0"
                  keyTimes="0;1"
                  dur="1s"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </rect>
            </g>
          </svg>
        </div>
      )}
    </>
  )
}

Spinner.defaultProps = {
  opacity: true,
  version: 1
}

export default Spinner
