import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'

import { createDeviceUpdate, resetCreate } from '../../store/updates/create'
import { getAllDevices } from '../../store/devices/getAll'
import Success from '../../common/stateInfo/StateInfo'
import Spinner from '../spinner/Spinner'
import GlobalModal from './Layout'

import { capitalizeFirstLetter } from '../../helpers/string'
import { useTranslation } from 'react-i18next'

const UpdateModal = ({ handleClose }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    limit: 5,
    offset: 0,
    toBeUpdated: [],
    data: [],
    distributionId: null,
    distribution: {},
    list: [],
    date: null,
    step: 1,
    updateType: 1
  })
  const [error, setError] = useState(null)
  const devices = useSelector((state) => state.getAllDevice)
  const distributions = useSelector((state) => state.updates.data.distributions)
  const createUpdate = useSelector((state) => state.createUpdate)
  const [alert, setAlert] = useState({ hide: true, type: null, msg: '' })
  const dispatch = useDispatch()
  const currentDate = new Date()
  const prevCreateUpdate = useRef()
  useEffect(() => {
    if (prevCreateUpdate.current && !createUpdate.loading) {
      if (createUpdate.error) {
        setAlert({ type: 'error', message: createUpdate.error, hide: false })
      } else {
        setAlert({ type: 'success', message: null, hide: false })
      }
      dispatch(resetCreate())
    }
    prevCreateUpdate.current = createUpdate.loading
  }, [createUpdate])
  useEffect(() => {
    dispatch(resetCreate())
    if (!devices.loading && !devices.data.count) {
      dispatch(getAllDevices())
    }
  }, [])
  const handlePagination = (e, page) => {
    e.preventDefault()
    setState((prev) => ({
      ...prev,
      data: devices.data.rows.slice(page * prev.limit, prev.limit * (page + 1)),
      offset: page
    }))
  }
  const handleSubmit = () => {
    if (!state.toBeUpdated.length) {
      setError(t('SELECT_DEVICE_ERROR'))
    } else if (state.updateType === 2 && !state.date) {
      setError(t('SELECT_DATE_ERROR'))
    } else {
      setError(null)
      dispatch(
        createDeviceUpdate({
          devices: state.toBeUpdated,
          distributionId: state.distributionId,
          date: state.updateType === 2 ? state.date : null
        })
      )
    }
  }
  const handleSelectOne = (device_uuid, e) => {
    const updatedDevice = { uuid: e.device_uuid, hostname: e.hostname }

    if (!state.toBeUpdated.find((device) => device.uuid === updatedDevice.uuid)) {
      setState((prev) => ({
        ...prev,
        toBeUpdated: [...prev.toBeUpdated, updatedDevice]
      }))
    } else {
      setState((prev) => ({
        ...prev,
        toBeUpdated: prev.toBeUpdated.filter((device) => device.uuid !== updatedDevice.uuid)
      }))
    }
  }
  const handleSelectAll = () => {
    if (devices.data.count === state.toBeUpdated.length) {
      setState((prev) => ({ ...prev, toBeUpdated: [] }))
    } else {
      const all = devices.data.rows.map((e) => ({ uuid: e.device_uuid, hostname: e.hostname }))
      setState((prev) => ({ ...prev, toBeUpdated: all }))
    }
  }
  const filterPassedTime = (time) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }
  const handleNext = () => {
    if (!state.distributionId) {
      setError(t('SELECT_DISTRIBUTION_ERROR'))
    } else {
      setError(null)
      const list = devices.data.rows.filter(
        (e) => e.properties?.device_type?.toLowerCase() === state.distribution.type?.toLowerCase()
      )
      setState((prev) => ({
        ...prev,
        step: 2,
        data: list.slice(0, prev.limit),
        list
      }))
    }
  }
  return (
    <GlobalModal
      title={`${t('ROLLOUT')} `}
      size="small"
      buttons={[
        alert.hide
          ? state.step === 1
            ? { title: t('NEXT'), action: () => handleNext() }
            : state.step === 2 && state.data.length
            ? {
                title: t('DEPLOY'),
                action: () => handleSubmit()
              }
            : {
                title: t('BACK'),
                action: () => setState((prev) => ({ ...prev, step: 1, distributionId: null }))
              }
          : null,
        {
          type: 'secondary',
          title: t('CLOSE'),
          action: handleClose
        }
      ]}
    >
      <div className="relative p-4 flex-auto">
        {!alert.hide ? (
          <Success type={alert.type} description={alert.message} />
        ) : (
          <>
            <div className="pb-10 relative mx-24">
              <ul
                className={`w-full h-2 ${
                  state.step >= 2 ? 'bg-emerald-500' : 'bg-slate-300'
                } flex justify-between items-center rounded-full transition duration-1000 ease-in-out`}
              >
                <li
                  className={`w-10 ${
                    state.step >= 2 ? 'bg-emerald-500' : 'bg-slate-300'
                  } h-10 rounded-full flex items-center justify-center absolute z-10 text-white transition duration-300 ease-in-out`}
                >
                  1
                </li>
                <li
                  className={`w-10 ${
                    state.step >= 3 ? 'bg-emerald-500' : 'bg-slate-300'
                  } h-10 rounded-full flex items-center justify-center absolute z-10 text-white transition duration-300 ease-in-out right-0`}
                >
                  2
                </li>
              </ul>
            </div>
            {error && <span className="text-red-500 m-2">{error}</span>}
            {devices.loading || createUpdate.loading ? (
              <div className="absolute w-full h-full z-50">
                <Spinner version={1} isContainer />
              </div>
            ) : (
              <form className="w-full">
                {state.step === 1 ? (
                  <div>
                    <div className="mb-3 flex flex-col items-start">
                      <label className="block lg:text-sm text-lg leading-5 font-medium text-primary dark:text-white">
                        {t('DISTRIBUTION')}:
                      </label>
                      <select
                        value={state.distributionId}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            distributionId: e.target.value,
                            distribution: distributions.content.find((i) => i.id == e.target.value)
                          }))
                        }
                        className="mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary text-sm block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none h-10"
                      >
                        <option value="" />
                        {distributions.content &&
                          distributions.content.map((e, i) => (
                            <option key={i} value={e.id}>
                              {`${e.name} (${e.version})`}
                            </option>
                          ))}
                      </select>
                      <div id="distribution-info" className="mt-5">
                        <ul>
                          <li className="flex space-x-2 text-sm">
                            <label className="font-medium text-gray-500 ">{t('NAME')} :</label>
                            <span className="">{state.distribution?.name || 'N/A'}</span>
                          </li>
                          <li className="flex space-x-2 text-sm">
                            <label className="font-medium text-gray-500 ">{t('VERSION')} :</label>
                            <span className="">{state.distribution?.version || 'N/A'}</span>
                          </li>
                          <li className="flex space-x-2 text-sm">
                            <label className="font-medium text-gray-500 ">{t('TYPE')} :</label>
                            <span className="">{state.distribution?.type || 'N/A'}</span>
                          </li>
                          <li className="flex flex-col space-y-1 text-sm">
                            <label className="font-medium text-gray-500 ">
                              {t('DESCRIPTION')} :
                            </label>
                            <span className="">{state.distribution?.description || 'N/A'}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : !state.data.length ? (
                  <div className="h-full w-full flex justify-center items-center">
                    <span>{t('NO_DEVICE_ERROR')}</span>
                  </div>
                ) : (
                  <div>
                    <div className="px-2 flex justify-between mb-2">
                      <div className="flex space-x-2 items-center">
                        <input
                          checked={devices.data.count === state.toBeUpdated.length}
                          onChange={handleSelectAll}
                          value={'test'}
                          name={'test'}
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          readOnly={true}
                        />
                        <label className="text-md font-medium">{t('ALL')}</label>
                      </div>
                      <div className="flex items-center space-x-1">
                        <button
                          disabled={state.offset === 0}
                          onClick={(e) => handlePagination(e, state.offset - 1)}
                          className="rounded-full border flex items-center hover:bg-gray-300 "
                        >
                          <KeyboardArrowLeftIcon />
                        </button>
                        <label className="text-md">
                          {state.offset + 1}/{Math.floor(devices.data.count / state.limit) + 1}
                        </label>
                        <button
                          disabled={state.offset === Math.floor(devices.data.count / state.limit)}
                          onClick={(e) => handlePagination(e, state.offset + 1)}
                          className="rounded-full border flex items-center hover:bg-gray-300 "
                        >
                          <KeyboardArrowRightIcon />
                        </button>
                      </div>
                    </div>
                    <div className="mb-2 w-full flex border p-2 rounded-md overflow-auto">
                      <div className="w-full space-y-1">
                        {state.data.map((e, i) => {
                          return (
                            <div key={i} className="relative flex items-center space-x-2 w-full">
                              <input
                                checked={state.toBeUpdated.some(
                                  (device) => device.uuid === e.device_uuid
                                )}
                                onChange={() => handleSelectOne(e.device_uuid, e)}
                                value={e.device_uuid}
                                name={e.device_uuid}
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                readOnly={true}
                              />
                              <label>{e.hostname}</label>
                              {e.informations && e.informations[0] && e.informations[0].tags && (
                                <div className="flex absolute right-0 space-x-1">
                                  {e.informations[0].tags.map((tag, key) => (
                                    <div
                                      key={key}
                                      className="text-xs rounded-full bg-blue-500 items-center px-1 py-0.5  shadow-sm text-white"
                                    >
                                      {capitalizeFirstLetter(tag.name)}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="mt-2 grid grid-cols-3 items-baseline">
                      <div className="flex ">
                        <input
                          checked={state.updateType === 1}
                          onChange={() => setState((prev) => ({ ...prev, updateType: 1 }))}
                          value={state.updateType}
                          type="radio"
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          readOnly={true}
                        />
                        <label className="ml-3">
                          <span className="block text-md leading-5 font-medium text-primary">
                            {t('IMMEDIATELY')}
                          </span>
                        </label>
                      </div>
                      <div className="flex ">
                        <input
                          checked={state.updateType !== 1}
                          onChange={() => setState((prev) => ({ ...prev, updateType: 2 }))}
                          value={state.updateType}
                          type="radio"
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          readOnly={true}
                        />
                        <label className="ml-3">
                          <span className="block text-md leading-5 font-medium text-primary">
                            {t('SCHEDULED')}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="flex mt-3  flex-col w-48">
                      {state.updateType === 2 && (
                        <div className="flex space-x-2 justify-center items-center">
                          <DatePicker
                            showTimeSelect
                            selected={state.date}
                            filterTime={filterPassedTime}
                            onChange={(date) => setState((prev) => ({ ...prev, date }))}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            className="focus:outline-none focus:ring bg-gray-50 border lg:text-sm text-xs border-gray-200 text-gray-900 rounded-lg focus:ring-blue-500/50 p-2 pr-4 lg:pr-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                            isClearable={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </form>
            )}
          </>
        )}
      </div>
    </GlobalModal>
  )
}

export default UpdateModal
