import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../common/customAxios'
import { getCleanUrl } from '../../utils/urlManip'

const basedUrl = `${getCleanUrl('device')}/api/device/tag`
const initialState = {
  loading: false,
  data: [],
  error: null
}

export const getAllTags = createAsyncThunk('tag/getAll', async (data, thunkAPI) => {
  const res = await axios(`${basedUrl}/getAll`, 'GET')
  if (res.status === 'error') return thunkAPI.rejectWithValue(res.message)
  return res.data
})

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  extraReducers: {
    [getAllTags.pending]: (state) => {
      return { ...state, loading: true }
    },
    [getAllTags.fulfilled]: (state, action) => {
      return {
        loading: false,
        data: action.payload,
        error: null
      }
    },
    [getAllTags.rejected]: (state, action) => {
      return { data: [], error: action.payload, loading: false }
    }
  }
})
const { reducer } = tagSlice
export default reducer
